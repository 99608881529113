import { ActionIcon, MantineSize, Popover, TextInput } from '@mantine/core';
import { PrimitiveTypeIcon } from '@root/Design/Primitives';
import { useToggle } from '@root/Services/EventEmitter';
import { FieldInfo, primitiveIcons, PrimitiveType, SchemaService, TypeInfo } from '@root/Services/QueryExpr';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import { VisibleSpaces } from '../Text/VisibleSpaces';
import { Picker } from './Picker';

type IFieldPickerDropdownProps = {
    value: FieldInfo | undefined;
    onChange: (value?: FieldInfo) => void;
    label?: ReactNode;
    description?: ReactNode;
    size?: MantineSize;
    placeholder?: string;
} & Omit<FieldPickerProps, 'onChange' | 'selections' | 'mode'>;
export function FieldPickerDropdown(props: IFieldPickerDropdownProps) {
    const { description, label, placeholder, size } = props;
    const [opened, { close, toggle }] = useToggle(false);
    const selections = useMemo(() => (props.value ? [props.value] : []), [props.value]);
    const onChange = useCallback(
        (selections: FieldInfo[]) => {
            props.onChange(selections[0]);
            close();
        },
        [props.onChange, close]
    );

    return (
        <Popover withinPortal opened={opened} onClose={close} shadow="md" position="bottom" offset={0} withArrow>
            <Popover.Target>
                <TextInput
                    value={props.value?.name ?? ''}
                    placeholder={placeholder}
                    onClick={toggle}
                    readOnly
                    label={label}
                    size={size}
                    description={description}
                    rightSection={
                        <ActionIcon variant={opened ? 'default' : undefined} color={opened ? 'primary' : undefined} tabIndex={-1} onClick={toggle}>
                            {opened ? <ChevronUp strokeWidth={3} size={20} /> : <ChevronDown strokeWidth={1} size={18} />}
                        </ActionIcon>
                    }
                    style={{ cursor: 'pointer' }}
                />
            </Popover.Target>
            <Popover.Dropdown p={0}>
                <FieldPicker {...props} onChange={onChange} selections={selections} mode="single" />
            </Popover.Dropdown>
        </Popover>
    );
}

export function FieldPicker({ schema, types, mode, selections, onChange, width = 400, ...props }: FieldPickerProps) {
    const items = useMemo(() => {
        const rootTypes: FieldPickerItem[] = schema.rootTypeInfo.length > 1 ? schema.rootTypeInfo : schema.rootTypeInfo[0]?.children ?? [];
        return rootTypes;
    }, [schema]);
    const typeLookup = useMemo(() => (types ? new Set<string>(types) : undefined), [types]);
    const isSelectable = useMemo(() => {
        return props.isSelectable ? props.isSelectable : (o: FieldPickerItem) => o instanceof FieldInfo && o.isPrimitive;
    }, [props.isSelectable]);
    const minimizeHeight = props.minimizeHeight !== false;
    const height = props.height ?? (minimizeHeight ? 350 : undefined);
    const filter = useCallback(
        (item: FieldPickerItem) => {
            return (
                (!typeLookup || ('isPrimitive' in item && typeLookup.has(item.field.TypeName ?? ''))) &&
                (!props.schemaFilter || props.schemaFilter(item))
            );
        },
        [typeLookup, props.schemaFilter]
    );
    const getChildren = useCallback(
        (item: FieldPickerItem) => {
            return props.getChildren ? props.getChildren(item) : item.children;
        },
        [props.getChildren]
    );

    const renderFieldName = useCallback(
        (o: FieldPickerItem) => {
            const name = props.renderItem?.(o) ?? o.name;

            if (
                typeof name === 'string' &&
                'pathWithRoot' in o &&
                (o.pathWithRoot?.startsWith('Tags.') || o.pathWithRoot?.startsWith('resourceTags.'))
            ) {
                return <VisibleSpaces value={name} />;
            }

            return name;
        },
        [props.renderItem]
    );

    return (
        <Picker
            items={items}
            selections={selections as FieldPickerItem[]}
            onChange={onChange as (selections: FieldPickerItem[]) => void}
            childAccessor={getChildren}
            isSelectable={isSelectable}
            isDisabled={props.isDisabled}
            nameAccessor={(o) => o.name}
            filterPlaceholder="Find field"
            mode={mode}
            width={width}
            minimizeHeight={minimizeHeight}
            height={height}
            renderItem={(o) =>
                'isPrimitive' in o && o.isPrimitive ? (
                    <>
                        <PrimitiveTypeIcon className={primitiveIcons[o.field.TypeName as PrimitiveType]}></PrimitiveTypeIcon> {renderFieldName(o)}
                    </>
                ) : (
                    o.name
                )
            }
            filter={filter}
            data-atid="field-picker"
        />
    );
}
type FieldPickerItem = FieldInfo | TypeInfo;
interface FieldPickerProps {
    schema: SchemaService;
    types?: string[];
    mode: 'single' | 'multiple';
    selections: FieldInfo[];
    isSelectable?: (item: FieldPickerItem) => boolean;
    isDisabled?: (item: FieldPickerItem) => boolean;
    onChange: (selections: FieldInfo[]) => void;
    renderItem?: (item: FieldPickerItem) => ReactNode;
    schemaFilter?: (item: FieldInfo | TypeInfo) => boolean;
    getChildren?: (item: FieldPickerItem) => undefined | FieldPickerItem[];
    width?: string | number;
    minimizeHeight?: boolean;
    height?: number;
    noFilter?: boolean;
}
