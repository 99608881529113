import { getCompanyGetCompanyConfig } from '@apis/Customers';
import { Company, CompanyConfiguration } from '@apis/Customers/model';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { inject, injectable } from 'tsyringe';
import { BaseCacheByTenant } from './BaseCacheByTenant';
import { ICompanyContextToken } from './CompanyContext';

interface ICompanyConfigModel {
    MapWorkload?: 'Require';
    Export?: 'Block';
    ForecastMaxPredictionDays?: string;
}

class CompanyConfig {
    private readonly model: ICompanyConfigModel;

    public constructor(public readonly configs: CompanyConfiguration[]) {
        this.model = this.configs.reduce((acc, c) => ({ ...acc, [c.Key ?? '']: c.Value }), {} as ICompanyConfigModel);
    }

    public get requireMapWorkload() {
        return this.checkConfig('MapWorkload', 'Require');
    }

    public get blockExport() {
        return this.checkConfig('Export', 'Block');
    }

    public get forecastMaxPredictionDays() {
        return this.model.ForecastMaxPredictionDays;
    }

    private checkConfig<TKey extends keyof ICompanyConfigModel>(key: TKey, value: string) {
        return this.model[key]?.localeCompare(value, undefined, { sensitivity: 'base' }) === 0;
    }
}

@injectable()
class CompanyConfigServiceCache extends BaseCacheByTenant<CompanyConfig> {
    public constructor(@inject(CompanyTenantPrereqService) tenantSvc: CompanyTenantPrereqService) {
        super(tenantSvc);
    }
}

@injectable()
export class CompanyConfigService {
    public constructor(
        @inject(CompanyConfigServiceCache) private readonly cache: CompanyConfigServiceCache,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public async getTypedCompanyConfig() {
        return this.cache.get(this.company.Id ?? 0, async () => {
            const configs = await getCompanyGetCompanyConfig();
            return new CompanyConfig(configs);
        });
    }
}
