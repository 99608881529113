import { Box, Button, Group, Loader, LoadingOverlay, Radio, Space, Switch, Text, useMantineTheme } from '@mantine/core';
import { observer } from 'mobx-react';
import { RuleEditCard } from './Design';
import { RuleEditor } from './Model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDiContainer } from '@root/Services/DI';
import { Clearfix } from '@root/Design/Primitives';
import { FilterResources, ValidationIssues } from './FilterResources';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { useEvent } from '@root/Services/EventEmitter';
import { injectable } from 'tsyringe';
import {
    TagAutomationRule,
    TagAutomationRuleParametersType,
    ResourceGroupRuleOptions,
    TagRuleSyntax,
    TypeMapping,
    SelectedEntityTags,
} from '@apis/TagManager/model';
import { useFilterValidation } from './RuleDescriber';
import { makeAutoObservable } from 'mobx';
import {
    AwsOUAccountGroupRuleOptions,
    AwsOUAccountGroupRuleOptionsGroupingType,
    BaseAzureResource,
    IQueryExpr,
    Query,
    SelectedResourceTags,
} from '@apis/Resources/model';
import { QueryExpr, postResourcesQuery } from '@apis/Resources';
import { exprBuilder, queryBuilder } from '@root/Services/QueryExpr';
import { set } from 'date-fns';
import styled from '@emotion/styled';
import { BaseResource } from '@root/Components/Resources/ResourcesGrid';
import { getAccountGetAccountsByAwsOUId, getAccountGetAccountsByOU } from '@apis/Customers';
import { useCompany } from '@root/Components/Router/CompanyContent';

interface AwsEntityTagSelection {
    entityName: string;
    tagKey: string;
    tagValue: string;
}

@injectable()
export class AwsOrgEntityEditor {
    public options: AwsOUAccountGroupRuleOptions = {};
    public syntax: TagRuleSyntax = {};
    public ruleEditor: RuleEditor = {} as RuleEditor;
    public filter: IQueryExpr = {};
    public rule?: TagAutomationRule = undefined;
    public constructor() {
        makeAutoObservable(this);
    }

    public init(ruleEditor: RuleEditor) {
        const rule = ruleEditor.rule!;
        rule.Parameters ??= {};
        rule.Parameters.Filter ??= {};
        rule.Parameters.Syntax ??= {};
        rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions ??= {};
        rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions.SelectedTags ??= [];
        rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions.SelectedAccounts ??= [];
        rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions.SelectedOrganizations ??= [];
        rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions.GroupingType ??= AwsOUAccountGroupRuleOptionsGroupingType.OrganizationalUnit;
        this.options = rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions ??= {};
        this.syntax = rule.Parameters.Syntax ??= {};
        this.ruleEditor = ruleEditor;
        this.rule = rule;
        return this;
    }

    public setSelectedTags(selectedTags: SelectedEntityTags[]) {
        this.options.SelectedTags = selectedTags;
    }

    public setSelectedAccounts(selectedAccounts: string[]) {
        this.options.SelectedAccounts = selectedAccounts;
    }

    public setSelectedOrgs(selectedOrgs: string[]) {
        this.options.SelectedOrganizations = selectedOrgs;
    }

    public setFilter(entityIds: string[]) {
        if (entityIds.length !== 0) {
            var query = queryBuilder<BaseResource>()
                .where((b) =>
                    b.and(
                        b.model.ResourceType!.ne('Organization Account'),
                        b.model.ResourceType!.ne('Organization Root'),
                        b.model.ResourceType!.ne('Organization Unit'),
                        b.model.Account!.eq(entityIds)
                    )
                )
                .build();
            this.rule!.Parameters!.Filter! = query.Where!;
        } else {
            this.rule!.Parameters!.Filter! = {};
        }
    }

    public setApplyAllTags(applyAllTags: boolean) {
        this.options.ApplyAllTags = applyAllTags;
    }

    public setGroupType(groupType: AwsOUAccountGroupRuleOptionsGroupingType) {
        this.options.GroupingType = groupType;
    }

    public getErrors() {
        const result: string[] = [];
        return result;
    }
}

export const AwsOrgEntityGroupActionCard = observer(function AwsOrgEntityGroupActionCard({
    awsOrgEntityEditor,
}: {
    awsOrgEntityEditor: AwsOrgEntityEditor;
}) {
    const theme = useMantineTheme();
    const [resourceGrid, setResourceGrid] = useState<DataGridModel>();
    const [tagGrid, setTagGrid] = useState<DataGridModel>();
    const [entityGroups, setEntityGroups] = useState<BaseResource[]>([]);
    const [entityGroupTags, setEntityGroupTags] = useState<AwsEntityTagSelection[]>([]);
    const [selectedEntityGroups, setSelectedEntityGroups] = useState<BaseResource[]>([]);
    const [selectedGroupType, setSelectedGroupType] = useState<AwsOUAccountGroupRuleOptionsGroupingType>(
        awsOrgEntityEditor.options.GroupingType ?? AwsOUAccountGroupRuleOptionsGroupingType.OrganizationalUnit
    );
    const [loading, setLoading] = useState(false);
    const company = useCompany();
    useEffect(() => {
        if (awsOrgEntityEditor.options.SelectedTags != undefined && awsOrgEntityEditor.options.SelectedTags.length > 0) {
            const previouslySelectedEntityGroups = awsOrgEntityEditor.options.SelectedTags.map((t) => t.EntityId!);
            setSelectedEntityGroups(entityGroups.filter((r) => previouslySelectedEntityGroups.includes(r.Name!)));
        }
    }, [resourceGrid?.selections]);

    const initialSelectedResourceGroups = useMemo(() => {
        return awsOrgEntityEditor.options.SelectedTags?.map((t) => t.EntityId!);
    }, [awsOrgEntityEditor.options.SelectedTags]);

    const initialSelectedTags = useMemo(() => {
        return awsOrgEntityEditor.options.SelectedTags?.map((t) => {
            return t.SelectedTags?.map((s) => {
                return { entityName: t.EntityId!, tagKey: s };
            });
        }).flat();
    }, [awsOrgEntityEditor.options.SelectedTags]);

    //first grid data load

    useEffect(() => {
        (async () => {
            setLoading(true);
            await queryBuilder<BaseResource>()
                .where((b) =>
                    selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account
                        ? b.model.ResourceType!.eq('Organization Account')
                        : b.or(b.model.ResourceType!.eq('Organization Unit'), b.model.ResourceType!.eq('Organization Root'))
                )
                .take(1000)
                .execute(postResourcesQuery)
                .then((r) => {
                    setEntityGroups(r.Results!);
                    setLoading(false);
                });
        })();
    }, [awsOrgEntityEditor.rule?.Parameters?.Type, selectedGroupType]);

    //second grid data load
    useMemo(async () => {
        if (selectedEntityGroups.length > 0) {
            const tagOptions = selectedEntityGroups.map((r) => {
                let results = [] as AwsEntityTagSelection[];
                if (r.Tags != null || r.Tags != undefined) {
                    r.Tags.forEach((tag) => {
                        let tagOption = { entityName: r.Name } as AwsEntityTagSelection;
                        tagOption.tagKey = tag.Key!;
                        tagOption.tagValue = tag.Value!;
                        results.push(tagOption);
                    });
                }
                return results;
            });
            setEntityGroupTags(tagOptions.flat());
        }
    }, [selectedEntityGroups, selectedGroupType]);

    //update selected entity groups
    useEvent(resourceGrid?.selectionChanged, async () => {
        const selectedResources = (await resourceGrid?.selections.getSelected()) as BaseResource[];
        if (selectedResources != null || selectedResources != undefined) {
            if (selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account) {
                awsOrgEntityEditor.setSelectedAccounts(selectedResources.map((r) => r.Name!));
            }

            if (selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.OrganizationalUnit) {
                awsOrgEntityEditor.setSelectedOrgs(selectedResources.map((r) => r.Name!));
            }
            setSelectedEntityGroups(selectedResources);
        } else {
            awsOrgEntityEditor.ruleEditor.setTypeErrors(TagAutomationRuleParametersType.AwsOUAccountGroup, [
                'You must select at least one entity to copy tags from.',
            ]);
            setSelectedEntityGroups([]);
            setEntityGroupTags([]);
        }
    });

    //update selected tags
    useEvent(tagGrid?.selectionChanged, async () => {
        const selectedTags = (await tagGrid?.selections.getSelected()) as AwsEntityTagSelection[];
        if (selectedTags != null || selectedTags != undefined) {
            var entityGroups: string[] = [];
            let selectionToSave: SelectedEntityTags[] = [];
            //make distinct list of entities
            selectedTags.forEach((tag) => {
                if (!entityGroups.includes(tag.entityName)) entityGroups.push(tag.entityName);
            });

            entityGroups.forEach((entity) => {
                var uniqueTagsForEg = selectedTags.filter((tag) => tag.entityName == entity);
                let tagOptions = { EntityId: entity } as SelectedEntityTags;
                tagOptions.SelectedTags = uniqueTagsForEg.map((tag) => tag.tagKey);
                selectionToSave.push(tagOptions);
            });

            if (selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account) {
                awsOrgEntityEditor.setSelectedTags(selectionToSave);
                awsOrgEntityEditor.setFilter(entityGroups);
                awsOrgEntityEditor.ruleEditor.ruleTypeValidation[TagAutomationRuleParametersType.AwsOUAccountGroup] = [];
            } else if (selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.OrganizationalUnit) {
                const accounts: string[] = await GetOuAccounts(entityGroups);
                awsOrgEntityEditor.setSelectedTags(selectionToSave);
                awsOrgEntityEditor.setFilter(accounts);
                awsOrgEntityEditor.ruleEditor.ruleTypeValidation[TagAutomationRuleParametersType.AwsOUAccountGroup] = [];
            }
        } else {
            awsOrgEntityEditor.ruleEditor.setTypeErrors(TagAutomationRuleParametersType.AwsOUAccountGroup, [
                'You must select at least one tag to copy from an entity group.',
            ]);
            awsOrgEntityEditor.setSelectedTags([]);
            awsOrgEntityEditor.setFilter([]);
        }
    });

    //set resource group columns Add top 5 tags?
    const resourceColumns = useMemo(() => {
        const columns: ColumnConfig<BaseAzureResource>[] = [
            {
                accessor: (item) => item.Name,
                header: selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account ? 'Account Name' : 'Organization Name',
                id: 'name',
                defaultWidth: 200,
            },
            {
                accessor: (item) => item.Tags?.length,
                header: 'Tag Count',
                id: 'tagCount',
                defaultWidth: 200,
            },
        ];
        return columns;
    }, [selectedGroupType]);

    //
    const tagColumns = useMemo(() => {
        const columns: ColumnConfig<AwsEntityTagSelection>[] = [
            {
                accessor: (item) => item.entityName,
                header: selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account ? 'Account Name' : 'Organization Name',
                id: 'name',
                defaultWidth: 200,
            },
            {
                accessor: (item) => item.tagKey,
                header: 'Tag Key',
                id: 'tagKey',
                defaultWidth: 200,
            },
            {
                accessor: (item) => item.tagValue,
                header: 'Tag Value',
                id: 'tagValue',
                defaultWidth: 200,
            },
        ];
        return columns;
    }, [selectedGroupType]);

    const handleApplyAllTagsChange = async (applyAllTags: boolean) => {
        awsOrgEntityEditor.setApplyAllTags(applyAllTags);

        if (applyAllTags) {
            var entityGroups: string[] =
                selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account
                    ? awsOrgEntityEditor.options.SelectedAccounts!
                    : awsOrgEntityEditor.options.SelectedOrganizations!;

            var selectedTags: SelectedEntityTags[] = [];
            entityGroups.forEach((entity) => {
                var uniqueTagsForEg = entityGroupTags.filter((tag) => tag.entityName == entity);
                let tagOptions = { EntityId: entity } as SelectedEntityTags;
                tagOptions.SelectedTags = uniqueTagsForEg.map((tag) => tag.tagKey);
                selectedTags.push(tagOptions);
            });
            awsOrgEntityEditor.setSelectedTags(selectedTags);

            awsOrgEntityEditor.setFilter(
                selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account ? entityGroups : await GetOuAccounts(entityGroups)
            );
        } else {
            awsOrgEntityEditor.setSelectedTags([]);
            awsOrgEntityEditor.setFilter([]);
        }
    };

    const filterValidityChanged = useFilterValidation(awsOrgEntityEditor.ruleEditor);

    const handleGroupTypeChanged = (value: string) => {
        if (value == 'Account') {
            setSelectedGroupType(AwsOUAccountGroupRuleOptionsGroupingType.Account);
            awsOrgEntityEditor.setGroupType(AwsOUAccountGroupRuleOptionsGroupingType.Account);
            setEntityGroups([]);
            setEntityGroupTags([]);
            setSelectedEntityGroups([]);
            awsOrgEntityEditor.setApplyAllTags(false);
            awsOrgEntityEditor.setSelectedTags([]);
            awsOrgEntityEditor.setFilter([]);
            awsOrgEntityEditor.setSelectedAccounts([]);
            awsOrgEntityEditor.setSelectedOrgs([]);
        } else {
            setSelectedGroupType(AwsOUAccountGroupRuleOptionsGroupingType.OrganizationalUnit);
            awsOrgEntityEditor.setGroupType(AwsOUAccountGroupRuleOptionsGroupingType.OrganizationalUnit);
            setEntityGroups([]);
            setEntityGroupTags([]);
            setSelectedEntityGroups([]);
            awsOrgEntityEditor.setSelectedTags([]);
            awsOrgEntityEditor.setFilter([]);
            awsOrgEntityEditor.setSelectedAccounts([]);
            awsOrgEntityEditor.setSelectedOrgs([]);
        }
    };

    return (
        <>
            <RuleEditCard
                title="Select Group Type"
                description="Should this rule apply to resources under specific AWS Organizational Units or Accounts?"
            >
                <Space h="md" />
                <Group position="center" spacing="xl">
                    <Button
                        onClick={() => handleGroupTypeChanged('Account')}
                        sx={{ width: 200 }}
                        variant={selectedGroupType === 'Account' ? 'filled' : 'outline'}
                        fullWidth
                    >
                        Accounts
                    </Button>
                    <Button
                        onClick={() => handleGroupTypeChanged('OrganizationalUnit')}
                        sx={{ width: 200 }}
                        variant={selectedGroupType === 'OrganizationalUnit' ? 'filled' : 'outline'}
                        fullWidth
                    >
                        Organizational Units
                    </Button>
                </Group>
            </RuleEditCard>

            <Space h="md" />
            <RuleEditCard
                height={400}
                title={selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account ? 'Select Accounts' : 'Select Organizational Units'}
                description={
                    selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account
                        ? 'Select Accounts whose tags should be copied to their resources'
                        : 'Select Organizational Units whose tags should be copied to their resources'
                }
            >
                {loading ? (
                    <Loader />
                ) : (
                    <div style={{ height: '300px' }}>
                        <DataGrid
                            dataSource={entityGroups}
                            columns={resourceColumns}
                            initialSelection={entityGroups.filter((r) => {
                                return initialSelectedResourceGroups?.includes(r.Name!);
                            })}
                            onModelLoaded={setResourceGrid}
                            hideFilter={true}
                            hideMenu={true}
                            hideGlobalSearch={true}
                            onRowClick="select"
                            selectionMode={'multiple'}
                        />
                    </div>
                )}
            </RuleEditCard>
            <Space h="md" />
            {entityGroupTags.length > 0 ? (
                <RuleEditCard
                    title={'Select Tags to Copy'}
                    description={`Which ${
                        selectedGroupType == AwsOUAccountGroupRuleOptionsGroupingType.Account ? 'Account' : 'Organizational Unit'
                    } tags should be inherited by resources?`}
                >
                    <div style={{ margin: '10px' }}>
                        <Switch
                            sx={{ display: 'inline-block' }}
                            defaultChecked={awsOrgEntityEditor.options.ApplyAllTags}
                            onChange={(e: any) => handleApplyAllTagsChange(e.currentTarget.checked)}
                        ></Switch>
                        <Text style={{ color: theme.colors.gray[6], fontSize: '14px', marginLeft: '10px', display: 'inline-block' }}>
                            Apply all tags current and future
                        </Text>
                    </div>
                    <div style={{ height: '300px' }}>
                        <DataGrid
                            dataSource={entityGroupTags}
                            columns={tagColumns}
                            initialSelection={entityGroupTags.filter((r) => {
                                return initialSelectedTags?.find((t) => t?.entityName == r.entityName && t.tagKey == r.tagKey) != undefined;
                            })}
                            onModelLoaded={setTagGrid}
                            hideFilter={true}
                            hideMenu={true}
                            hideGlobalSearch={true}
                            selectionMode={'multiple'}
                        />
                    </div>
                </RuleEditCard>
            ) : null}
            <RuleEditCard title="Select Filters" description="Add filtering to narrow down which resources will be impacted">
                {awsOrgEntityEditor.rule ? (
                    <>
                        <AwsOrgEntityResourceFilter typeEditor={awsOrgEntityEditor} onValidationIssuesChanged={filterValidityChanged} />
                    </>
                ) : null}
            </RuleEditCard>
        </>
    );

    async function GetOuAccounts(entityGroups: string[]) {
        const accounts: string[] = [];
        await Promise.all(
            entityGroups.map(async (entity) => {
                var accountsByOu = await getAccountGetAccountsByAwsOUId({ companyId: company?.Id!, awsOrganizationId: entity }).then((a) =>
                    a.map((acc) => acc.AwsAccountId!)
                );
                accountsByOu.forEach((acc) => {
                    accounts.push(acc);
                });
            })
        );
        return accounts;
    }
});

export function AwsOrgEntityResourceFilter({
    typeEditor,
    onValidationIssuesChanged,
}: {
    typeEditor: AwsOrgEntityEditor;
    onValidationIssuesChanged: (issues: ValidationIssues) => void;
}) {
    let rule: TagAutomationRule = typeEditor.rule!;
    const normalizeFilter = (rule: TagAutomationRule) => {
        let result: QueryExpr[];
        rule.Parameters ??= {};
        rule.Parameters.Syntax ??= {};
        rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions ??= {};
        rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions.Filter ??= { Operation: 'and', Operands: (result = []) };
        if (!('Operation' in rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions.Filter)) {
            rule.Parameters.Filter = { Opearation: 'and', Operands: (result = []) };
        } else {
            result = rule.Parameters.Syntax.AwsOUAccountGroupRuleOptions.Filter.Operands ??= [];
        }
        return result;
    };
    const filter = useMemo(() => normalizeFilter(rule), [rule, rule.Parameters?.Syntax?.ResourceGroupRuleOptions?.Filter]);
    const onChange = useCallback((nextFilter: QueryExpr[]) => {
        filter.splice(0, Infinity, ...nextFilter);
    }, []);
    return <FilterResources filter={filter} onChange={onChange} onValidationIssuesChanged={onValidationIssuesChanged} />;
}
