import { Query, QuerySelectExpr, QuerySortExpr } from '@apis/Resources/model';
import { DataGridModel } from './DataGridModel';
import { QueryExpr, QueryResult } from '@apis/Resources';
import { DataGridState } from './Models';
import { ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { QueryBasedResourceSelection } from '../Resources/ResourcesGrid';

export type GroupRow<T> = { row: T; groupId: string; parent?: T; value: string; count: number };

export class QueryExprGroupHelper<T> {
    private previousGroupByItems: GroupRow<any>[] = [];
    private dataGrid?: DataGridModel;
    private valueAccessor = (item: GroupRow<any>) => item.value;
    private countAccessor? = (item: GroupRow<any>) => item.count;

    public constructor(
        private readonly searchApi: (query: Query) => Promise<QueryResult<T>>,
        private readonly queryApi: (query: Query, depth: number) => Promise<QueryResult<T>>,
        private readonly maxGroupByRecords = 1000,
        private readonly selections?: QueryBasedResourceSelection,
        private readonly fallbackSortFields: QuerySortExpr[] = [{ Direction: 'Asc', Expr: { Field: 'Id' } }],
        private readonly sortFields?: { count?: string | null; value?: string }
    ) {
        const countField = this.sortFields?.count;
        const valueField = this.sortFields?.value;
        if (countField) {
            this.countAccessor = (item: GroupRow<any>) => (item as unknown as Record<string, number>)[countField];
        } else if (countField === null) {
            this.countAccessor = undefined;
        }
        if (valueField) {
            this.valueAccessor = (item: GroupRow<any>) => (item as unknown as Record<string, string>)[valueField];
        }
    }

    public attach(dataGrid: DataGridModel) {
        this.dataGrid = dataGrid;
    }

    public getPage = async (start: number, end: number, state: DataGridState, parent?: T, defaultCriteria?: QueryExpr, flatQuery?: boolean) => {
        const { where: filterWhere, sort } = await this.getQuery(state, defaultCriteria);
        const { groupId, select, where: groupWhere, parents } = this.getGroupByQuery(state, parent as unknown as GroupRow<any>);

        if (select && !flatQuery) {
            if (!parent) {
                await this.updateCount(filterWhere);
            }
            return await this.getGroupByResults(groupId!, select, filterWhere, groupWhere, parent, parents.length);
        } else {
            return await this.getFlatResults(filterWhere, groupWhere, start, end, sort);
        }
    };

    private async updateCount(where?: QueryExpr) {
        if (this.selections) {
            const response = await this.queryApi({ Where: where, Take: 0 }, 0);
            this.selections.updateQuery(where, response.Count ?? 0);
        }
    }

    private async getFlatResults(
        filterWhere: QueryExpr | undefined,
        groupWhere: QueryExpr | undefined,
        start: number,
        end: number,
        sort: QuerySortExpr[]
    ) {
        const where = this.combineWhere(filterWhere, groupWhere);
        const response = await this.searchApi({ Where: where, Skip: start, Take: end - start + 1, Sort: sort });
        if (!groupWhere) {
            this.selections?.updateQuery(where, response.Count ?? 0);
        }
        return { items: response.Results, total: response.Count };
    }

    private async getGroupByResults(
        groupId: string,
        select: QuerySelectExpr[],
        filterWhere: QueryExpr | undefined,
        groupWhere: QueryExpr | undefined,
        parent?: T,
        depth: number = 0
    ) {
        const where = this.combineWhere(filterWhere, groupWhere);
        const response = (await this.queryApi({ Where: where, Take: this.maxGroupByRecords, Select: select }, depth)) as unknown as QueryResult<{
            value: string;
            count: number;
        }>;

        let items: GroupRow<any>[];
        const currentItems = response.Results?.map((r) => ({ ...r, groupId, parent } as GroupRow<T>)) ?? [];
        if (this.previousGroupByItems.length > 0) {
            items = this.previousGroupByItems;

            items.forEach((rawItem) => {
                const item = rawItem as unknown as GroupRow<any>;
                const i = currentItems.find((currentItem) => currentItem.value == item.value)!;
                Object.assign(item, i);
                var node = this.dataGrid?.treeModel?.getTreeNode(item);
                node?.invalidateChildren(true);
            });
        } else {
            items = currentItems;
        }

        const groupConfig = this.dataGrid?.getGroupBy().find((g) => g.id === groupId);
        const sortHandlers = [
            this.sortByOtherValue,
            ...this.getSortGroupByModifier(groupConfig?.sortDir ?? 'Desc', groupConfig?.sortMode ?? 'count'),
        ];
        items.sort(this.createSortHandler(sortHandlers));

        return { items, total: response.Results?.length };
    }
    private createSortHandler(sortHandlers: ((a: GroupRow<any>, b: GroupRow<any>) => number)[]) {
        return (a: GroupRow<any>, b: GroupRow<any>) => {
            for (const sortHandler of sortHandlers) {
                const result = sortHandler(a, b);
                if (result !== 0) {
                    return result;
                }
            }
            return 0;
        };
    }
    private sortByOtherValue = (a: GroupRow<any>, b: GroupRow<any>) => {
        return this.valueAccessor(a) === ValuesGroupOtherText ? 1 : this.valueAccessor(b) === ValuesGroupOtherText ? -1 : 0;
    };
    private sortGroupByCount = (a: GroupRow<any>, b: GroupRow<any>) => {
        return (
            this.countAccessor!(a) - this.countAccessor!(b) ||
            this.valueAccessor(a).localeCompare(this.valueAccessor(b), undefined, { sensitivity: 'base' })
        );
    };
    private sortGroupByValue = (a: GroupRow<any>, b: GroupRow<any>) => {
        return this.valueAccessor(a).localeCompare(this.valueAccessor(b), undefined, { sensitivity: 'base' });
    };
    private getSortGroupByModifier(dir: 'Asc' | 'Desc', mode: 'count' | 'value') {
        const aggSort = [...this.getAggSort()];
        if (!aggSort.length) {
            const modifier = dir === 'Asc' ? 1 : -1;
            const comparer = mode === 'value' || !this.countAccessor ? this.sortGroupByValue : this.sortGroupByCount;
            return [
                (a: any, b: any) => {
                    return comparer(a, b) * modifier;
                },
            ];
        }
        return aggSort;
    }

    private *getAggSort() {
        const aggSortColumns = this.getAggSortColumns();
        if (aggSortColumns) {
            for (const aggSortColumn of aggSortColumns) {
                const field = aggSortColumn.field ?? aggSortColumn.column?.sortField ?? aggSortColumn.column?.config.id ?? '';
                const modifier = aggSortColumn.dir === 'Asc' ? 1 : -1;
                if (field === this.sortFields?.value) {
                    yield (a: any, b: any) => {
                        return this.sortGroupByValue(a, b) * modifier;
                    };
                } else if (aggSortColumn.column?.type === 'number') {
                    yield (a: any, b: any) => {
                        return (typeof a[field] !== 'number' ? -1 : typeof b[field] !== 'number' ? 1 : a[field] - b[field]) * modifier;
                    };
                }
            }
        }
    }

    private getAggSortColumns(sort: QuerySortExpr[] = this.dataGrid?.gridState.sort ?? []) {
        const gridSortColumns = this.getSortColumns(sort);
        const aggSortColumns = gridSortColumns?.filter((s) => s?.column?.aggregator || s?.field === this.sortFields?.value);
        return aggSortColumns;
    }

    private getSortColumns(sort: QuerySortExpr[] = this.dataGrid?.gridState.sort ?? []) {
        const gridSortState = sort.map((s) => (s.Expr && 'Field' in s.Expr ? { field: s.Expr.Field as string, dir: s.Direction } : null));
        const gridSortColumns = gridSortState?.map((s) => ({
            ...s,
            column: s?.field ? this.dataGrid?.columns.find((c) => c.config.sortField === s?.field) : undefined,
        }));
        return gridSortColumns;
    }

    private combineWhere(whereA: QueryExpr | undefined, whereB: QueryExpr | undefined) {
        return whereA && whereB ? { Operation: 'and', Operands: [whereA, whereB] } : whereA ?? whereB;
    }

    private getGroupByQuery(state?: DataGridState, parent?: GroupRow<any>) {
        const { groupBy } = state ?? this.dataGrid?.gridState ?? {};
        let select: QuerySelectExpr[] | undefined = undefined;
        let where: QueryExpr | undefined = { Operation: 'and', Operands: [] };

        const parents = [...this.groupByIterator(parent)];
        if (parents.length) {
            where.Operands.push(
                ...parents.map((p) =>
                    p.row.value === ValuesGroupOtherText
                        ? { Operation: 'isNull', Operands: [{ Field: p.filterField }] }
                        : { Operation: 'eq', Operands: [{ Field: p.filterField }, { Value: p.row.value }] }
                )
            );
        } else {
            where = undefined;
        }
        const parentGroup = groupBy?.find((g) => !parents.find((p) => p.row.groupId === g.id));
        const parentColumn = parentGroup ? this.dataGrid?.getColumnById(parentGroup.id) : null;
        const groupId = parentColumn ? parentColumn.config.id : null;
        const filterField = parentColumn && typeof parentColumn.config.filter === 'object' ? parentColumn.config.filter.filterField : null;
        if (filterField) {
            select = [
                {
                    Alias: 'value',
                    Expr: {
                        Operation: 'values',
                        Operands: [{ Field: filterField }, { Value: null }, { Value: parentColumn?.type === 'date' ? null : ValuesGroupOtherText }],
                    },
                },
                { Alias: 'count', Expr: { Operation: 'count' } },
            ];
            if (state?.columns) {
                for (const columnState of state?.columns) {
                    const column = this.dataGrid?.getColumnById(columnState.id);
                    if (column && column.aggregator) {
                        select.push({
                            Alias: typeof column.config.accessor === 'string' ? column.config.accessor : column.config.sortField ?? column.config.id,
                            Expr: { Operation: this.getAggOperations(column.aggregator), Operands: [{ Field: column.getAggregatedField() }] },
                        });
                    }
                }
            }
        }
        return { groupId, select, where, parents };
    }

    private getAggOperations(aggregator: string) {
        switch (aggregator.toLocaleLowerCase()) {
            case 'average':
                return 'avg';
            default:
                return aggregator;
        }
    }

    public getQuery(state?: DataGridState, defaultCriteria?: QueryExpr) {
        const { filters, sort: userSort } = state ?? this.dataGrid?.gridState ?? {};
        const showOnlySelected = this.dataGrid?.getShowOnlySelected();
        const where: QueryExpr = { Operation: 'and', Operands: filters?.length ? filters.slice() : [] };

        if (defaultCriteria) {
            where.Operands.push(defaultCriteria);
        }

        if (this.selections?.count() === 0 || !showOnlySelected) {
        } else {
            const selectionCriteria = this.selections?.createIdCriteria();
            if (selectionCriteria) {
                where.Operands.push(selectionCriteria);
            }
        }

        const sortColumns = this.getSortColumns(userSort ? [...userSort] : []).filter((s) => s.field !== this.sortFields?.value);
        const sort = sortColumns?.map(
            (s) =>
                ({
                    Direction: s?.dir ?? 'Asc',
                    Expr: { Field: s.column?.aggregator ? s.column.getAggregatedField() : s?.field, Type: s.column?.type },
                } as QuerySortExpr)
        );
        if (!sort.length) {
            sort.push(...this.fallbackSortFields);
        }
        return { where: where.Operands.length ? where : undefined, sort };
    }

    private *groupByIterator(parent?: GroupRow<any>) {
        let result = parent;
        while (result) {
            const column = this.dataGrid?.getColumnById(result.groupId);
            const filterField = column && typeof column.config.filter === 'object' ? column.config.filter.filterField : null;
            if (filterField) {
                yield { row: result, filterField };
            }
            result = result.parent as GroupRow<any>;
        }
    }
}
