import { getFocusGetConversionInfo } from '@apis/Invoices';
import { ConversionPlanBundle, FocusFieldInfo } from '@apis/Invoices/model';
import { singleton } from 'tsyringe';
import { Platform } from '../PlatformService';

const schemaPlatformLookup = new Map<string, Platform>([
    ['AwsCurV1', 'Aws'],
    ['AwsDataExportsCurV2', 'Aws'],
    ['AzureCostExportIdeal20240101', 'Azure'],
]);

export class FocusMetadataInfo {
    private readonly focusFieldByField = new Map<string, FocusFieldInfo>();
    private readonly focusFieldByAlias = new Map<string, FocusFieldInfo>();
    private readonly cspFields = new Map<Platform, { cspAlias: string; field: FocusFieldInfo }[]>();
    private readonly cspAliasLookup = new Map<string, string>();

    public constructor(public readonly focusMeta: ConversionPlanBundle) {
        for (const field of focusMeta.FocusFields ?? []) {
            if (!field.Ignore) {
                this.focusFieldByField.set(field.Field ?? '', field);
                for (const alias of field.Aliases ?? []) {
                    this.focusFieldByAlias.set(alias.Field ?? '', field);
                }
            }
        }
    }

    public getCspAliasedFields(platform: Platform) {
        if (!this.cspFields.size) {
            this.createCspFieldLookup();
        }
        return this.cspFields.get(platform);
    }
    public getCspAlias(platform: Platform, focusField: string) {
        if (!this.cspAliasLookup.size) {
            this.createCspFieldLookup();
        }
        return this.cspAliasLookup.get(this.getCspAliasKey(platform, focusField));
    }
    public getFieldByAlias(alias: string) {
        return this.focusFieldByAlias.get(alias);
    }
    public getField(field: string) {
        return this.focusFieldByField.get(field);
    }
    public getFields() {
        return this.focusMeta.FocusFields ?? [];
    }

    private createCspFieldLookup() {
        for (const field of this.focusMeta.FocusFields ?? []) {
            for (const alias of field.Aliases ?? []) {
                const platform = schemaPlatformLookup.get(alias.Schema ?? '');
                if (platform && !field.Ignore && alias.Field !== field.Field && !!alias.Field && !!field.Field) {
                    const cspFields = this.cspFields.get(platform) ?? [];
                    cspFields.push({ cspAlias: alias.Field, field });

                    this.cspFields.set(platform, cspFields);
                    this.cspAliasLookup.set(this.getCspAliasKey(platform, field.Field), alias.Field);
                }
            }
        }
    }

    private getCspAliasKey(platform: Platform, focusField: string) {
        return `${platform}-${focusField}`;
    }
}

@singleton()
export class FocusMetadataService {
    private _focusMetadata?: Promise<FocusMetadataInfo>;

    public constructor() {}

    public getFocusMetadata(): Promise<FocusMetadataInfo> {
        return (this._focusMetadata ??= this.loadFocusMetadata());
    }

    private async loadFocusMetadata() {
        const conversionBundle = await getFocusGetConversionInfo();
        return new FocusMetadataInfo(conversionBundle);
    }
}
