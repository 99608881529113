import { makeAutoObservable } from 'mobx';
import {
    deleteTagAutomationRemoveTagAutomationRule,
    getTagAutomationGetTagAutomationRuleDetails,
    getTagAutomationGetTagAutomationRules,
} from '@apis/TagManager';
import { TagAutomationRule, TagAutomationRuleDetails, TagAutomationRuleParametersType } from '@apis/TagManager/model';
import { Lifecycle, scoped, singleton } from 'tsyringe';
import { EventEmitter } from '@root/Services/EventEmitter';

@singleton()
export class TagAutomationRuleEvents {
    public ruleSaved = EventEmitter.empty();
}

@scoped(Lifecycle.ContainerScoped)
export class TagAutomationRuleService {
    public rules: TagAutomationRule[] = [];
    public ruleDetails: TagAutomationRuleDetails = {};
    public ruleTypes = new Set<TagAutomationRuleParametersType>([
        'AutoTag',
        'Inheritance',
        'Replacement',
        'SyntaxCorrection',
        'ResourceGroup',
        'AwsOUAccountGroup',
        'BulkTag',
    ]);
    public loading = {
        dashboard: false,
        details: false,
        editableRule: false,
        saving: false,
    };
    public drawerOpen: boolean = false;
    public previewDrawerOpen: boolean = false;

    public constructor() {
        makeAutoObservable(this);
    }

    public async refreshRules() {
        this.loading.dashboard = true;
        await getTagAutomationGetTagAutomationRules()
            .then((results) => {
                this.rules = (results ?? []).filter((r) => r.Parameters?.Type && this.ruleTypes.has(r.Parameters.Type));
            })
            .catch(() => {
                this.rules = [];
            })
            .finally(() => {
                this.loading.dashboard = false;
            });
    }

    public async deleteRule(rule: TagAutomationRule) {
        await deleteTagAutomationRemoveTagAutomationRule({ ruleId: rule.Id, companyId: rule.CompanyId });
        this.silentRefresh();
    }

    private silentRefresh() {
        return getTagAutomationGetTagAutomationRules().then((results) => {
            this.rules = (results ?? []).filter((r) => r.Parameters?.Type && this.ruleTypes.has(r.Parameters.Type));
        });
    }

    private async getRuleDetails(rule: TagAutomationRule) {
        this.loading.details = true;
        await getTagAutomationGetTagAutomationRuleDetails({ ruleId: rule.Id })
            .then((results) => {
                this.ruleDetails = results ?? {};
            })
            .catch((error) => {
                this.ruleDetails = {};
            })
            .finally(() => {
                this.loading.details = false;
            });
    }

    public async openDetailsDrawer(rule: TagAutomationRule) {
        this.drawerOpen = true;
        await this.getRuleDetails(rule);
    }

    public closeDetailsDrawer = () => {
        this.drawerOpen = false;
    };

    public async openPreviewDrawer() {
        this.previewDrawerOpen = true;
    }

    public closePreviewDrawer = () => {
        this.previewDrawerOpen = false;
    };
}
