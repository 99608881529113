import { Box, Group, Loader, LoadingOverlay, Space, Switch, Text, useMantineTheme } from '@mantine/core';
import { observer } from 'mobx-react';
import { RuleEditCard } from './Design';
import { RuleEditor } from './Model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDiContainer } from '@root/Services/DI';
import { Clearfix } from '@root/Design/Primitives';
import { FilterResources, ValidationIssues } from './FilterResources';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { useEvent } from '@root/Services/EventEmitter';
import { injectable } from 'tsyringe';
import { TagAutomationRule, TagAutomationRuleParametersType, ResourceGroupRuleOptions, TagRuleSyntax, TypeMapping } from '@apis/TagManager/model';
import { useFilterValidation } from './RuleDescriber';
import { makeAutoObservable } from 'mobx';
import { BaseAzureResource, IQueryExpr, Query, SelectedResourceTags } from '@apis/Resources/model';
import {
    QueryExpr,
    QueryResult,
    getResources,
    postResourceTagAutomationGetFilteredResourceTypes,
    postResourcesQuery,
    postResourcesSearch,
} from '@apis/Resources';
import { exprBuilder, queryBuilder } from '@root/Services/QueryExpr';
import { set } from 'date-fns';
import styled from '@emotion/styled';

interface ResourceGroupTagSelection {
    resourceGroupName: string;
    tagKey: string;
    tagValue: string;
}

@injectable()
export class ResourceGroupEditor {
    public options: ResourceGroupRuleOptions = {};
    public syntax: TagRuleSyntax = {};
    public ruleEditor: RuleEditor = {} as RuleEditor;
    public filter: IQueryExpr = {};
    public rule?: TagAutomationRule = undefined;
    public constructor() {
        makeAutoObservable(this);
    }
    public init(ruleEditor: RuleEditor) {
        const rule = ruleEditor.rule!;
        rule.Parameters ??= {};
        rule.Parameters.Filter ??= {};
        rule.Parameters.Syntax ??= {};
        rule.Parameters.Syntax.ResourceGroupRuleOptions ??= {};
        rule.Parameters.Syntax.ResourceGroupRuleOptions.SelectedTags ??= [];
        this.options = rule.Parameters.Syntax.ResourceGroupRuleOptions ??= {};
        this.syntax = rule.Parameters.Syntax ??= {};
        this.ruleEditor = ruleEditor;
        this.rule = rule;
        return this;
    }

    public setSelectedTags(selectedTags: SelectedResourceTags[]) {
        this.options.SelectedTags = selectedTags;
    }

    public setFilter(resourceGroups: string[]) {
        if (resourceGroups.length !== 0) {
            var query = queryBuilder<BaseAzureResource>()
                .where((b) => b.and(b.model.ResourceType!.ne('Microsoft.Resources/resourceGroups'), b.model.ResourceGroup!.eq(resourceGroups)))
                .build();
            this.rule!.Parameters!.Filter! = query.Where!;
        } else {
            this.rule!.Parameters!.Filter! = {};
        }
    }

    public setApplyAllTags(applyAllTags: boolean) {
        this.options.ApplyAllTags = applyAllTags;
    }

    public getErrors() {
        const result: string[] = [];
        return result;
    }
}

export const ResourceGroupActionCard = observer(function ResourceGroupActionCard({
    resourceGroupEditor,
}: {
    resourceGroupEditor: ResourceGroupEditor;
}) {
    const theme = useMantineTheme();
    const [resourceGrid, setResourceGrid] = useState<DataGridModel>();
    const [tagGrid, setTagGrid] = useState<DataGridModel>();
    const [resourceGroups, setResourceGroups] = useState<BaseAzureResource[]>([]);
    const [resourceGroupTags, setResourceGroupTags] = useState<ResourceGroupTagSelection[]>([]);
    const [selectedResourceGroups, setSelectedResourceGroups] = useState<BaseAzureResource[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (resourceGroupEditor.options.SelectedTags != undefined && resourceGroupEditor.options.SelectedTags.length > 0) {
            const previouslySelectedResourceGroups = resourceGroupEditor.options.SelectedTags.map((t) => t.ResourceGroup!);
            setSelectedResourceGroups(resourceGroups.filter((r) => previouslySelectedResourceGroups.includes(r.Name!)));
        }
    }, [resourceGrid?.selections]);

    const initialSelectedResourceGroups = useMemo(() => {
        return resourceGroupEditor.options.SelectedTags?.map((t) => t.ResourceGroup!);
    }, [resourceGroupEditor.options.SelectedTags]);

    const initialSelectedTags = useMemo(() => {
        return resourceGroupEditor.options.SelectedTags?.map((t) => {
            return t.SelectedTags?.map((s) => {
                return { resourceGroupName: t.ResourceGroup!, tagKey: s };
            });
        }).flat();
    }, [resourceGroupEditor.options.SelectedTags]);

    //first grid data load
    useEffect(() => {
        (async () => {
            setLoading(true);
            await queryBuilder<BaseAzureResource>()
                .where((b) => b.model.ResourceType!.eq('Microsoft.Resources/resourceGroups'))
                .take(1000)
                .execute(postResourcesQuery)
                .then((r) => {
                    setResourceGroups(r.Results!);
                    setLoading(false);
                });
        })();
    }, [resourceGroupEditor.rule?.Parameters?.Type]);

    //second grid data load
    useMemo(async () => {
        if (selectedResourceGroups.length > 0) {
            const tagOptions = selectedResourceGroups.map((r) => {
                let results = [] as ResourceGroupTagSelection[];
                if (r.Tags != null || r.Tags != undefined) {
                    r.Tags.forEach((tag) => {
                        let tagOption = { resourceGroupName: r.Name } as ResourceGroupTagSelection;
                        tagOption.tagKey = tag.Key!;
                        tagOption.tagValue = tag.Value!;
                        results.push(tagOption);
                    });
                }
                return results;
            });
            setResourceGroupTags(tagOptions.flat());
        }
    }, [selectedResourceGroups]);

    //update selected resource groups
    useEvent(resourceGrid?.selectionChanged, async () => {
        const selectedResources = (await resourceGrid?.selections.getSelected()) as BaseAzureResource[];
        if (selectedResources != null || selectedResources != undefined) {
            setSelectedResourceGroups(selectedResources);
        } else {
            resourceGroupEditor.ruleEditor.setTypeErrors(TagAutomationRuleParametersType.ResourceGroup, [
                'You must select at least one resource group to copy tags from.',
            ]);
            setSelectedResourceGroups([]);
            setResourceGroupTags([]);
        }
    });

    //update selected tags
    useEvent(tagGrid?.selectionChanged, async () => {
        const selectedTags = (await tagGrid?.selections.getSelected()) as ResourceGroupTagSelection[];
        if (selectedTags != null || selectedTags != undefined) {
            var resourceGroups: string[] = [];
            let selectionToSave: SelectedResourceTags[] = [];
            //make distinct list of resource groups
            selectedTags.forEach((tag) => {
                if (!resourceGroups.includes(tag.resourceGroupName)) resourceGroups.push(tag.resourceGroupName);
            });

            resourceGroups.forEach((resourceGroupName) => {
                var uniqueTagsForRg = selectedTags.filter((tag) => tag.resourceGroupName == resourceGroupName);
                let tagOptions = { ResourceGroup: resourceGroupName } as SelectedResourceTags;
                tagOptions.SelectedTags = uniqueTagsForRg.map((tag) => tag.tagKey);
                selectionToSave.push(tagOptions);
            });

            resourceGroupEditor.setSelectedTags(selectionToSave);
            resourceGroupEditor.setFilter(resourceGroups);
            resourceGroupEditor.ruleEditor.ruleTypeValidation[TagAutomationRuleParametersType.ResourceGroup] = [];
        } else {
            resourceGroupEditor.ruleEditor.setTypeErrors(TagAutomationRuleParametersType.ResourceGroup, [
                'You must select at least one tag to copy from a resource group.',
            ]);
            resourceGroupEditor.setSelectedTags([]);
            resourceGroupEditor.setFilter([]);
        }
    });

    //set resource group columns Add top 5 tags?
    const resourceColumns = useMemo(() => {
        const columns: ColumnConfig<BaseAzureResource>[] = [
            {
                accessor: (item) => item.Name,
                header: 'Resource Group Name',
                id: 'name',
                defaultWidth: 200,
            },
            {
                accessor: (item) => item.Tags?.length,
                header: 'Tag Count',
                id: 'tagCount',
                defaultWidth: 200,
            },
        ];
        return columns;
    }, []);

    //
    const tagColumns = useMemo(() => {
        const columns: ColumnConfig<ResourceGroupTagSelection>[] = [
            {
                accessor: (item) => item.resourceGroupName,
                header: 'Resource Group Name',
                id: 'name',
                defaultWidth: 200,
            },
            {
                accessor: (item) => item.tagKey,
                header: 'Tag Key',
                id: 'tagKey',
                defaultWidth: 200,
            },
            {
                accessor: (item) => item.tagValue,
                header: 'Tag Value',
                id: 'tagValue',
                defaultWidth: 200,
            },
        ];
        return columns;
    }, []);

    const handleApplyAllTagsChange = (applyAllTags: boolean) => {
        resourceGroupEditor.setApplyAllTags(applyAllTags);
    };

    const filterValidityChanged = useFilterValidation(resourceGroupEditor.ruleEditor);

    return (
        <>
            <RuleEditCard
                height={400}
                title="Select Resource Groups"
                description="Select Resource Groups whose tags should be copied to the groups' resources"
            >
                {loading ? (
                    <Loader />
                ) : (
                    <div style={{ height: '300px' }}>
                        <DataGrid
                            dataSource={resourceGroups}
                            columns={resourceColumns}
                            initialSelection={resourceGroups.filter((r) => {
                                return initialSelectedResourceGroups?.includes(r.Name!);
                            })}
                            onModelLoaded={setResourceGrid}
                            hideFilter={true}
                            hideMenu={true}
                            hideGlobalSearch={true}
                            selectionMode={'multiple'}
                            onRowClick="select"
                        />
                    </div>
                )}
            </RuleEditCard>
            <Space h="md" />
            {resourceGroupTags.length > 0 ? (
                <RuleEditCard title="Select Tags to Copy" description="Which Resource Group tags should be inherited by resources?">
                    <div style={{ margin: '10px' }}>
                        <Switch
                            sx={{ display: 'inline-block' }}
                            defaultChecked={resourceGroupEditor.options.ApplyAllTags}
                            onChange={(e: any) => handleApplyAllTagsChange(e.currentTarget.checked)}
                        ></Switch>
                        <Text style={{ color: theme.colors.gray[6], fontSize: '14px', marginLeft: '10px', display: 'inline-block' }}>
                            Apply all tags current and future
                        </Text>
                    </div>
                    <div style={{ height: '300px' }}>
                        <DataGrid
                            dataSource={resourceGroupTags}
                            columns={tagColumns}
                            initialSelection={resourceGroupTags.filter((r) => {
                                return (
                                    initialSelectedTags?.find((t) => t?.resourceGroupName == r.resourceGroupName && t.tagKey == r.tagKey) != undefined
                                );
                            })}
                            onModelLoaded={setTagGrid}
                            hideFilter={true}
                            hideMenu={true}
                            hideGlobalSearch={true}
                            selectionMode={'multiple'}
                        />
                    </div>
                </RuleEditCard>
            ) : null}
            <Space h="md" />
            <RuleEditCard title="Select Filters" description="Add filtering to narrow down which resources will be impacted">
                {resourceGroupEditor.rule ? (
                    <>
                        <ResourceGroupResourceFilter typeEditor={resourceGroupEditor} onValidationIssuesChanged={filterValidityChanged} />
                    </>
                ) : null}
            </RuleEditCard>
        </>
    );
});

export function ResourceGroupResourceFilter({
    typeEditor,
    onValidationIssuesChanged,
}: {
    typeEditor: ResourceGroupEditor;
    onValidationIssuesChanged: (issues: ValidationIssues) => void;
}) {
    let rule: TagAutomationRule = typeEditor.rule!;
    const normalizeFilter = (rule: TagAutomationRule) => {
        let result: QueryExpr[];
        rule.Parameters ??= {};
        rule.Parameters.Syntax ??= {};
        rule.Parameters.Syntax.ResourceGroupRuleOptions ??= {};
        rule.Parameters.Syntax.ResourceGroupRuleOptions.Filter ??= { Operation: 'and', Operands: (result = []) };
        if (!('Operation' in rule.Parameters.Syntax.ResourceGroupRuleOptions.Filter)) {
            rule.Parameters.Filter = { Opearation: 'and', Operands: (result = []) };
        } else {
            result = rule.Parameters.Syntax.ResourceGroupRuleOptions.Filter.Operands ??= [];
        }
        return result;
    };
    const filter = useMemo(() => normalizeFilter(rule), [rule, rule.Parameters?.Syntax?.ResourceGroupRuleOptions?.Filter]);
    const onChange = useCallback((nextFilter: QueryExpr[]) => {
        filter.splice(0, Infinity, ...nextFilter);
    }, []);
    return <FilterResources filter={filter} onChange={onChange} onValidationIssuesChanged={onValidationIssuesChanged} />;
}
