import { ForecastConfig, ForecastConfigCostFieldType } from '@apis/Invoices/model';
import { useThrottledEffect } from '@react-hookz/web';
import {
    Box,
    Button,
    Center,
    CloseButton,
    Divider,
    Drawer,
    Grid,
    Group,
    Loader,
    LoadingOverlay,
    MultiSelect,
    Select,
    Stack,
    Text,
    TextInput,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { PanelToolbar } from '@root/Design/Layout';
import { useDi } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { getGroupOptions } from '@root/Services/Invoices/CostForecastService';
import { InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { SchemaService } from '@root/Services/QueryExpr';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { AlertTriangle } from 'tabler-icons-react';
import { SpendForecastModel } from './Models';
import { getForecastGetForecastConfig, postForecastEstimateCardinality } from '@apis/Invoices';
import { ForecastCard, ForecastCardTitle } from './Components';
import { addMonths, differenceInDays, startOfMonth } from 'date-fns';
import { openConfirmModal } from '@mantine/modals';
import { IntegrationSchemaService } from '@root/Services/Integrations/IntegrationSchemaService';
import { CompanyConfigService } from '@root/Services/Customers/CompanyConfigService';

export function ForecastDetails({
    ranges,
    closeSidePanel,
    onSave,
}: {
    ranges: { accountName: string; accountId: string; from: Date; to: Date }[];
    closeSidePanel: () => void;
    onSave?: (dimensions: string[], costField: ForecastConfigCostFieldType) => Promise<void>;
}) {
    const invoiceSchemaSvc = useDi(InvoiceSchemaService);
    const companyConfigSvc = useDi(CompanyConfigService);
    const integrationSchemaSvc = useDi(IntegrationSchemaService);
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);
    const [dimensions, setDimensions] = useState<string[]>([]);
    const [dimensionOptions, setDimensionOptions] = useState<{ label: string; value: string }[]>([]);
    const [currentConfig, setCurrentConfig] = useState<ForecastConfig>({
        MaxMonths: 9,
        MaxPredictionDays: 90,
        CostFieldType: 'Billed',
        Dimensions: [],
    });
    const [costField, setCostField] = useState<ForecastConfigCostFieldType>('Billed');
    const [hasAdjustedCost, setHasAdjustedCost] = useState(false);
    const [cardinality, setCardinality] = useState<number>();
    const [cardinalityCheckStatus, setCardinalityCheckStatus] = useState<'timedout' | 'failed' | 'loading' | 'none'>();
    const [saving, setSaving] = useState(false);
    const cardinalityReqKey = useMemo(() => ({ lastRequest: 1 }), []);
    useEffect(() => {
        (async () => {
            const [currentConfig, companyConfig] = await Promise.all([getForecastGetForecastConfig(), companyConfigSvc.getTypedCompanyConfig()]);
            setDimensions(currentConfig?.Dimensions ?? []);
            const companyMaxHorizon = companyConfig.forecastMaxPredictionDays ? parseInt(companyConfig.forecastMaxPredictionDays) : 90;
            setCurrentConfig({ MaxPredictionDays: companyMaxHorizon, ...currentConfig });

            const integrationTypes = await integrationSchemaSvc.getIntegrationSchema();
            const integrationNames = new Set(integrationTypes.map((n) => n.IsRoot && n.Name));
            const types = await invoiceSchemaSvc.getMonthlySchema(); // todo: update forecast field options
            const schema = new SchemaService(types);
            const hasAdjustedCost = !!schema.getField('AdjustedCashCost');
            setCostField(currentConfig?.CostFieldType ?? 'Billed');
            setHasAdjustedCost(hasAdjustedCost);
            const tagFields = [...new Set(schema.types.filter((t) => t.Name?.startsWith('Tags')).flatMap((t) => t.Fields ?? []))]
                .filter((f) => f.Field?.startsWith('resourceTags/user:'))
                .map((f) => f.Field!)
                .sort();
            const integrationFields = schema.types
                .filter((t) => integrationNames.has(t.TypeId))
                .flatMap((t) => t.Fields ?? [])
                .map((f) => f.Field!)
                .sort();
            const nonStandardFields = [...tagFields, ...integrationFields];

            setDimensionOptions(getGroupOptions(nonStandardFields));
        })().finally(() => setLoading(false));
    }, []);

    const getDimensionFields = useCallback((dim: string[]) => [...new Set(['lineItem/ProductCode', 'lineItem/UsageType', ...dim])], []);

    useThrottledEffect(
        () => {
            (async () => {
                cardinalityReqKey.lastRequest++;
                const key = cardinalityReqKey.lastRequest;
                setCardinality(undefined);
                setCardinalityCheckStatus('loading');
                try {
                    const result = await postForecastEstimateCardinality({
                        Groups: getDimensionFields(dimensions),
                    });
                    if (key === cardinalityReqKey.lastRequest) {
                        setCardinality(result.Cardinality ?? -1);
                        setCardinalityCheckStatus(
                            result.TimedOut ? 'timedout' : result.Failed ? 'failed' : typeof result.Records !== 'number' ? 'none' : undefined
                        );
                    }
                } catch (error) {
                    setCardinality(-1);
                }
            })();
        },
        [dimensions],
        500
    );

    const saveConfig = useCallback(async () => {
        setSaving(true);
        try {
            await onSave?.(getDimensionFields(dimensions), costField);
        } finally {
            setSaving(false);
        }
    }, [dimensions, costField, onSave]);

    const minMonth = addMonths(startOfMonth(new Date()), -(currentConfig.MaxMonths ?? 9) - 1);
    const oldestAvailData = ranges.map((r) => r.from).reduce((result, date) => (result < date ? result : date), new Date());
    const earliestStartDate = oldestAvailData < minMonth ? minMonth : oldestAvailData;
    const daysSinceStartDate = !earliestStartDate ? 0 : differenceInDays(new Date(), earliestStartDate);
    const currentHorizon = currentConfig.MaxPredictionDays ?? 90;
    const analysisStartDate = earliestStartDate;
    const effectiveCardinality = (cardinality ?? -1) < 0 ? 1000000 : (cardinality as number);
    const cardinalityRange =
        cardinalityCheckStatus === 'none' || cardinalityCheckStatus === 'timedout' || cardinalityCheckStatus === 'failed'
            ? 'invalid'
            : effectiveCardinality > 500000
            ? 'high'
            : effectiveCardinality > 250000
            ? 'warning'
            : 'success';
    const cardinalityColor =
        cardinalityRange === 'high' ? theme.colors.error[6] : cardinalityRange === 'warning' ? theme.colors.warning[5] : theme.colors.success[6];
    const costOptions: { label: string; value: ForecastConfigCostFieldType }[] = [
        { label: 'Invoice', value: 'Billed' },
        { label: 'Showback (Amortized)', value: 'AdjustedAmortized' },
        { label: 'Showback', value: 'Adjusted' },
    ];

    return (
        <>
            {loading && <LoadingOverlay visible={true} />}
            <Box p="lg" sx={{ flex: 1, overflow: 'auto' }}>
                <Stack>
                    <ForecastCard>
                        <ForecastCardTitle
                            title="Historical Spend Analysis"
                            description={`Up to ${
                                currentConfig.MaxMonths ?? 9
                            } months of historical spend data will be analyzed and used for forecasting. `}
                        />
                        <Grid columns={3}>
                            <Grid.Col span={1}>
                                <TextInput
                                    label="From"
                                    sx={{ ['input:disabled']: { color: theme.colors.gray[7], opacity: 1 } }}
                                    value={analysisStartDate ? fmtSvc.formatShortMonthYear(analysisStartDate) : ''}
                                    disabled
                                />
                            </Grid.Col>
                        </Grid>
                    </ForecastCard>
                    <ForecastCard>
                        <ForecastCardTitle
                            title="Forecast Horizon"
                            description={`The forecast will extend up to one-third of the available historical data with a maximum of ${
                                currentConfig.MaxPredictionDays ?? 90
                            } days. `}
                        />
                        <Grid columns={3}>
                            <Grid.Col span={1}>
                                <TextInput
                                    label="Days"
                                    value={currentHorizon.toString()}
                                    sx={{ ['input:disabled']: { color: theme.colors.gray[7], opacity: 1 } }}
                                    disabled
                                />
                            </Grid.Col>
                        </Grid>
                    </ForecastCard>
                    {!hasAdjustedCost ? null : (
                        <ForecastCard>
                            <ForecastCardTitle
                                title="Cost Field"
                                description={`Forecasts will be based on the selected cost field, and amounts will reflect projected cost for the selected field.`}
                            />
                            <Grid columns={3}>
                                <Grid.Col span={1}>
                                    <Select data={costOptions} value={costField} onChange={(v) => setCostField(v as ForecastConfigCostFieldType)} />
                                </Grid.Col>
                            </Grid>
                        </ForecastCard>
                    )}

                    <ForecastCard>
                        <Grid columns={3}>
                            <Grid.Col span={2}>
                                <ForecastCardTitle
                                    title="Dimensions"
                                    description="Choose the dimensions (e.g., tags, regions, accounts) to group the forecast data by. Grouping by different dimensions can provide insights into specific cost drivers. Product code and usage type dimensions are included automatically. "
                                />
                                <MultiSelect
                                    withinPortal
                                    label="Group By"
                                    data={dimensionOptions}
                                    value={dimensions}
                                    onChange={setDimensions}
                                    dropdownPosition="flip"
                                    clearable
                                ></MultiSelect>
                            </Grid.Col>
                            <Grid.Col span={1}>
                                <Stack sx={{ height: '100%' }} py="lg" align="center" spacing={0}>
                                    <Text size="sm">Estimated forecast elements</Text>
                                    <Center sx={{ flex: 1 }}>
                                        {typeof cardinality !== 'number' ? (
                                            <Loader size="lg" />
                                        ) : cardinalityRange === 'invalid' ? null : (
                                            <Title sx={{ color: cardinalityColor }}>
                                                {(cardinalityRange === 'high' ? '>' : '') +
                                                    fmtSvc.formatInt0Dec(Math.min(effectiveCardinality, 500000))}
                                            </Title>
                                        )}
                                    </Center>
                                    <Text align="center" italic color="error" size="sm">
                                        {cardinalityCheckStatus === 'loading'
                                            ? ''
                                            : cardinalityRange === 'high'
                                            ? 'Selection would result in too many forecast elements. '
                                            : ''}
                                    </Text>
                                    <Text size="xs" align="center" color="dimmed">
                                        This represents the complexity of the forecast, based on the selected groups. This may not exceed 500,000.
                                    </Text>
                                </Stack>
                            </Grid.Col>
                        </Grid>
                    </ForecastCard>
                </Stack>
            </Box>
            <div>
                <Divider />
                <PanelToolbar>
                    <Button variant="outline" onClick={closeSidePanel}>
                        Cancel
                    </Button>
                    <Button
                        onClick={saveConfig}
                        disabled={saving || cardinalityRange === 'high' || cardinalityRange === 'invalid'}
                        rightIcon={saving ? <Loader color="gray.0" size="sm" /> : undefined}
                    >
                        {saving ? 'Saving' : 'Save'} Configuration
                    </Button>
                </PanelToolbar>
            </div>
        </>
    );
}

async function confirmChanges() {
    let confirm = () => {};
    const result = new Promise<void>((r) => (confirm = r));
    openConfirmModal({
        title: <Title order={4}>Confirm Changes</Title>,
        children: (
            <>
                <Text color="dimmed">
                    Changes to the forecast configuration will require significant time to process. The forecast will not be available during
                    reprocessing.
                </Text>
                <Text>Are you sure you'd like to continue?</Text>
            </>
        ),
        centered: true,
        labels: { confirm: 'Apply Changes', cancel: 'Cancel' },
        onConfirm: confirm,
        zIndex: 10000,
    });
    return await result;
}

export function TenantForecastConfigDrawer({ model, onFinished }: { model: SpendForecastModel; onFinished: () => void }) {
    const notificationSvc = useDi(NotificationService);
    const onSave = useCallback(
        async (dimensions: string[], costField: ForecastConfigCostFieldType) => {
            try {
                const currentConfig = await model.getTenantConfig();
                if (currentConfig && JSON.stringify(currentConfig.Dimensions?.sort()) !== JSON.stringify(dimensions?.sort())) {
                    await confirmChanges();
                }
                const job = await model.saveTenantConfig(dimensions, costField);
                if (job.Status === 'Failed') {
                    showForecastError();
                } else {
                    model.closeConfig();
                    onFinished();
                }
            } catch (error) {
                showForecastError();
            }
        },
        [model]
    );

    function showForecastError() {
        notificationSvc.notify('Error', 'Failed to save forecast configuration. Please try again.', 'error', <AlertTriangle />);
    }

    const configRequested = useEventValue(model.configRequested);
    const show = !!configRequested;
    return (
        <Drawer opened={show} onClose={model.closeConfig} position="right" size={850} withCloseButton={false}>
            <Stack sx={{ height: '100%' }} spacing={0}>
                {!show ? null : (
                    <>
                        <Box>
                            <Group position="apart" p="lg">
                                <Title order={4}>Cost Forecast Configuration</Title>
                                <CloseButton onClick={model.closeConfig} />
                            </Group>
                            <Divider />
                        </Box>
                        <ForecastDetails onSave={onSave} ranges={model.accountDateRange} closeSidePanel={model.closeConfig} />
                    </>
                )}
            </Stack>
        </Drawer>
    );
}
