/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Invoices.Host.Api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ObjectQueryResult,
  Query,
  PostCspInvoiceDataDigestContollerQueryParams,
  PostCspInvoiceDataDigestContollerMultiQueryParams,
  PostCspInvoiceDataDigestContollerStreamParams,
  DeleteCurSummary,
  PostCurDeleteParams,
  PostDailyRollupQueryParams,
  PostDailyRollupStreamParams,
  PostDailyRollupQueryDailyResourceSpendParams,
  PostDailyRollupMultiQueryParams,
  ConversionPlanBundle,
  CostForecastRequestedJob,
  CostForecastRequest,
  CardinalityCheckResult,
  TenantForecastRefreshNeededJob,
  ForecastConfig,
  PostInvoiceIngestionIngestReducedBillingFilesParams,
  PostInvoiceIngestionIngestStagedFilesParams,
  ExportFilesProcessed,
  InvoiceSchemaInfo,
  GetInvoiceIngestionGetSchemaInfoParams,
  InvoiceTaggingRule,
  InvoiceAllocationRule,
  AllocationDimension,
  InvoiceProcessingReport,
  GetInvoiceRuleGetProcessingReportParams,
  PostInvoiceRuleProcessInvoiceDataParams,
  PostMonthlyRollupQueryParams,
  PostMonthlyRollupStreamParams,
  PostMonthlyRollupMultiQueryParams
} from './Invoices/model'
import { request } from '../Services/BasicApi'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postCspInvoiceDataDigestContollerQuery = (
    query: Query,
    params?: PostCspInvoiceDataDigestContollerQueryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/CspInvoiceDataDigestContoller/Query`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostCspInvoiceDataDigestContollerQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerQuery>>>
    export type PostCspInvoiceDataDigestContollerQueryMutationBody = Query
    export type PostCspInvoiceDataDigestContollerQueryMutationError = unknown

    export const usePostCspInvoiceDataDigestContollerQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerQuery>>, TError,{data: Query;params?: PostCspInvoiceDataDigestContollerQueryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerQuery>>, {data: Query;params?: PostCspInvoiceDataDigestContollerQueryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCspInvoiceDataDigestContollerQuery(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerQuery>>, TError, {data: Query;params?: PostCspInvoiceDataDigestContollerQueryParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCspInvoiceDataDigestContollerMultiQuery = (
    query: Query[],
    params?: PostCspInvoiceDataDigestContollerMultiQueryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult[]>(
      {url: `/CspInvoiceDataDigestContoller/MultiQuery`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostCspInvoiceDataDigestContollerMultiQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerMultiQuery>>>
    export type PostCspInvoiceDataDigestContollerMultiQueryMutationBody = Query[]
    export type PostCspInvoiceDataDigestContollerMultiQueryMutationError = unknown

    export const usePostCspInvoiceDataDigestContollerMultiQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerMultiQuery>>, TError,{data: Query[];params?: PostCspInvoiceDataDigestContollerMultiQueryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerMultiQuery>>, {data: Query[];params?: PostCspInvoiceDataDigestContollerMultiQueryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCspInvoiceDataDigestContollerMultiQuery(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerMultiQuery>>, TError, {data: Query[];params?: PostCspInvoiceDataDigestContollerMultiQueryParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCspInvoiceDataDigestContollerStream = (
    query: Query,
    params?: PostCspInvoiceDataDigestContollerStreamParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/CspInvoiceDataDigestContoller/Stream`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostCspInvoiceDataDigestContollerStreamMutationResult = NonNullable<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerStream>>>
    export type PostCspInvoiceDataDigestContollerStreamMutationBody = Query
    export type PostCspInvoiceDataDigestContollerStreamMutationError = unknown

    export const usePostCspInvoiceDataDigestContollerStream = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerStream>>, TError,{data: Query;params?: PostCspInvoiceDataDigestContollerStreamParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerStream>>, {data: Query;params?: PostCspInvoiceDataDigestContollerStreamParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postCspInvoiceDataDigestContollerStream(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCspInvoiceDataDigestContollerStream>>, TError, {data: Query;params?: PostCspInvoiceDataDigestContollerStreamParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postCurDelete = (
    params?: PostCurDeleteParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<DeleteCurSummary>(
      {url: `/Cur/Delete`, method: 'post',
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostCurDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof postCurDelete>>>
    
    export type PostCurDeleteMutationError = unknown

    export const usePostCurDelete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCurDelete>>, TError,{params?: PostCurDeleteParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCurDelete>>, {params?: PostCurDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  postCurDelete(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCurDelete>>, TError, {params?: PostCurDeleteParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postDailyRollupQuery = (
    query: Query,
    params?: PostDailyRollupQueryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/DailyRollup/Query`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostDailyRollupQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postDailyRollupQuery>>>
    export type PostDailyRollupQueryMutationBody = Query
    export type PostDailyRollupQueryMutationError = unknown

    export const usePostDailyRollupQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDailyRollupQuery>>, TError,{data: Query;params?: PostDailyRollupQueryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDailyRollupQuery>>, {data: Query;params?: PostDailyRollupQueryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postDailyRollupQuery(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postDailyRollupQuery>>, TError, {data: Query;params?: PostDailyRollupQueryParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postDailyRollupStream = (
    query: Query,
    params?: PostDailyRollupStreamParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/DailyRollup/Stream`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostDailyRollupStreamMutationResult = NonNullable<Awaited<ReturnType<typeof postDailyRollupStream>>>
    export type PostDailyRollupStreamMutationBody = Query
    export type PostDailyRollupStreamMutationError = unknown

    export const usePostDailyRollupStream = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDailyRollupStream>>, TError,{data: Query;params?: PostDailyRollupStreamParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDailyRollupStream>>, {data: Query;params?: PostDailyRollupStreamParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postDailyRollupStream(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postDailyRollupStream>>, TError, {data: Query;params?: PostDailyRollupStreamParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postDailyRollupQueryDailyResourceSpend = (
    query: Query,
    params?: PostDailyRollupQueryDailyResourceSpendParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/DailyRollup/QueryDailyResourceSpend`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostDailyRollupQueryDailyResourceSpendMutationResult = NonNullable<Awaited<ReturnType<typeof postDailyRollupQueryDailyResourceSpend>>>
    export type PostDailyRollupQueryDailyResourceSpendMutationBody = Query
    export type PostDailyRollupQueryDailyResourceSpendMutationError = unknown

    export const usePostDailyRollupQueryDailyResourceSpend = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDailyRollupQueryDailyResourceSpend>>, TError,{data: Query;params?: PostDailyRollupQueryDailyResourceSpendParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDailyRollupQueryDailyResourceSpend>>, {data: Query;params?: PostDailyRollupQueryDailyResourceSpendParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postDailyRollupQueryDailyResourceSpend(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postDailyRollupQueryDailyResourceSpend>>, TError, {data: Query;params?: PostDailyRollupQueryDailyResourceSpendParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postDailyRollupMultiQuery = (
    query: Query[],
    params?: PostDailyRollupMultiQueryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult[]>(
      {url: `/DailyRollup/MultiQuery`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostDailyRollupMultiQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postDailyRollupMultiQuery>>>
    export type PostDailyRollupMultiQueryMutationBody = Query[]
    export type PostDailyRollupMultiQueryMutationError = unknown

    export const usePostDailyRollupMultiQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDailyRollupMultiQuery>>, TError,{data: Query[];params?: PostDailyRollupMultiQueryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDailyRollupMultiQuery>>, {data: Query[];params?: PostDailyRollupMultiQueryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postDailyRollupMultiQuery(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postDailyRollupMultiQuery>>, TError, {data: Query[];params?: PostDailyRollupMultiQueryParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getFocusGetConversionInfo = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<ConversionPlanBundle>(
      {url: `/Focus/GetConversionInfo`, method: 'get', signal
    },
      { type: 'Invoices',  ...options});
    }
  

export const getGetFocusGetConversionInfoQueryKey = () => [`/Focus/GetConversionInfo`];

    
export type GetFocusGetConversionInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getFocusGetConversionInfo>>>
export type GetFocusGetConversionInfoQueryError = unknown

export const useGetFocusGetConversionInfo = <TData = Awaited<ReturnType<typeof getFocusGetConversionInfo>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFocusGetConversionInfo>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFocusGetConversionInfoQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFocusGetConversionInfo>>> = ({ signal }) => getFocusGetConversionInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getFocusGetConversionInfo>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postForecastQuery = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/Forecast/Query`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostForecastQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postForecastQuery>>>
    export type PostForecastQueryMutationBody = Query
    export type PostForecastQueryMutationError = unknown

    export const usePostForecastQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postForecastQuery>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postForecastQuery>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postForecastQuery(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postForecastQuery>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postForecastMultiQuery = (
    query: Query[],
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult[]>(
      {url: `/Forecast/MultiQuery`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostForecastMultiQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postForecastMultiQuery>>>
    export type PostForecastMultiQueryMutationBody = Query[]
    export type PostForecastMultiQueryMutationError = unknown

    export const usePostForecastMultiQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postForecastMultiQuery>>, TError,{data: Query[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postForecastMultiQuery>>, {data: Query[]}> = (props) => {
          const {data} = props ?? {};

          return  postForecastMultiQuery(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postForecastMultiQuery>>, TError, {data: Query[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const postForecastStreamForecast = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Forecast/StreamForecast`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostForecastStreamForecastMutationResult = NonNullable<Awaited<ReturnType<typeof postForecastStreamForecast>>>
    export type PostForecastStreamForecastMutationBody = Query
    export type PostForecastStreamForecastMutationError = unknown

    export const usePostForecastStreamForecast = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postForecastStreamForecast>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postForecastStreamForecast>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postForecastStreamForecast(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postForecastStreamForecast>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postForecastCreateCostForecast = (
    costForecastRequest: CostForecastRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<CostForecastRequestedJob>(
      {url: `/Forecast/CreateCostForecast`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: costForecastRequest
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostForecastCreateCostForecastMutationResult = NonNullable<Awaited<ReturnType<typeof postForecastCreateCostForecast>>>
    export type PostForecastCreateCostForecastMutationBody = CostForecastRequest
    export type PostForecastCreateCostForecastMutationError = unknown

    export const usePostForecastCreateCostForecast = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postForecastCreateCostForecast>>, TError,{data: CostForecastRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postForecastCreateCostForecast>>, {data: CostForecastRequest}> = (props) => {
          const {data} = props ?? {};

          return  postForecastCreateCostForecast(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postForecastCreateCostForecast>>, TError, {data: CostForecastRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const postForecastEstimateCardinality = (
    costForecastRequest: CostForecastRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<CardinalityCheckResult>(
      {url: `/Forecast/EstimateCardinality`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: costForecastRequest
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostForecastEstimateCardinalityMutationResult = NonNullable<Awaited<ReturnType<typeof postForecastEstimateCardinality>>>
    export type PostForecastEstimateCardinalityMutationBody = CostForecastRequest
    export type PostForecastEstimateCardinalityMutationError = unknown

    export const usePostForecastEstimateCardinality = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postForecastEstimateCardinality>>, TError,{data: CostForecastRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postForecastEstimateCardinality>>, {data: CostForecastRequest}> = (props) => {
          const {data} = props ?? {};

          return  postForecastEstimateCardinality(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postForecastEstimateCardinality>>, TError, {data: CostForecastRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const postForecastUpsertForecastConfig = (
    forecastConfig: ForecastConfig,
 options?: SecondParameter<typeof request>,
) => {
      return request<TenantForecastRefreshNeededJob>(
      {url: `/Forecast/UpsertForecastConfig`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: forecastConfig
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostForecastUpsertForecastConfigMutationResult = NonNullable<Awaited<ReturnType<typeof postForecastUpsertForecastConfig>>>
    export type PostForecastUpsertForecastConfigMutationBody = ForecastConfig
    export type PostForecastUpsertForecastConfigMutationError = unknown

    export const usePostForecastUpsertForecastConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postForecastUpsertForecastConfig>>, TError,{data: ForecastConfig}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postForecastUpsertForecastConfig>>, {data: ForecastConfig}> = (props) => {
          const {data} = props ?? {};

          return  postForecastUpsertForecastConfig(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postForecastUpsertForecastConfig>>, TError, {data: ForecastConfig}, TContext>(mutationFn, mutationOptions)
    }
    
export const getForecastGetForecastConfig = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<ForecastConfig>(
      {url: `/Forecast/GetForecastConfig`, method: 'get', signal
    },
      { type: 'Invoices',  ...options});
    }
  

export const getGetForecastGetForecastConfigQueryKey = () => [`/Forecast/GetForecastConfig`];

    
export type GetForecastGetForecastConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getForecastGetForecastConfig>>>
export type GetForecastGetForecastConfigQueryError = unknown

export const useGetForecastGetForecastConfig = <TData = Awaited<ReturnType<typeof getForecastGetForecastConfig>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getForecastGetForecastConfig>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetForecastGetForecastConfigQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getForecastGetForecastConfig>>> = ({ signal }) => getForecastGetForecastConfig(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getForecastGetForecastConfig>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postInvoiceIngestionIngestReducedBillingFiles = (
    params?: PostInvoiceIngestionIngestReducedBillingFilesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/InvoiceIngestion/IngestReducedBillingFiles`, method: 'post',
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostInvoiceIngestionIngestReducedBillingFilesMutationResult = NonNullable<Awaited<ReturnType<typeof postInvoiceIngestionIngestReducedBillingFiles>>>
    
    export type PostInvoiceIngestionIngestReducedBillingFilesMutationError = unknown

    export const usePostInvoiceIngestionIngestReducedBillingFiles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInvoiceIngestionIngestReducedBillingFiles>>, TError,{params?: PostInvoiceIngestionIngestReducedBillingFilesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInvoiceIngestionIngestReducedBillingFiles>>, {params?: PostInvoiceIngestionIngestReducedBillingFilesParams}> = (props) => {
          const {params} = props ?? {};

          return  postInvoiceIngestionIngestReducedBillingFiles(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postInvoiceIngestionIngestReducedBillingFiles>>, TError, {params?: PostInvoiceIngestionIngestReducedBillingFilesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postInvoiceIngestionIngestStagedFiles = (
    params?: PostInvoiceIngestionIngestStagedFilesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/InvoiceIngestion/IngestStagedFiles`, method: 'post',
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostInvoiceIngestionIngestStagedFilesMutationResult = NonNullable<Awaited<ReturnType<typeof postInvoiceIngestionIngestStagedFiles>>>
    
    export type PostInvoiceIngestionIngestStagedFilesMutationError = unknown

    export const usePostInvoiceIngestionIngestStagedFiles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInvoiceIngestionIngestStagedFiles>>, TError,{params?: PostInvoiceIngestionIngestStagedFilesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInvoiceIngestionIngestStagedFiles>>, {params?: PostInvoiceIngestionIngestStagedFilesParams}> = (props) => {
          const {params} = props ?? {};

          return  postInvoiceIngestionIngestStagedFiles(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postInvoiceIngestionIngestStagedFiles>>, TError, {params?: PostInvoiceIngestionIngestStagedFilesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postInvoiceIngestionForwardKafkaMessageForSynapse = (
    exportFilesProcessed: ExportFilesProcessed,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/InvoiceIngestion/ForwardKafkaMessageForSynapse`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: exportFilesProcessed
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostInvoiceIngestionForwardKafkaMessageForSynapseMutationResult = NonNullable<Awaited<ReturnType<typeof postInvoiceIngestionForwardKafkaMessageForSynapse>>>
    export type PostInvoiceIngestionForwardKafkaMessageForSynapseMutationBody = ExportFilesProcessed
    export type PostInvoiceIngestionForwardKafkaMessageForSynapseMutationError = unknown

    export const usePostInvoiceIngestionForwardKafkaMessageForSynapse = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInvoiceIngestionForwardKafkaMessageForSynapse>>, TError,{data: ExportFilesProcessed}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInvoiceIngestionForwardKafkaMessageForSynapse>>, {data: ExportFilesProcessed}> = (props) => {
          const {data} = props ?? {};

          return  postInvoiceIngestionForwardKafkaMessageForSynapse(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postInvoiceIngestionForwardKafkaMessageForSynapse>>, TError, {data: ExportFilesProcessed}, TContext>(mutationFn, mutationOptions)
    }
    
export const getInvoiceIngestionGetSchemaInfo = (
    params?: GetInvoiceIngestionGetSchemaInfoParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<InvoiceSchemaInfo>(
      {url: `/InvoiceIngestion/GetSchemaInfo`, method: 'get', signal,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  

export const getGetInvoiceIngestionGetSchemaInfoQueryKey = (params?: GetInvoiceIngestionGetSchemaInfoParams,) => [`/InvoiceIngestion/GetSchemaInfo`, ...(params ? [params]: [])];

    
export type GetInvoiceIngestionGetSchemaInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceIngestionGetSchemaInfo>>>
export type GetInvoiceIngestionGetSchemaInfoQueryError = unknown

export const useGetInvoiceIngestionGetSchemaInfo = <TData = Awaited<ReturnType<typeof getInvoiceIngestionGetSchemaInfo>>, TError = unknown>(
 params?: GetInvoiceIngestionGetSchemaInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceIngestionGetSchemaInfo>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInvoiceIngestionGetSchemaInfoQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceIngestionGetSchemaInfo>>> = ({ signal }) => getInvoiceIngestionGetSchemaInfo(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInvoiceIngestionGetSchemaInfo>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postInvoiceRuleSaveInvoiceTaggingRule = (
    invoiceTaggingRule: InvoiceTaggingRule,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/InvoiceRule/SaveInvoiceTaggingRule`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: invoiceTaggingRule
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostInvoiceRuleSaveInvoiceTaggingRuleMutationResult = NonNullable<Awaited<ReturnType<typeof postInvoiceRuleSaveInvoiceTaggingRule>>>
    export type PostInvoiceRuleSaveInvoiceTaggingRuleMutationBody = InvoiceTaggingRule
    export type PostInvoiceRuleSaveInvoiceTaggingRuleMutationError = unknown

    export const usePostInvoiceRuleSaveInvoiceTaggingRule = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInvoiceRuleSaveInvoiceTaggingRule>>, TError,{data: InvoiceTaggingRule}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInvoiceRuleSaveInvoiceTaggingRule>>, {data: InvoiceTaggingRule}> = (props) => {
          const {data} = props ?? {};

          return  postInvoiceRuleSaveInvoiceTaggingRule(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postInvoiceRuleSaveInvoiceTaggingRule>>, TError, {data: InvoiceTaggingRule}, TContext>(mutationFn, mutationOptions)
    }
    
export const postInvoiceRuleSaveInvoiceAllocationRule = (
    invoiceAllocationRule: InvoiceAllocationRule,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/InvoiceRule/SaveInvoiceAllocationRule`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: invoiceAllocationRule
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostInvoiceRuleSaveInvoiceAllocationRuleMutationResult = NonNullable<Awaited<ReturnType<typeof postInvoiceRuleSaveInvoiceAllocationRule>>>
    export type PostInvoiceRuleSaveInvoiceAllocationRuleMutationBody = InvoiceAllocationRule
    export type PostInvoiceRuleSaveInvoiceAllocationRuleMutationError = unknown

    export const usePostInvoiceRuleSaveInvoiceAllocationRule = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInvoiceRuleSaveInvoiceAllocationRule>>, TError,{data: InvoiceAllocationRule}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInvoiceRuleSaveInvoiceAllocationRule>>, {data: InvoiceAllocationRule}> = (props) => {
          const {data} = props ?? {};

          return  postInvoiceRuleSaveInvoiceAllocationRule(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postInvoiceRuleSaveInvoiceAllocationRule>>, TError, {data: InvoiceAllocationRule}, TContext>(mutationFn, mutationOptions)
    }
    
export const postInvoiceRuleSaveAllocationDimension = (
    allocationDimension: AllocationDimension,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/InvoiceRule/SaveAllocationDimension`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: allocationDimension
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostInvoiceRuleSaveAllocationDimensionMutationResult = NonNullable<Awaited<ReturnType<typeof postInvoiceRuleSaveAllocationDimension>>>
    export type PostInvoiceRuleSaveAllocationDimensionMutationBody = AllocationDimension
    export type PostInvoiceRuleSaveAllocationDimensionMutationError = unknown

    export const usePostInvoiceRuleSaveAllocationDimension = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInvoiceRuleSaveAllocationDimension>>, TError,{data: AllocationDimension}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInvoiceRuleSaveAllocationDimension>>, {data: AllocationDimension}> = (props) => {
          const {data} = props ?? {};

          return  postInvoiceRuleSaveAllocationDimension(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postInvoiceRuleSaveAllocationDimension>>, TError, {data: AllocationDimension}, TContext>(mutationFn, mutationOptions)
    }
    
export const getInvoiceRuleGetAllocationDimensions = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AllocationDimension[]>(
      {url: `/InvoiceRule/GetAllocationDimensions`, method: 'get', signal
    },
      { type: 'Invoices',  ...options});
    }
  

export const getGetInvoiceRuleGetAllocationDimensionsQueryKey = () => [`/InvoiceRule/GetAllocationDimensions`];

    
export type GetInvoiceRuleGetAllocationDimensionsQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceRuleGetAllocationDimensions>>>
export type GetInvoiceRuleGetAllocationDimensionsQueryError = unknown

export const useGetInvoiceRuleGetAllocationDimensions = <TData = Awaited<ReturnType<typeof getInvoiceRuleGetAllocationDimensions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceRuleGetAllocationDimensions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInvoiceRuleGetAllocationDimensionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceRuleGetAllocationDimensions>>> = ({ signal }) => getInvoiceRuleGetAllocationDimensions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInvoiceRuleGetAllocationDimensions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getInvoiceRuleGetInvoiceAllocationRules = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<InvoiceAllocationRule[]>(
      {url: `/InvoiceRule/GetInvoiceAllocationRules`, method: 'get', signal
    },
      { type: 'Invoices',  ...options});
    }
  

export const getGetInvoiceRuleGetInvoiceAllocationRulesQueryKey = () => [`/InvoiceRule/GetInvoiceAllocationRules`];

    
export type GetInvoiceRuleGetInvoiceAllocationRulesQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceAllocationRules>>>
export type GetInvoiceRuleGetInvoiceAllocationRulesQueryError = unknown

export const useGetInvoiceRuleGetInvoiceAllocationRules = <TData = Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceAllocationRules>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceAllocationRules>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInvoiceRuleGetInvoiceAllocationRulesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceAllocationRules>>> = ({ signal }) => getInvoiceRuleGetInvoiceAllocationRules(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceAllocationRules>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getInvoiceRuleGetInvoiceTaggingRules = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<InvoiceTaggingRule[]>(
      {url: `/InvoiceRule/GetInvoiceTaggingRules`, method: 'get', signal
    },
      { type: 'Invoices',  ...options});
    }
  

export const getGetInvoiceRuleGetInvoiceTaggingRulesQueryKey = () => [`/InvoiceRule/GetInvoiceTaggingRules`];

    
export type GetInvoiceRuleGetInvoiceTaggingRulesQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceTaggingRules>>>
export type GetInvoiceRuleGetInvoiceTaggingRulesQueryError = unknown

export const useGetInvoiceRuleGetInvoiceTaggingRules = <TData = Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceTaggingRules>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceTaggingRules>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInvoiceRuleGetInvoiceTaggingRulesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceTaggingRules>>> = ({ signal }) => getInvoiceRuleGetInvoiceTaggingRules(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInvoiceRuleGetInvoiceTaggingRules>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getInvoiceRuleGetProcessingReport = (
    params?: GetInvoiceRuleGetProcessingReportParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<InvoiceProcessingReport>(
      {url: `/InvoiceRule/GetProcessingReport`, method: 'get', signal,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  

export const getGetInvoiceRuleGetProcessingReportQueryKey = (params?: GetInvoiceRuleGetProcessingReportParams,) => [`/InvoiceRule/GetProcessingReport`, ...(params ? [params]: [])];

    
export type GetInvoiceRuleGetProcessingReportQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceRuleGetProcessingReport>>>
export type GetInvoiceRuleGetProcessingReportQueryError = unknown

export const useGetInvoiceRuleGetProcessingReport = <TData = Awaited<ReturnType<typeof getInvoiceRuleGetProcessingReport>>, TError = unknown>(
 params?: GetInvoiceRuleGetProcessingReportParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceRuleGetProcessingReport>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInvoiceRuleGetProcessingReportQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceRuleGetProcessingReport>>> = ({ signal }) => getInvoiceRuleGetProcessingReport(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInvoiceRuleGetProcessingReport>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postInvoiceRuleProcessInvoiceData = (
    params?: PostInvoiceRuleProcessInvoiceDataParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/InvoiceRule/ProcessInvoiceData`, method: 'post',
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostInvoiceRuleProcessInvoiceDataMutationResult = NonNullable<Awaited<ReturnType<typeof postInvoiceRuleProcessInvoiceData>>>
    
    export type PostInvoiceRuleProcessInvoiceDataMutationError = unknown

    export const usePostInvoiceRuleProcessInvoiceData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInvoiceRuleProcessInvoiceData>>, TError,{params?: PostInvoiceRuleProcessInvoiceDataParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInvoiceRuleProcessInvoiceData>>, {params?: PostInvoiceRuleProcessInvoiceDataParams}> = (props) => {
          const {params} = props ?? {};

          return  postInvoiceRuleProcessInvoiceData(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postInvoiceRuleProcessInvoiceData>>, TError, {params?: PostInvoiceRuleProcessInvoiceDataParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postMonthlyRollupQuery = (
    query: Query,
    params?: PostMonthlyRollupQueryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/MonthlyRollup/Query`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostMonthlyRollupQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postMonthlyRollupQuery>>>
    export type PostMonthlyRollupQueryMutationBody = Query
    export type PostMonthlyRollupQueryMutationError = unknown

    export const usePostMonthlyRollupQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMonthlyRollupQuery>>, TError,{data: Query;params?: PostMonthlyRollupQueryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMonthlyRollupQuery>>, {data: Query;params?: PostMonthlyRollupQueryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postMonthlyRollupQuery(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMonthlyRollupQuery>>, TError, {data: Query;params?: PostMonthlyRollupQueryParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postMonthlyRollupStream = (
    query: Query,
    params?: PostMonthlyRollupStreamParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/MonthlyRollup/Stream`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostMonthlyRollupStreamMutationResult = NonNullable<Awaited<ReturnType<typeof postMonthlyRollupStream>>>
    export type PostMonthlyRollupStreamMutationBody = Query
    export type PostMonthlyRollupStreamMutationError = unknown

    export const usePostMonthlyRollupStream = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMonthlyRollupStream>>, TError,{data: Query;params?: PostMonthlyRollupStreamParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMonthlyRollupStream>>, {data: Query;params?: PostMonthlyRollupStreamParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postMonthlyRollupStream(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMonthlyRollupStream>>, TError, {data: Query;params?: PostMonthlyRollupStreamParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postMonthlyRollupMultiQuery = (
    query: Query[],
    params?: PostMonthlyRollupMultiQueryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult[]>(
      {url: `/MonthlyRollup/MultiQuery`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Invoices',  ...options});
    }
  


    export type PostMonthlyRollupMultiQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postMonthlyRollupMultiQuery>>>
    export type PostMonthlyRollupMultiQueryMutationBody = Query[]
    export type PostMonthlyRollupMultiQueryMutationError = unknown

    export const usePostMonthlyRollupMultiQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMonthlyRollupMultiQuery>>, TError,{data: Query[];params?: PostMonthlyRollupMultiQueryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMonthlyRollupMultiQuery>>, {data: Query[];params?: PostMonthlyRollupMultiQueryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postMonthlyRollupMultiQuery(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMonthlyRollupMultiQuery>>, TError, {data: Query[];params?: PostMonthlyRollupMultiQueryParams}, TContext>(mutationFn, mutationOptions)
    }
    
