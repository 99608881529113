import { BulkTagOptions } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Card,
    Divider,
    Group,
    List,
    Loader,
    Popover,
    Space,
    Stack,
    Text,
    TextInput,
    useMantineTheme,
} from '@mantine/core';
import { useDi, useDiContainer } from '@root/Services/DI';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';
import { AlertTriangle, Check, ChevronDown, ChevronUp, Download, FileSpreadsheet, Tool, Trash } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { RuleEditCard } from './Design';
import { RuleEditor } from './Model';
import { useDropzone } from 'react-dropzone';
import { observable } from 'mobx';
import { FormatService } from '@root/Services/FormatService';
import { ColumnConfig, DataGridProps } from '@root/Components/DataGrid/Models';
import { DataGrid } from '@root/Components/DataGrid';
import { DataFileReader } from '@root/Services/Query/DataFileReader';
import { DataCompressionService } from '@root/Services/Query/DataCompressionService';
import { FieldPickerDropdown } from '@root/Components/Picker/FieldPicker';
import { FieldInfo, SchemaService } from '@root/Services/QueryExpr';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { Picker } from '@root/Components/Picker/Picker';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { EmptyDataText, VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { TooltipWhite } from '@root/Design/Primitives';
import { FillerSwitch } from '@root/Design/Filler';
import { useScrollBarDimensions } from '@root/Design/Layout';

type FileDetails = { originalSize: number; adjustments: string[]; fileModifiedDate: string; rows: number };
type DataWarningTypes = 'duplicate-match-field' | 'missing-match-field' | 'value-whitespace' | 'header-whitespace';
@injectable()
class BulkTagEditor {
    @observable
    public options: BulkTagOptions = {};
    public records: BulkDataRecord[] = [];
    public loading = new EventEmitter<boolean>(true);
    public schema = new SchemaService([]);
    @observable
    public dataWarnings: Set<DataWarningTypes> | null = null;
    public dataAdjusted = EventEmitter.empty();
    @observable
    public dataKey = 0;
    public updateErrors = () => {};

    public get resourceField() {
        return this.options.ResourceField ?? '';
    }

    public get fileField() {
        return this.options.FileField ?? '';
    }

    public get hasFile() {
        const fileDetails = this.options.PresentationData?.fileDetails as FileDetails;
        return !!fileDetails && !!fileDetails.originalSize;
    }

    public constructor(
        @inject(DataFileReader) private readonly fileReader: DataFileReader,
        @inject(DataCompressionService) private readonly compressionSvc: DataCompressionService,
        @inject(ResourceSchemaProvider) private readonly resourceSchemaSvc: ResourceSchemaProvider
    ) {}

    public init(ruleEditor: RuleEditor) {
        const rule = ruleEditor.rule!;

        try {
            rule.Parameters ??= {};
            rule.Parameters.Type ??= 'BulkTag';
            rule.Parameters.Syntax ??= {};
            rule.Parameters.Syntax.BulkTagOptions ??= {};
            rule.Parameters.Syntax.BulkTagOptions.PresentationData ??= {};
            rule.Parameters.Syntax.BulkTagOptions.PresentationData.fileDetails ??= {};
            this.options = rule.Parameters.Syntax.BulkTagOptions!;

            this.records = !this.options.CompressedBase64Data
                ? []
                : this.compressionSvc.decompressJson<string[][]>(this.options.CompressedBase64Data);

            this.loadSchema();

            this.updateDataWarnings();

            this.updateErrors = () => ruleEditor.setTypeErrors('BulkTag', this.getErrors());

            return this;
        } finally {
            this.loading.emit(false);
        }
    }

    private async loadSchema() {
        try {
            this.loading.emit(true);
            const types = await this.resourceSchemaSvc.getSchema();
            this.schema = new SchemaService(types);
            this.schema.resolveChildren();
        } finally {
            this.loading.emit(false);
        }
    }

    public setFileField = (value: string) => {
        this.options.FileField = value;
        this.updateDataWarnings();
        this.updateErrors();
    };

    public setResourceField = (value?: FieldInfo) => {
        this.options.ResourceField = value?.path;
        this.updateErrors();
    };

    public clearData = () => {
        this.updateOptions();
        this.records = [];
        this.updateErrors();
    };

    public getFileDetails() {
        return this.options.PresentationData?.fileDetails as FileDetails;
    }

    public getResourceField() {
        return this.schema.getField(this.options.ResourceField ?? '');
    }

    public getHeaders() {
        return this.options.Headers ?? [];
    }

    public getErrors() {
        const result: string[] = [];

        if ((this.options.Headers?.length ?? 0) <= 1) {
            result.push('The uploaded must have at least 2 columns');
        }
        if (!this.options.FileField) {
            result.push('No Pivot Column has been selected');
        }
        if (!this.options.ResourceField) {
            result.push('No Resource Field has been selected');
        }

        return result;
    }

    public async uploadData(file: File): Promise<{ error?: string }> {
        const { headers, data, error } = await this.fileReader.readFlatFile(file);

        if (!data || !headers) {
            return { error: error ?? 'No data found in file' };
        }
        if (error) {
            return { error };
        }
        if (headers.length <= 1) {
            return { error: 'File must have at least 2 columns' };
        }

        const records = data.map((row) => row.map((value) => (value instanceof Date ? value.toISOString() : value.toString())));
        this.records = records;

        const fileName = file.name;
        const fileDate = new Date().toISOString();

        const fileDetails = {
            originalSize: file.size,
            adjustments: [],
            fileModifiedDate: new Date(file.lastModified).toISOString(),
            rows: records.length,
        };
        this.updateOptions(headers, records, '', fileName, fileDate, fileDetails);
        this.updateDataWarnings();
        this.updateErrors();

        return {};
    }

    private updateOptions(
        headers?: string[],
        data?: string[][],
        fileField?: string,
        fileName?: string,
        fileDate?: string,
        fileDetails?: FileDetails
    ) {
        this.options.Headers = headers;
        this.options.FileField = fileField;
        this.options.FileName = fileName;
        this.options.FileDate = fileDate;
        this.options.PresentationData!['fileDetails'] = fileDetails as unknown;
        this.updateCompressedData(data ?? []);
    }

    private updateCompressedData(data: string[][]) {
        this.options.CompressedBase64Data = this.compressionSvc.compressJson(data);
        this.dataKey++;
    }

    // #region Data Warnings
    public fixDataIssue(issue: DataWarningTypes) {
        const fixHandler =
            issue === 'value-whitespace'
                ? this.fixValueWhitespace
                : issue === 'duplicate-match-field'
                ? this.removeDuplicates
                : issue === 'missing-match-field'
                ? this.removeMissingMatchField
                : issue === 'header-whitespace'
                ? this.fixHeaderWhitespace
                : null;

        if (fixHandler) {
            fixHandler();
            this.records = this.records.slice();

            this.updateDataWarnings();
            const fileDetails = this.getFileDetails();
            if (fileDetails) {
                if (!fileDetails.adjustments.includes(issue)) {
                    fileDetails.adjustments.push(issue);
                }
                fileDetails.rows = this.records.length;
            }

            this.updateCompressedData(this.records);
            this.dataAdjusted.emit();
        }
    }

    private updateDataWarnings() {
        const result = new Set<DataWarningTypes>();

        const wsPattern = /^\s+|\s+$/g;
        const headers = this.options.Headers ?? [];
        if (headers.some((h) => wsPattern.test(h))) {
            result.add('header-whitespace');
        }

        const matchFldValues = new Set<string>();
        for (const item of this.iterateData()) {
            if (wsPattern.test(item.value)) {
                result.add('value-whitespace');
            }
            if (item.isMatchFld) {
                if (matchFldValues.has(item.value)) {
                    result.add('duplicate-match-field');
                } else {
                    matchFldValues.add(item.value);
                }

                if (!item.value) {
                    result.add('missing-match-field');
                }
            }
        }

        this.dataWarnings = result;
    }

    private fixHeaderWhitespace = () => {
        const trimmedMatchField = (this.options.FileField ?? '').trim();
        this.options.Headers = this.options.Headers?.map((h) => h.trim());
        if (this.options.Headers?.includes(trimmedMatchField)) {
            this.options.FileField = trimmedMatchField;
        }
    };

    private fixValueWhitespace = () => {
        for (const { value, col, row } of this.iterateData()) {
            row[col] = value.trim();
        }
    };

    private removeDuplicates = () => {
        const matchFldValues = new Set<string>();
        const dupeRowIdxs = new Set<number>();
        for (const { value, rowIdx, isMatchFld } of this.iterateData()) {
            if (isMatchFld) {
                if (matchFldValues.has(value)) {
                    dupeRowIdxs.add(rowIdx);
                } else {
                    matchFldValues.add(value);
                }
            }
        }
        this.records = this.records.filter((_, idx) => !dupeRowIdxs.has(idx));
    };

    private removeMissingMatchField = () => {
        const matchField = this.options.FileField ?? '';
        const matchFieldIdx = this.options.Headers?.indexOf(matchField) ?? 0;
        this.records = this.records.filter((row) => !!row[matchFieldIdx]);
    };
    // #endregion

    private *iterateData() {
        const data = this.records;
        const matchField = this.options.FileField ?? '';
        const headers = this.options.Headers ?? [];

        const end = data.length;
        for (let i = 0; i < end; i++) {
            const row = data[i];
            for (let col = 0; col < row.length; col++) {
                const header = headers[col];
                const isMatchFld = header === matchField;
                yield { row, rowIdx: i, col, value: row[col], header, isMatchFld };
            }
        }
    }
}

interface BulkDataRecord extends Array<string> {}

type FileStatus = 'empty' | 'uploading' | 'uploaded' | 'error';
type FileState = { status: FileStatus; error?: string };
export const BulkTagCard = observer(function BulkTagCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    const di = useDiContainer();
    const typeEditor = useMemo(() => di.resolve(BulkTagEditor).init(ruleEditor), []);
    const loading = useEventValue(typeEditor.loading);
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const enableValidationError = useCallback(() => setShowValidationErrors(true), []);

    const { hasFile } = typeEditor;

    return (
        <>
            <RuleEditCard
                title={hasFile ? 'Validate File & Configure' : 'Upload a Data File'}
                titleDescriptionControl={<FileDetailButton editor={typeEditor} />}
                description={
                    hasFile
                        ? 'Select the column that maps to resource data and validate the file contents.'
                        : 'Upload a data file containing tags to apply to resources. This can be a simple CSV or Excel file. '
                }
                accent
                onBlur={enableValidationError}
            >
                <FillerSwitch loading={loading}>
                    {() => <>{!typeEditor.hasFile ? <FileUploader editor={typeEditor} /> : <UploadDataViewer editor={typeEditor} />}</>}
                </FillerSwitch>
            </RuleEditCard>
            <Space h="md" />
            <RuleEditCard
                title="Select Resource Field"
                description={`Choose the resource tag or metadata field with values that match "${
                    typeEditor?.fileField ?? 'the Pivot Column'
                }" values`}
            >
                <SettingsContainer style={{ width: 400 }}>
                    <FieldPickerDropdown
                        onChange={typeEditor.setResourceField}
                        schema={typeEditor.schema}
                        value={typeEditor.getResourceField()}
                        minimizeHeight
                        label="Resource Field"
                        placeholder="Select a field"
                        types={['string']}
                        width={450}
                        height={250}
                    />
                    <Text size="xs" color="dimmed">
                        Select resource field to match "{typeEditor.fileField}" values from the uploaded file
                    </Text>
                </SettingsContainer>
            </RuleEditCard>
        </>
    );
});

const FileUploader = observer(function FileUploader({ editor }: { editor: BulkTagEditor }) {
    const [state, setState] = useState<FileState>({ status: editor.records.length > 0 ? 'uploaded' : 'empty' });

    const theme = useMantineTheme();
    const updateState = useCallback((status: FileStatus, error?: string) => setState({ status, error }), []);

    const onDrop = useCallback(async (acceptedFile: File[]) => {
        updateState('uploading');
        var file = acceptedFile[0];
        if (file !== null && file !== undefined) {
            try {
                const { error } = await editor.uploadData(file);
                if (error) {
                    updateState('error', error);
                } else {
                    updateState('uploaded');
                }
            } catch (error) {
                console.error(error);
                updateState('error', 'Something unexpected happened');
            }
        } else {
            updateState('error', 'Please upload a valid Excel or CSV file');
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        maxFiles: 1,
    });

    const { status: fileStatus, error: fileError } = state;
    const uploaderState =
        fileStatus === 'uploading'
            ? 'loading'
            : fileStatus === 'error'
            ? 'error'
            : isDragAccept || isDragActive
            ? 'active'
            : isDragReject
            ? 'disabled'
            : 'empty';

    return (
        <Group noWrap align="stretch">
            <FileContainer {...getRootProps()} state={uploaderState}>
                <input {...getInputProps()} data-atid="BulkTagUploadInput" />
                {fileStatus === 'uploading' ? (
                    <Stack align="center">
                        <Loader size={40} color="primary.6" />
                        <Text size="sm">Extracting Data from File</Text>
                        <Text>Please Wait...</Text>
                    </Stack>
                ) : fileStatus === 'error' ? (
                    <Stack align="center">
                        <img src="/assets/cloud-upload-not.svg" style={{ width: '75px' }} />
                        <Text align="center" color="error">
                            {fileError}
                        </Text>
                        <Anchor color="error">Try Again</Anchor>
                    </Stack>
                ) : (
                    <Stack spacing={0} align="center">
                        {isDragReject ? (
                            <img src="/assets/cloud-upload-not.svg" style={{ width: '75px' }} />
                        ) : (
                            <img
                                src="/assets/cloud-upload.svg"
                                style={{
                                    width: '75px',
                                    filter: '',
                                }}
                            />
                        )}
                        <Text size="sm">Drop Excel or CSV file</Text>
                        <Text>
                            or <Anchor>Browse</Anchor>
                        </Text>
                    </Stack>
                )}
            </FileContainer>
            <Card radius="lg" sx={{ width: 350, background: theme.colors.primary[1], fontSize: theme.fontSizes.xs }}>
                <Text color="dimmed" size="xs">
                    Acceptable files:
                </Text>
                <Text size="xs" sx={{ display: 'flex', gap: 10 }}>
                    <span>Excel/CSV</span>
                    <span>10MB or less</span>
                    <span>10,000 rows or less</span>
                </Text>
                <Space h="sm" />
                <Text color="dimmed" size="xs">
                    Expected contents:
                </Text>
                <List pl={4} size="xs" listStyleType="none" sx={{ ['li:before']: { content: '"- "' } }}>
                    <List.Item>At least 2 columns with headers</List.Item>
                    <List.Item>1 column containing values that match resource tags or metadata</List.Item>
                    <List.Item>1 or more columns containing values to be used to tag resources</List.Item>
                    <List.Item>Clean column headers to be used as tag keys</List.Item>
                    <List.Item>If the file contains multiple worksheets, only the first is used</List.Item>
                </List>
            </Card>
        </Group>
    );
});

const UploadDataViewer = observer(function UploadDataViewer({ editor }: { editor: BulkTagEditor }) {
    const { colors, spacing } = useMantineTheme();

    useEvent(editor.dataAdjusted);

    const model = useMemo(() => {
        let grid: DataGridModel | null = null;
        return {
            setModel: (model: DataGridModel) => (grid = model),
            download: () => grid?.export(),
        };
    }, []);

    const columns = useMemo(() => {
        const fileField = editor.options.FileField;
        const headerInfo = editor
            .getHeaders()
            .map((h, idx) => ({ idx, header: h, isPivot: fileField === h, accessor: (r: BulkDataRecord) => r[idx] ?? '' }));

        return headerInfo.map(
            ({ header, idx, accessor, isPivot }) =>
                ({
                    accessor,
                    id: `col-${idx}`,
                    header,
                    headerRenderer: () => <VisibleSpaces value={header} />,
                    type: 'string',
                    align: 'left',
                    groupName: isPivot ? 'Pivot Column' : 'Tags to Apply',
                    defaultWidth: isPivot ? 180 : 120,
                    defaultFixed: isPivot,
                    noRemove: true,
                    exportOptions: {
                        renderer: accessor,
                        header: header,
                    },
                    cellRenderer: (r) => (!accessor(r) ? <EmptyDataText /> : <VisibleSpaces value={accessor(r)} />),
                } as ColumnConfig<BulkDataRecord>)
        );
    }, [JSON.stringify(editor.getHeaders()), editor.options.FileField, editor.dataKey]);

    const itemH = 30;
    const gridConfig = useMemo(
        () =>
            ({
                key: editor.dataKey + editor.fileField,
                columns,
                state: { sort: [], filters: [], columns: columns.map((c) => ({ id: c.id, width: c.defaultWidth, fixed: c.defaultFixed })) },
                groupConfig: !editor.fileField
                    ? undefined
                    : {
                          'Pivot Column': { color: colors.warning[4] + '80' },
                          'Tags to Apply': { color: colors.primary[4] + '80' },
                      },
                showHeaderGroups: editor.fileField ? true : false,
                defaultGroupName: 'Tags to Apply',
                dataSource: [],
                onModelLoaded: model.setModel,
                allowLoadAllPages: true,
                exportName: editor.options.FileName ?? 'Unnamed.xlsx',
                showCount: false,
                hideColumnSelector: true,
                hideHeader: true,
                hideFilter: true,
                hideMenu: true,
                itemHeight: itemH,
            } as DataGridProps<BulkDataRecord>),
        [columns]
    );

    const { y: scrollH } = useScrollBarDimensions();
    const rowsH = editor.records.length * itemH;
    const headerH = itemH * (editor.fileField ? 2 : 1);
    const requiredH = Math.max(150, Math.min(500, rowsH + headerH + scrollH));

    return (
        <>
            <FileDetailsHeader editor={editor} onDownload={model.download} />
            <Space h="md" />
            <Box mx={-spacing.xl} mb={-spacing.xl} sx={{ height: requiredH, ['[data-grid-component]']: { borderWidth: 0 } }}>
                <Divider />
                <DataGrid {...gridConfig} dataSource={editor.records} />
            </Box>
        </>
    );
});

function FileDetailsHeader(props: { editor: BulkTagEditor; onDownload: () => void }) {
    const fmtSvc = useDi(FormatService);
    const { editor, onDownload } = props;
    const { colors } = useMantineTheme();

    return (
        <Group pb={10} align="flex-end" position="apart">
            <Box mb={-10}>
                <FileFieldPicker onChange={editor.setFileField} options={editor.getHeaders()} value={editor.fileField} />
            </Box>
            <Group position="apart" align="stretch" spacing={6} sx={{ flex: 1 }}>
                <DataChecksButton editor={editor} />
                <Button leftIcon={<Download size={16} />} onClick={onDownload} variant="outline">
                    Download Data ({fmtSvc.formatInt0Dec(editor.records.length)} rows)
                </Button>
            </Group>
        </Group>
    );
}

function useDataWarningDescriptions() {
    return [
        {
            type: 'duplicate-match-field',
            label: 'Duplicate Pivot Values',
            descriptionSuffix: 'duplicate values found in the pivot column',
            behaviorWarning: `Tags will only be applied from the first row when there are duplicate pivot column cells.`,
            fix: 'Duplicates',
        },
        {
            type: 'missing-match-field',
            label: 'Missing Pivot Values',
            descriptionSuffix: 'pivot columns values are empty',
            behaviorWarning: `Tags from rows with empty cells in the pivot column will not be applied.`,
            fix: 'Empty-Pivot Rows',
        },
        {
            type: 'value-whitespace',
            label: 'Tag Value Whitespaces',
            descriptionSuffix: 'cells under "Tags to Apply" have leading or trailing whitespaces',
            behaviorWarning: `Resource tags would be applied with leading or trailing whitespaces in the tag value.`,
            fix: 'Cell Whitespaces',
        },
        {
            type: 'header-whitespace',
            label: 'Tag Key Whitespaces',
            descriptionSuffix: 'tag keys (headers under "Tags to Apply") have leading or trailing whitespaces',
            behaviorWarning: `Resource tags would be applied with leading or trailing whitespaces in the tag key.`,
            fix: 'Header Whitespaces',
        },
    ] as { type: DataWarningTypes; label: string; descriptionSuffix: string; behaviorWarning: string; fix: string }[];
}

const FileDetailButton = observer(function FileDetailButton({ editor }: { editor: BulkTagEditor }) {
    const { colors } = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const fileExt = editor.options.FileName?.split('.').pop() ?? '';
    const fileNameWoExt = editor.options.FileName?.replace(/\.\w+$/, '') ?? '';

    return (
        <>
            {!editor.hasFile ? null : (
                <Box sx={{ position: 'relative' }}>
                    <Group spacing={2} noWrap align="stretch" sx={{ position: 'absolute', right: 0 }}>
                        <TooltipWhite withinPortal position="left" p={0} label={<FileFullDetails editor={editor} />} radius="md" offset={0}>
                            <Card py={6} px={16} radius={3} sx={{ background: colors.gray[2], display: 'flex', alignItems: 'center' }}>
                                <FileSpreadsheet size={20} strokeWidth={1} stroke={colors.gray[7]} />
                                <Text
                                    ml={3}
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: 200,
                                        display: 'inline-block',
                                    }}
                                    size="sm"
                                    color="dimmed"
                                >
                                    {fileNameWoExt}
                                </Text>
                                <Text span size="sm" color="dimmed">
                                    .{fileExt}
                                </Text>
                            </Card>
                        </TooltipWhite>
                        <TooltipWhite offset={0} label="Close this file and upload a new file" withinPortal>
                            <Card
                                component="a"
                                py={3}
                                px={6}
                                radius={3}
                                sx={{
                                    background: colors.gray[2],
                                    cursor: 'pointer',
                                    color: colors.gray[9],
                                    '&:hover': { background: colors.warning[1], color: colors.warning[6] },
                                }}
                                onClick={editor.clearData}
                            >
                                <Trash size={24} strokeWidth={1} style={{ marginTop: 4 }} />
                            </Card>
                        </TooltipWhite>
                    </Group>
                </Box>
            )}
        </>
    );
});

function FileFullDetails({ editor }: { editor: BulkTagEditor }) {
    const { colors } = useMantineTheme();
    const warningDescriptions = useDataWarningDescriptions();
    const fmtSvc = useDi(FormatService);
    const fileDetails = editor.getFileDetails();
    const { FileName: fileName, FileDate: uploadedAt } = editor.options;
    const { adjustments, rows, originalSize, fileModifiedDate } = fileDetails ?? {};
    const adjusted = !!adjustments?.length;
    const adjDescs = adjustments?.map((adj) => warningDescriptions.find((d) => d.type === adj)) ?? [];

    return (
        <Box>
            <Box p="lg" sx={{ background: colors.gray[3] }}>
                <Text size="xs" my={-5} color="dimmed">
                    File Info
                </Text>
                <Text size="md">{fileName}</Text>
            </Box>
            <Divider />
            <Stack p="lg" spacing={4} sx={{ maxWidth: 350, whiteSpace: 'normal' }}>
                <Text size="sm">Uploaded At: {fmtSvc.toLocal(uploadedAt)}</Text>
                <Text hidden={!fileModifiedDate} size="sm">
                    {!fileModifiedDate ? null : `Last Modified At: ${fmtSvc.toLocal(fileModifiedDate)}`}
                </Text>
                <Text hidden={typeof originalSize !== 'number'} size="sm">
                    {typeof originalSize === 'number' ? `File Size: ${fmtSvc.formatBytes(originalSize)}` : null}
                </Text>
                <Text hidden={typeof rows !== 'number'} size="sm">
                    {adjusted ? 'Rows' : 'Original Rows'}: {fmtSvc.formatInt0Dec(rows ?? 0)}
                </Text>
                <Text size="sm">Adjustments: {!adjusted ? 'None' : ''}</Text>
                <List size="sm">
                    {adjDescs.map((adj, i) => (
                        <List.Item key={i}>Removed {adj?.label}</List.Item>
                    ))}
                </List>
            </Stack>
        </Box>
    );
}

function DataChecksButton({ editor }: { editor: BulkTagEditor }) {
    const { colors } = useMantineTheme();
    const [opened, { close, toggle }] = useToggle(false);
    const issueCt = editor.dataWarnings?.size ?? 0;
    const visible = !!editor.fileField;
    const icon = !visible ? (
        <></>
    ) : issueCt ? (
        <AlertTriangle size={18} color={colors.warning[6]} />
    ) : (
        <Check size={18} color={colors.success[6]} strokeWidth={2} />
    );
    return (
        <Popover radius="md" opened={opened} onClose={close} position="bottom" withArrow withinPortal shadow="lg" closeOnClickOutside offset={0}>
            <Popover.Target>
                <Button variant="outline" leftIcon={icon} onClick={toggle}>
                    {!visible ? 'Data Validation' : issueCt ? `Possible Issues (${issueCt})` : 'No Data Issues'}
                </Button>
            </Popover.Target>
            <Popover.Dropdown p={0}>
                <DataChecks editor={editor} />
            </Popover.Dropdown>
        </Popover>
    );
}

function DataChecks({ editor }: { editor: BulkTagEditor }) {
    const { colors } = useMantineTheme();
    const warningDescriptions = useDataWarningDescriptions();
    const warningDetails = warningDescriptions
        .map((d) => {
            const pass = !editor.dataWarnings?.has(d.type) ?? true;
            return {
                pass,
                icon: pass ? <Check color={colors.success[6]} strokeWidth={2} size={18} /> : <AlertTriangle color={colors.warning[6]} size={18} />,
                fgColor: pass ? undefined : colors.warning[7],
                bgColor: pass ? undefined : colors.warning[1],
                actionLbl: `Remove ${d.fix}`,
                action: () => editor.fixDataIssue(d.type),
                title: (pass ? 'No' : 'Found') + ' ' + d.label,
                description: `${pass ? 'No' : 'Some'} ${d.descriptionSuffix}.`,
                behavior: d.behaviorWarning,
            };
        })
        .sort((a, b) => (a.pass === b.pass ? 0 : a.pass ? 1 : -1));

    return (
        <Stack p="md">
            {warningDetails.map((d, idx) => (
                <Group noWrap key={idx} position="apart" align="center">
                    <Group>
                        {d.icon}
                        <Box>
                            <Text size="sm" sx={{ color: d.fgColor }} weight="bold">
                                {d.title}
                            </Text>
                            <Text size="xs" sx={{ color: d.fgColor }}>
                                {d.description}
                            </Text>
                            <Text hidden={d.pass} size="xs">
                                {d.behavior}
                            </Text>
                        </Box>
                    </Group>
                    {d.pass ? (
                        <span></span>
                    ) : (
                        <Button leftIcon={<Tool size={16} />} size="xs" variant="outline" color="warning" onClick={d.action}>
                            {d.actionLbl}
                        </Button>
                    )}
                </Group>
            ))}
        </Stack>
    );
}

function FileFieldPicker({ value, onChange, options }: { onChange: (value: string) => void; value: string; options: string[] }) {
    const [opened, { close, toggle }] = useToggle(false);
    const selections = useMemo(() => (value ? [{ value }] : []), [value]);
    const onSelected = useCallback(
        (selections: { value: string }[]) => {
            onChange(selections[0]?.value ?? '');
            close();
        },
        [onChange, close]
    );
    const pickerItems = useMemo(() => options.map((o) => ({ value: o })), [options]);

    return (
        <SettingsContainer>
            <Popover withinPortal withArrow opened={opened} offset={0} onClose={close} shadow="md" position="bottom">
                <Popover.Target>
                    <TextInput
                        value={value}
                        placeholder="Select a column"
                        onClick={toggle}
                        readOnly
                        description="Select a column with values that match resource tags or metadata"
                        inputWrapperOrder={['label', 'description', 'input', 'error']}
                        label="Pivot Column"
                        rightSection={
                            <ActionIcon
                                variant={opened ? 'default' : undefined}
                                color={opened ? 'primary' : undefined}
                                tabIndex={-1}
                                onClick={toggle}
                            >
                                {opened ? <ChevronUp strokeWidth={3} size={20} /> : <ChevronDown strokeWidth={1} size={18} />}
                            </ActionIcon>
                        }
                        sx={{ width: 350 }}
                    />
                </Popover.Target>
                <Popover.Dropdown p={0}>
                    <Picker
                        mode="single"
                        items={pickerItems}
                        nameAccessor="value"
                        onChange={onSelected}
                        selections={selections}
                        minimizeHeight
                        height={200}
                    />
                </Popover.Dropdown>
            </Popover>
        </SettingsContainer>
    );
}

const FileContainer = styled.div<{ state: 'loading' | 'error' | 'disabled' | 'active' | 'empty' }>`
    flex: 1;
    display: flex;
    flex-direction: columns;
    align-items: center;
    justify-content: center;
    padding: 20px;
    cursor: {p => p.state === 'inactive' ? 'not-allowed' : 'pointer'};
    opacity: ${(p) => (p.state === 'disabled' ? 0.6 : 1)};
    border-radius: ${(p) => p.theme.radius.lg}px;
    border: 2px dashed ${(p) =>
        p.state === 'error' ? p.theme.colors.error[3] : p.state === 'active' ? p.theme.colors.primary[5] : p.theme.colors.gray[3]};
    background-color: ${(p) =>
        p.state === 'error' ? p.theme.colors.error[1] : p.state === 'active' ? p.theme.colors.primary[3] : p.theme.colors.gray[1]};
    
    img {
        margin: 10px auto;
        display: flex;
        filter: ${(p) => getSvgFilter(p.state === 'error')}};
    }
`;

const getSvgFilter = (error?: boolean) => {
    return error
        ? 'invert(21%) sepia(44%) saturate(6355%) hue-rotate(354deg) brightness(73%) contrast(90%)'
        : 'invert(74%) sepia(48%) saturate(6723%) hue-rotate(162deg) brightness(86%) contrast(107%)';
};

const SettingsContainer = styled.div`
    border-radius: 8px;
    background: ${(p) => p.theme.colors.primary[0]};
    border: solid 1px ${(p) => p.theme.colors.primary[3]};
    padding: 10px 16px;
`;
