import { Company } from '@apis/Customers/model';
import { getMetadataIntegrationGetSchema } from '@apis/Resources';
import { SchemaType } from '@apis/Resources/model';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { inject, injectable, singleton } from 'tsyringe';
import { BaseCacheByTenant } from '../Customers/BaseCacheByTenant';
import { ICompanyContextToken } from '../Customers/CompanyContext';
import { FormatService } from '../FormatService';

@singleton()
class IntegrationSchemaCache extends BaseCacheByTenant<SchemaType[]> {
    public constructor(@inject(CompanyTenantPrereqService) tenantPrereqSvc: CompanyTenantPrereqService) {
        super(tenantPrereqSvc);
    }
}

@injectable()
export class IntegrationSchemaService {
    public constructor(
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(IntegrationSchemaCache) private readonly cache: IntegrationSchemaCache,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public async getIntegrationSchema() {
        return this.cache.get(this.company.Id ?? 0, async () => {
            const integrationSchemas = await getMetadataIntegrationGetSchema();
            this.adjustFields(integrationSchemas);
            return integrationSchemas;
        });
    }

    private adjustFields(types: SchemaType[]) {
        for (const type of types) {
            if (type.Fields) {
                for (const field of type.Fields) {
                    field.Field = field.Name;
                    field.Name = this.fmtSvc.userFriendlyCamelCase(field.Name ?? field.Field ?? '');
                }
            }
        }
    }
}
