import { observer } from 'mobx-react';
import { RuleEditor } from './Model';
import { StandardRuleLayout } from './StandardRuleLayout';
import { BulkTagCard } from './BulkTagCard';

export const BulkTagRuleCard = observer(function BulkTagRuleCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    return (
        <StandardRuleLayout ruleEditor={ruleEditor}>
            <BulkTagCard ruleEditor={ruleEditor} />
        </StandardRuleLayout>
    );
});
