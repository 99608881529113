import { Box, Group, LoadingOverlay, Space, Switch } from '@mantine/core';
import { observer, useObserver } from 'mobx-react';
import { RuleEditCard } from './Design';
import { RuleEditor } from './Model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Clearfix } from '@root/Design/Primitives';
import { FilterResources, ValidationIssues } from './FilterResources';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { TypeMapping } from '@apis/Resources/model';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { useEvent } from '@root/Services/EventEmitter';
import { injectable } from 'tsyringe';
import {
    HierarchyOptions,
    TagAutomationRule,
    TagAutomationRuleParametersType,
    TagRuleSyntax,
    TagAutomationRuleParameters,
} from '@apis/TagManager/model';
import { useFilterValidation } from './RuleDescriber';
import { QueryExpr, postResourceTagAutomationGetFilteredResourceTypes, postResourceTagAutomationGetInheritancePreviewCount } from '@apis/Resources';
import { makeAutoObservable } from 'mobx';

interface InheritanceState {
    inheritableTypes: TypeMapping[];
    inheritableTypesLoading: boolean;
}

@injectable()
export class InheritanceEditor {
    public options: HierarchyOptions = {};
    public syntax: TagRuleSyntax = {};
    public ruleEditor: RuleEditor = {} as RuleEditor;
    public rule?: TagAutomationRule = undefined;
    public constructor() {
        makeAutoObservable(this);
    }
    public init(ruleEditor: RuleEditor) {
        const rule = ruleEditor.rule!;
        rule.Parameters ??= {};
        rule.Parameters.Syntax ??= {};
        rule.Parameters.Syntax.HierarchyOptions ??= {};
        rule.Parameters.Syntax.HierarchyOptions.ChildResourceTypes ??= [];
        this.options = rule.Parameters.Syntax.HierarchyOptions;
        this.syntax = rule.Parameters.Syntax;
        this.ruleEditor = ruleEditor;
        this.rule = rule;
        return this;
    }

    public inheritanceState: InheritanceState = {
        inheritableTypes: [],
        inheritableTypesLoading: false,
    };

    public async loadFilteredResources() {
        if (!this.rule) {
            return;
        }
        this.inheritanceState.inheritableTypesLoading = true;
        const rule = this.ruleEditor.trimFilter(this.rule);
        await postResourceTagAutomationGetFilteredResourceTypes(rule)
            .then((response) => {
                this.inheritanceState.inheritableTypes = response.ChildTypes ?? [];
            })
            .catch((error) => {
                this.inheritanceState.inheritableTypes = [];
            })
            .finally(() => {
                this.inheritanceState.inheritableTypesLoading = false;
                this.ruleEditor.setTypeErrors(TagAutomationRuleParametersType.Inheritance, ['You must select at least one child resource type.']);
            });
    }

    public setChildResourceTypes(types: TypeMapping[]) {
        this.options.ChildResourceTypes = types;
    }

    public getErrors() {
        const result: string[] = [];
        return result;
    }
}

export const InheritanceActionCard = observer(function InheritanceActionCard({ inheritanceEditor }: { inheritanceEditor: InheritanceEditor }) {
    const [grid, setGrid] = useState<DataGridModel>();

    useEvent(grid?.selectionChanged, async () => {
        const selectedResourceTypes = (await grid?.selections.getSelected()) as TypeMapping[];
        if (selectedResourceTypes != null || selectedResourceTypes != undefined) {
            inheritanceEditor.setChildResourceTypes(selectedResourceTypes);
        }
    });

    const onChange = useCallback(() => {
        const issues: string[] = [];
        const options = inheritanceEditor.rule?.Parameters?.Syntax?.HierarchyOptions;
        if (!options?.ChildResourceTypes?.length) {
            issues.push('You must select at least one child resource type.');
        }
        inheritanceEditor.ruleEditor.setTypeErrors(TagAutomationRuleParametersType.Inheritance, issues);
    }, [inheritanceEditor.ruleEditor]);
    useObserver(onChange);

    useEffect(() => {
        (async () => {
            await inheritanceEditor.loadFilteredResources();
        })();
    }, []);

    const filterValidityChanged = useFilterValidation(inheritanceEditor.ruleEditor);

    const columnResult: ColumnConfig<TypeMapping>[] = [
        {
            accessor: (item) => item.ParentResourceType,
            id: 'ParentResourceType',
            sortField: 'ParentResourceType',
            header: 'Source Resource Type',
            defaultWidth: 250,
            defaultFixed: true,
            type: 'string',
        },
        {
            accessor: (item) => item.ChildResourceType,
            id: 'ChildResourceType',
            sortField: 'ChildResourceType',
            header: 'Attached Resource Type',
            defaultWidth: 250,
            defaultFixed: true,
            type: 'string',
        },
    ];
    return (
        <>
            <RuleEditCard
                accent
                title="Parent Resource Filter"
                description="Add filters to identify which parent resources have tags that should be inherited by their child resources"
            >
                {inheritanceEditor.rule ? (
                    <>
                        <InheritanceRuleResourceFilter typeEditor={inheritanceEditor} onValidationIssuesChanged={filterValidityChanged} />
                    </>
                ) : null}
            </RuleEditCard>
            <Space h="md" />
            {inheritanceEditor.inheritanceState.inheritableTypes.length > 0 ? (
                <>
                    {inheritanceEditor.inheritanceState.inheritableTypesLoading ? (
                        <LoadingOverlay visible />
                    ) : (
                        <>
                            <div style={{ width: '66%', float: 'left' }}>
                                {' '}
                                <RuleEditCard title="Select Attached Resource Types">
                                    <div style={{ height: 400 }}>
                                        <DataGrid
                                            dataSource={inheritanceEditor.inheritanceState.inheritableTypes}
                                            selectionMode={'multiple'}
                                            initialSelection={inheritanceEditor.inheritanceState.inheritableTypes.filter((x) => {
                                                return (
                                                    inheritanceEditor.options.ChildResourceTypes?.find(
                                                        (y) => y.ChildResourceType == x.ChildResourceType
                                                    ) != null
                                                );
                                            })}
                                            showHeaderGroups={true}
                                            columns={columnResult}
                                            hideMenu={true}
                                            showRefresh
                                            hideFilter={true}
                                            hideGlobalSearch={true}
                                            onModelLoaded={setGrid}
                                        />
                                    </div>
                                </RuleEditCard>
                            </div>
                            <div style={{ width: '32.3%', float: 'left', marginLeft: '1.7%' }}>
                                <RuleEditCard title="Additional Options">
                                    <Group>
                                        <Box>
                                            <Box pt="sm" sx={{ minHeight: '42px' }}>
                                                <Switch
                                                    label="Overwrite existing tags"
                                                    checked={inheritanceEditor.syntax.OverwriteValue ?? false}
                                                    onChange={(v) => (inheritanceEditor.syntax.OverwriteValue = v.target.checked)}
                                                />
                                            </Box>
                                        </Box>
                                    </Group>
                                </RuleEditCard>
                            </div>
                            <Clearfix></Clearfix>
                        </>
                    )}
                </>
            ) : null}
        </>
    );
});

//had to make a copy for the inheritance rule because the parent filter is in a different place on the object and we don't care about the main filter
export function InheritanceRuleResourceFilter({
    typeEditor,
    onValidationIssuesChanged,
}: {
    typeEditor: InheritanceEditor;
    onValidationIssuesChanged: (issues: ValidationIssues) => void;
}) {
    let rule: TagAutomationRule = typeEditor.rule!;
    const normalizeFilter = (rule: TagAutomationRule) => {
        let result: QueryExpr[];
        rule.Parameters ??= {};
        rule.Parameters.Syntax ??= {};
        rule.Parameters.Syntax.HierarchyOptions ??= {};
        rule.Parameters.Syntax.HierarchyOptions.ParentFilter ??= { Operation: 'and', Operands: (result = []) };
        if (!('Operation' in rule.Parameters.Syntax.HierarchyOptions.ParentFilter)) {
            rule.Parameters.Filter = { Opearation: 'and', Operands: (result = []) };
        } else {
            result = rule.Parameters.Syntax.HierarchyOptions.ParentFilter.Operands ??= [];
        }
        return result;
    };
    const filter = useMemo(() => normalizeFilter(rule), [rule, rule.Parameters?.Syntax?.HierarchyOptions?.ParentFilter]);
    const onChange = useCallback((nextFilter: QueryExpr[]) => {
        filter.splice(0, Infinity, ...nextFilter);
        (async () => {
            await typeEditor.loadFilteredResources();
        })();
    }, []);
    return <FilterResources filter={filter} onChange={onChange} onValidationIssuesChanged={onValidationIssuesChanged} />;
}
