import styled from '@emotion/styled';
import { useMantineTheme, Sx, Card, Group, Switch, Text } from '@mantine/core';
import { CSSProperties, forwardRef, ReactNode } from 'react';
import { ChevronDown, ChevronRight, ChevronUp, Icon, IconProps } from 'tabler-icons-react';
import { InfoIconTooltip } from './Primitives';

export function SwitchCard({ children, onClick }: { children: ReactNode; onClick: () => void }) {
    const theme = useMantineTheme();
    const cardSwitchStyle: Sx = { ['&:hover']: { background: theme.colors.gray[1], cursor: 'pointer' }, background: theme.colors.gray[2] };

    return (
        <Card py={4} px={12} withBorder radius="md" onClick={onClick} sx={cardSwitchStyle}>
            <Group noWrap>{children}</Group>
        </Card>
    );
}

export function FullSwitchCard({ onClick, ...switchProps }: ClickableLabeledSwitchProps) {
    return (
        <SwitchCard onClick={onClick}>
            <BaseLabeledSwitch {...switchProps} />
        </SwitchCard>
    );
}

interface ClickableLabeledSwitchProps extends LabeledSwitchProps {
    onClick: () => void;
}
interface LabeledSwitchProps {
    checked: boolean;
    alwaysOn?: boolean;
    label: ReactNode;
    tooltip?: ReactNode;
}
function BaseLabeledSwitch({ checked, alwaysOn, label, tooltip }: LabeledSwitchProps) {
    const theme = useMantineTheme();
    return (
        <>
            <Switch readOnly sx={{ input: !alwaysOn ? null : { background: theme.colors.primary[6] } }} checked={checked} />
            <Text size="sm">{label}</Text>
            {tooltip ? <InfoIconTooltip label={tooltip} /> : null}
        </>
    );
}

export function LabeledSwitch({ checked, alwaysOn, label, tooltip, onClick }: ClickableLabeledSwitchProps) {
    return (
        <Group noWrap onClick={onClick} sx={{ cursor: 'pointer' }}>
            <BaseLabeledSwitch checked={checked} alwaysOn={alwaysOn} label={label} tooltip={tooltip} />
        </Group>
    );
}

interface IExpanderChevProps {
    expanded?: boolean;
    orientation?: 'up-down' | 'right-down';
    durationSec?: number;
}
export function ExpanderChev(props: IExpanderChevProps & IconProps) {
    const { expanded, orientation = 'up-down', durationSec = 0.4, ...iconProps } = props;
    const ChevIcon = orientation === 'up-down' ? ChevronUp : ChevronRight;
    const expandedDegs = orientation === 'up-down' ? 180 : 90;
    const styles: CSSProperties = {
        transform: expanded ? `rotate(${expandedDegs}deg)` : 'rotate(0deg)',
        transition: `transform ${durationSec}s ease-in-out`,
        ...props.style,
    };
    return <ChevIcon style={styles} {...iconProps} />;
}
export function SectionExpander(props: IExpanderChevProps & IconProps) {
    return <ExpanderChev orientation="up-down" size={18} strokeWidth={2} {...props} />;
}
export function DataExpander(props: IExpanderChevProps & IconProps) {
    return <ExpanderChev orientation="right-down" size={16} strokeWidth={1} {...props} />;
}
