import { getCreditsGetCreditSummary } from '@apis/Customers';
import { Company } from '@apis/Customers/model';
import { postResourcesQuery, postResourcesTagResources, QueryResult } from '@apis/Resources';
import { ResourceIdentifier, TagResourcesJob } from '@apis/Resources/model';
import { getMapContractsGetMapContracts, postMapContractsGetMapResourceTypes } from '@apis/TagManager';
import { MapContract } from '@apis/TagManager/model';
import { BaseResource, QueryBasedResourceSelection } from '@root/Components/Resources/ResourcesGrid';
import { CompanyConfigService } from '@root/Services/Customers/CompanyConfigService';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { EventEmitter } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { inject, injectable } from 'tsyringe';
import { MapResourceQueryService } from '../../Services/MapResourceQueryService';

@injectable()
export class MapTagEditorModel {
    public contract!: MapContract;
    public mapQuerySvc!: MapResourceQueryService;
    public loading = new EventEmitter<boolean>(false);
    public selection?: QueryBasedResourceSelection;
    public creditsEnforced = false;
    public requireWorkload = false;

    public constructor(
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(NotificationService) public readonly notificationSvc: NotificationService,
        @inject(CompanyConfigService) private readonly companyConfigSvc: CompanyConfigService
    ) {}

    public init(contract: MapContract) {
        this.load(contract);
        return this;
    }

    private async load(contract: MapContract) {
        try {
            this.loading.emit(true);
            this.contract = contract;
            const mapResourceMeta = await postMapContractsGetMapResourceTypes();
            const allContracts = await getMapContractsGetMapContracts();
            const creditSummary = await getCreditsGetCreditSummary();
            this.creditsEnforced =
                creditSummary.CanUseCredits !== false && (creditSummary.PaymentType !== 'Postpaid' || creditSummary.CreditLimit !== 0);
            const companyConfig = await this.companyConfigSvc.getTypedCompanyConfig();
            this.requireWorkload = companyConfig.requireMapWorkload;
            const otherContracts = allContracts.filter((c) => c.Id !== this.contract?.Id);
            this.mapQuerySvc = new MapResourceQueryService(contract, mapResourceMeta, this.fmtSvc, otherContracts);
        } finally {
            this.loading.emit(false);
        }
    }

    public async applyTag(tag: string, workloadTag: string) {
        const request = await this.createTagRequest(tag, workloadTag);
        if (request) {
            await postResourcesTagResources(request);
            this.selection?.clearSelected();
        }
    }

    public async getSelectedResourceTypes() {
        const result: string[] = [];
        if (this.selection) {
            if (this.selection.allSelected()) {
                const query = queryBuilder<BaseResource>()
                    .select((b) => ({ type: b.model.ResourceType!, count: b.count() }))
                    .build();

                query.Where = this.selection.getResourceQuery();
                const response = (await postResourcesQuery(query)) as QueryResult<{ type: string }>;
                const results = response.Results?.map((r) => r.type) ?? [];
                result.push(...results);
            } else {
                result.push(...this.selection.getIncluded().map((r) => r.ResourceType));
            }
        }
        return result;
    }

    public async createTagRequest(tag: string, workloadTag: string) {
        const selection = this.selection;
        if (!selection) return null;
        const allResources = selection.allSelected();
        const includedResourceIds = (await selection.getSelected()).map(
            (r) => ({ CloudPlatform: 'Aws', ResourceId: r.Id, ResourceType: r.ResourceType } as ResourceIdentifier)
        );
        var deleteTags = [{ Key: 'map-migrated' }];
        if (workloadTag != '') {
            deleteTags.push({ Key: 'map-workload' });
        }
        var addTags = [{ Key: 'map-migrated', Value: tag }];
        if (workloadTag != '') {
            addTags.push({ Key: 'map-workload', Value: workloadTag });
        }
        return {
            CompanyId: this.company.Id!,
            AllResources: allResources,
            ExcludedResourceIds: allResources ? selection.getExcluded() : null,
            ResourceIds: !allResources ? includedResourceIds : [],
            Filter: allResources ? selection.getResourceQuery() : null,
            DeleteTags: tag === 'Delete' ? deleteTags : null,
            AddTags: tag !== 'Delete' ? addTags : null,
            OverwriteConflicts: true,
        } as TagResourcesJob;
    }
}
