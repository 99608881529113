import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';

export class BaseCacheByTenant<T> {
    private tenantCache: Map<number, Promise<any>> = new Map<number, Promise<any>>();
    protected resolvedResults: Map<number, T> = new Map<number, T>();

    public constructor(protected tenantPrereqSvc: CompanyTenantPrereqService) {
        this.tenantPrereqSvc.handleCacheInvalidate((companyId) => this.invalidate(companyId));
    }

    public invalidate(tenantId: number) {
        this.tenantCache.delete(tenantId);
        this.resolvedResults.delete(tenantId);
    }

    protected invalidateAll() {
        this.tenantCache.clear();
        this.resolvedResults.clear();
    }

    public get(tenantId: number, callback: () => Promise<T>) {
        if (!this.tenantCache.has(tenantId)) {
            this.tenantCache.set(
                tenantId,
                this.tenantPrereqSvc!.withCompanyId(tenantId, async () => {
                    const result = await callback();
                    this.resolvedResults.set(tenantId, result);
                    return result;
                })
            );
        }
        return this.tenantCache.get(tenantId)! as Promise<T>;
    }
}
