import {
    AllocationDimension,
    InvoiceAllocationRule,
    InvoiceAllocationRuleRuleType,
    InvoiceAllocationRuleStatus,
    InvoiceTaggingRule,
    FundSourceDefinition,
    DisbursementDefinition,
} from '@apis/Invoices/model';
import { EventEmitter } from '@root/Services/EventEmitter';
import { InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { SchemaService } from '@root/Services/QueryExpr';
import { makeAutoObservable } from 'mobx';
import { inject, Lifecycle, scoped } from 'tsyringe';

@scoped(Lifecycle.ContainerScoped)
export class AllocationRuleEditorContext {
    public loading = new EventEmitter(true);
    public ruleEditor: AllocationRuleEditor = undefined!;
    public month: Date = new Date();
    private loadKey = '';
    public constructor(
        @inject(ShowbackPersistence) private readonly showbackPersistence: ShowbackPersistence,
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService
    ) {}

    public tryInit(month: Date, dimId: number, ruleType?: string, ruleId?: string) {
        const nextLoadKey = `${month}-${dimId}-${ruleType ?? ''}-${ruleId ?? ''}`;
        if (this.loadKey !== nextLoadKey) {
            this.loadKey = nextLoadKey;
            this.ruleEditor = undefined!;
            this.month = month;
            this.load(dimId, ruleId, ruleType);
        }
        return this;
    }

    public async load(dimId: number, ruleId?: string, ruleType?: string) {
        this.loading.emit(true);
        const startLoadKey = this.loadKey;
        try {
            const [allocDim, schemaTypes] = await Promise.all([
                this.showbackPersistence.getAllocationDimension(dimId),
                this.invoiceSchemaSvc.getDailySchema(),
            ]);
            if (allocDim) {
                const schemaSvc = new SchemaService(schemaTypes);
                const id = parseInt(ruleId ?? '');
                const ruleEditor = new AllocationRuleEditor(allocDim, this.month, this.showbackPersistence, schemaSvc);
                await ruleEditor.init(isNaN(id) ? undefined : id, ruleType as InvoiceAllocationRuleRuleType | undefined);
                if (startLoadKey === this.loadKey) {
                    this.ruleEditor = ruleEditor;
                }
            }
        } finally {
            if (startLoadKey === this.loadKey) {
                this.loading.emit(false);
            }
        }
    }
}

export class AllocationRuleEditor {
    public rule?: InvoiceAllocationRule | InvoiceTaggingRule = undefined;
    public loading = true;
    public saving = false;
    public originalStatus?: InvoiceAllocationRuleStatus = undefined;
    private originalParams?: string = '';
    public ruleTypeValidation: Partial<Record<InvoiceAllocationRuleRuleType, string[]>> = {};
    public filterValidation: string[] = [];
    public rulePropsChanged = EventEmitter.empty();

    public constructor(
        public readonly allocDim: AllocationDimension,
        public readonly month: Date,
        public readonly showbackSvc: ShowbackPersistence,
        public readonly schemaSvc: SchemaService
    ) {
        makeAutoObservable(this, { schemaSvc: false, showbackSvc: false, allocDim: false });
    }

    public async init(ruleId?: number, ruleType?: InvoiceAllocationRuleRuleType) {
        if (typeof ruleId === 'number') {
            this.rule = await this.loadRule(ruleId, ruleType);
            this.originalStatus = this.rule?.Status;
        } else {
            this.rule = this.createRule(ruleType);
            if (this.rule.RuleType === 'UntaggedCosts') {
                (this.rule as InvoiceTaggingRule).TargetFilters = {};
            }
            this.loading = false;
        }
    }

    private async loadRule(ruleId: number, ruleType?: InvoiceAllocationRuleRuleType) {
        this.loading = true;
        try {
            return this.showbackSvc.getInvoiceRule(ruleId, ruleType);
        } finally {
            this.loading = false;
        }
    }

    private createRule(ruleType: InvoiceAllocationRuleRuleType | undefined) {
        return ruleType === 'UntaggedCosts'
            ? this.createTaggingRule()
            : ({
                  Name: '',
                  RuleDescription: '',
                  IsDeleted: false,
                  RuleType: ruleType,
                  Status: InvoiceAllocationRuleStatus.Draft,
                  AllocationDimensionId: this.allocDim.Id,
              } as InvoiceAllocationRule);
    }

    private createTaggingRule() {
        const { xb } = this.showbackSvc.getMetricExprBuilder();

        return {
            Name: '',
            RuleDescription: '',
            IsDeleted: false,
            RuleType: 'UntaggedCosts',
            Status: InvoiceAllocationRuleStatus.Draft,
            AllocationDimensionId: this.allocDim.Id,
            SelectedMonth: this.month.toISOString(),
            TargetFilters: {
                InclusionRules: [{ Name: 'custom_filters', Filter: { Operation: 'and', Operands: [] } }],
                ExclusionRules: [{ Name: 'default_exclusion_negative_usage', Filter: xb.resolve(xb.model['lineItem/LineItemType']!.ne('Usage')) }],
            },
            CreatedBy: 0,
            Order: 0,
            CreatedAt: new Date().toISOString(),
        } as InvoiceTaggingRule;
    }

    public setTypeErrors(type: InvoiceAllocationRuleRuleType, errors: string[]) {
        this.ruleTypeValidation[type] = errors;
    }

    public getRuleTypeIssues() {
        const type = this.rule?.RuleType;
        const ruleIssues = type ? this.ruleTypeValidation[type] : null;
        return ruleIssues;
    }

    public async save() {
        this.saving = true;
        try {
            if (!this.rule) {
                return false;
            }
            await this.showbackSvc.saveInvoiceRule(this.rule);
            return true;
        } catch {
            return false;
        } finally {
            this.saving = false;
        }
    }

    public setRuleName(name: string) {
        this.rule!.Name = name;
    }

    public setRuleDescription(description: string) {
        this.rule!.RuleDescription = description;
    }

    public setRuleStatus = (status: InvoiceAllocationRuleStatus) => {
        this.rule!.Status = status;
    };

    public canSave() {
        const hasName = !!this.rule?.Name;
        const type = this.rule?.RuleType;
        const status = this.rule?.Status;
        const hasType = type;
        const ruleIssues = type ? this.ruleTypeValidation[type]?.length! > 0 : false;
        const filterIssues = this.filterValidation.length > 0;
        const hasFundSource = this.checkFundSource();
        return !this.saving && hasName && hasType && hasFundSource && (status === 'Draft' || (!ruleIssues && !filterIssues));
    }

    public checkFundSource() {
        const rule = this.rule as InvoiceAllocationRule;
        if (rule.FundSource === undefined || (rule.FundSource && rule.FundSource?.Sources!.length > 0)) {
            return true;
        }
        return false;
    }

    public setFundSource(fundSource?: FundSourceDefinition) {
        const rule = this.rule as InvoiceAllocationRule;
        rule.FundSource = fundSource;
        this.rule = rule;
    }

    public setDisbursement(disbursement?: DisbursementDefinition) {
        const rule = this.rule as InvoiceAllocationRule;
        rule.Disbursement = disbursement;
        this.rule = rule;
    }

    public getDisbursement() {
        const rule = this.rule as InvoiceAllocationRule;
        return (rule.Disbursement ??= {});
    }

    public getSingleTarget() {
        const disbDef = this.getDisbursement();
        if (!disbDef?.Targets?.length) {
            disbDef.Targets = [
                {
                    TargetType: 'Existing',
                    DisbursementMethod: 'SplitByUsage',
                },
            ];
        }
        return disbDef?.Targets[0];
    }

    public async getFundSourcePreview() {
        const rule = this.rule as InvoiceAllocationRule;
        return await this.showbackSvc.getSourcePreviewData(rule, this.month);
    }

    public async getDisbursmentTargetPreview() {
        const rule = this.rule as InvoiceAllocationRule;
        return await this.showbackSvc.getDisbursmentPreviewData(rule, this.month);
    }

    public async getSourceAndTargetTotals() {
        const rule = this.rule as InvoiceAllocationRule;
        return await this.showbackSvc.getSourceAndTargetTotalPreviewData(rule, this.month);
    }

    public async getUntaggedTargetsPreview() {
        const rule = this.rule as InvoiceTaggingRule;
        return await this.showbackSvc.getUntaggedPreviewData(rule, this.month, this.allocDim);
    }

    public getUntaggedTargetsPreviewQuery() {
        const rule = this.rule as InvoiceTaggingRule;
        return this.showbackSvc.getUntaggedPreviewQuery(rule, this.allocDim);
    }

    public getDimensionKey() {
        return this.showbackSvc.getDimensionInvoiceField(this.allocDim);
    }
    public getDimensionName() {
        return this.showbackSvc.getDimensionName(this.allocDim);
    }
}
