import { TagAutomationRuleParametersType } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import { Center, Text, UnstyledButton } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useCallback } from 'react';

const typeDescriptorLookup: Record<TagAutomationRuleParametersType, { image: string; ufName: string; maxH?: string; maxW?: string }> = {
    AutoTag: { image: 'auto-tagging', ufName: 'Auto-Tag', maxW: '90px' },
    AwsOUAccountGroup: { image: 'resourcegroup', ufName: 'AWS Org Entity' },
    BulkTag: { image: 'tag-import', ufName: 'Tag Import', maxH: '70px' },
    Inheritance: { image: 'tag-inheritance', ufName: 'Tag Inheritance' },
    MapCredits: { image: 'mapcredit', ufName: 'MAP Credit' },
    Replacement: { image: 'tag-replacement', ufName: 'Tag Replacement' },
    ResourceGroup: { image: 'group-inheritance', ufName: 'Group Inheritance', maxH: '80px' },
    SyntaxCorrection: { image: 'syntax-correction', ufName: 'Syntax Correction' },
};

export function RuleTypeCard({
    ruleType,
    disabled,
    selected,
    onClick,
}: {
    ruleType: TagAutomationRuleParametersType;
    disabled: boolean;
    selected: boolean;
    onClick: (type: TagAutomationRuleParametersType) => void;
}) {
    const { hovered, ref } = useHover<HTMLButtonElement>();
    const { maxW, maxH, image, ufName } = typeDescriptorLookup[ruleType];
    const state = selected ? 'selected' : disabled ? 'disabled' : hovered ? 'hover' : undefined;
    const select = useCallback(() => onClick(ruleType), [ruleType]);
    const src = `/assets/compliance/tag-automation/rule-types/${image}.svg`;

    return (
        <UnstyledButton ref={ref} component={RuleTypeButton} onClick={select} state={state}>
            <Text size={14} weight="bold" align="center">
                {ufName}
            </Text>
            <Center sx={{ width: '100%', flex: 1, maxHeight: 90 }}>
                <img src={src} alt={ufName} style={{ maxWidth: maxW, maxHeight: maxH }} />
            </Center>
        </UnstyledButton>
    );
}

const RuleTypeButton = styled.button<{ state?: 'selected' | 'disabled' | 'hover' }>`
    box-shadow: 0 3px 8px #0003;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px 0 4px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    filter: ${(p) => (p.state === 'disabled' ? 'grayscale(100%)' : 'none')};
    opacity: ${(p) => (p.state === 'disabled' ? 0.5 : 1)};
    cursor: ${(p) => (p.state === 'disabled' ? 'not-allowed' : 'pointer')};
    color: ${(p) => (p.state !== 'selected' ? p.theme.colors.primary[6] : p.theme.colors.gray[0])};
    background: ${(p) =>
        p.state === 'disabled'
            ? p.theme.colors.gray[1]
            : p.state === 'hover'
            ? p.theme.colors.primary[1]
            : p.state === 'selected'
            ? p.theme.colors.primary[6]
            : p.theme.colors.gray[0]};
    border: solid 2px #0000;
    border-radius: 5px;
    border-color: ${(p) => (p.state === 'hover' || p.state === 'selected' ? p.theme.colors.primary[6] : '#0000')};
    :before {
        content: '';
        display: ${(p) => (p.state === 'selected' ? 'block' : 'none')};
        position: absolute;
        bottom: -15px;
        width: 28px;
        height: 14px;
        border-width: 14px 14px 0 14px;
        border-color: ${(p) => p.theme.colors.primary[6]} transparent transparent transparent;
        border-style: solid;
    }
    img {
        max-width: 80%;
        max-height: 60px;
        flex: 1;
        filter: ${(p) => (p.state === 'selected' ? 'hue-rotate(10deg) brightness(1) contrast(1.1) saturate(0.8)' : 'none')};
    }
`;
