import { CellValue, Workbook } from 'exceljs';
import { injectable, inject } from 'tsyringe';
import { FormatService } from '../FormatService';
import {} from 'pako';
import Papa from 'papaparse';

interface FlatFileResult {
    headers?: string[];
    data?: (number | Date | string | boolean)[][];
    error?: string;
}

@injectable()
export class DataFileReader {
    public constructor(@inject(FormatService) private readonly fmtSvc: FormatService) {}

    public async readFlatFile(file: File, maxRows: number = 10000, maxSize: number = Math.pow(1024, 2) * 10): Promise<FlatFileResult> {
        if (maxSize && file.size > maxSize) {
            return { error: `File size exceeds the maximum allowed size of ${this.fmtSvc.formatBytes(maxSize)}` };
        }
        const fileExt = this.getFileExt(file);
        if (!fileExt) {
            return { error: 'Invalid file type' };
        }
        if (!['xlsx', 'csv'].includes(fileExt)) {
            return { error: 'Invalid file type. Only Excel and CSV files are supported' };
        }

        const handler = fileExt === 'xlsx' ? this.readExcel : this.readCsv;

        return await handler.call(this, file, maxRows);
    }

    // #region Excel
    private async readExcel(file: File, maxRows: number): Promise<FlatFileResult> {
        try {
            const wb = await new Workbook().xlsx.load(await file.arrayBuffer());
            const ws = wb.worksheets?.[0];
            if (!ws) {
                return { error: 'No worksheets found in Excel file. ' };
            }
            if (ws.rowCount > maxRows) {
                return { error: `Number of rows exceeds the maximum allowed rows of ${this.fmtSvc.formatInt0Dec(maxRows)}` };
            }

            const headers: string[] = [];
            ws.getRow(1).eachCell((cell) => {
                headers.push(this.getPrimitiveCellValue(cell.value).toString() ?? '');
            });

            const data: (number | Date | string | boolean)[][] = [];
            ws.eachRow((row, rowIndex) => {
                if (rowIndex === 1) {
                    return;
                }
                data.push(headers.map((_, i) => this.getPrimitiveCellValue(row.getCell(i + 1))));
            });

            return { headers, data };
        } catch (e) {
            return { error: 'Failed to read Excel file' };
        }
    }

    private getPrimitiveCellValue(cell: CellValue | undefined) {
        if (!cell) {
            return '';
        }

        cell =
            typeof cell !== 'object' || cell instanceof Date
                ? cell
                : 'text' in cell
                ? cell.text
                : 'result' in cell
                ? cell.result
                : 'richText' in cell
                ? cell.richText.map((rt) => rt.text).join(' ')
                : 'formula' in cell
                ? cell.result ?? ''
                : 'sharedFormula' in cell
                ? cell.result ?? ''
                : cell;

        if (cell === undefined || (typeof cell === 'object' && 'error' in cell)) {
            return '';
        }

        return cell;
    }
    // #endregion

    // #region CSV
    private async readCsv(file: File, maxRows: number): Promise<FlatFileResult> {
        return new Promise((resolve) => {
            Papa.parse<string[]>(file, {
                complete: (result) => {
                    if (result.errors.length) {
                        resolve({ error: `Errors occurred while parsing the CSV` });
                        return;
                    }
                    if (result.data.length > maxRows) {
                        resolve({ error: `Number of rows exceeds the maximum allowed rows of ${this.fmtSvc.formatInt0Dec(maxRows)}` });
                        return;
                    }
                    if (result.data.length === 0) {
                        resolve({ error: 'No data found in CSV file' });
                        return;
                    }
                    const headers = result.data[0];
                    const data = [];
                    for (let i = 1; i < result.data.length; i++) {
                        data.push(result.data[i]);
                    }

                    resolve({ headers, data });
                },
            });
        });
    }
    // #endregion

    public getFileExt(file: File) {
        return file.name.split('.')?.pop()?.toLowerCase();
    }
}
