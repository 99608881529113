import { AnonymousQueueJobJobStatus } from '@apis/Jobs/model';
import { ResourceIdentifier, TagAutomationRule } from '@apis/Resources/model';
import { postTagAutomationQueryTraceByRule } from '@apis/TagManager';
import { TagAutomationTraceByRuleMode, TagAutomationTraceByRuleStatus } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Box,
    Button,
    Card,
    CloseButton,
    Collapse,
    Divider,
    Drawer,
    Group,
    Loader,
    LoadingOverlay,
    ScrollArea,
    Space,
    Stack,
    Text,
    ThemeIcon,
    Title,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { LineChart } from '@root/Components/Charts/LineChart';
import { PageContent, PagePanel, PanelBody, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { ToolTip, TooltipWhite } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { useNav } from '@root/Services/NavigationService';
import { IFluentOperators, queryBuilder, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { MapContractListContext } from '@root/Site/MapManager/Management/AutoTag/MapAutoTag';
import { MapAutoTagModel } from '@root/Site/MapManager/Management/AutoTag/MapAutoTagModel';
import { MapAutoTagRule } from '@root/Site/MapManager/Management/AutoTag/MapAutoTagRule';
import { MapContractHomeModel } from '@root/Site/MapManager/Management/Models';
import { addDays, parseISO } from 'date-fns';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronRight, ChevronUp, Refresh } from 'tabler-icons-react';
import { singleton } from 'tsyringe';
import { ActivityGrid } from './Components/ActivityGrid';
import { TagAutomationRuleActivityModel } from './Components/Model';
import { RuleDescription } from './Components/RuleDescriber';
import { RuleTraceViewer } from './Components/RuleTraceViewer';
import { TagAutomationDateRangeFilter } from './Components/TagAutoDateRangeFilter';
import { ChartWrapper } from '@root/Components/Charts/Design';
import { ResourceDetailsOpener } from '@root/Components/Resources/ResourceDetails';

@singleton()
export class MapContractEditContext {
    public editorOpen = new EventEmitter(false);
}

export function TagAutomationRuleActivity() {
    const theme = useMantineTheme();
    const model = useDi(TagAutomationRuleActivityModel);
    const fmtSvc = useDi(FormatService);
    const { id, ruleId, from, to } = useNav().getData('id', 'ruleId', 'from', 'to');
    const container = useDiContainer();
    useEffect(() => {
        model.load(ruleId);
    }, []);
    const [{ ResourceId, CloudPlatform, ResourceType }, setResourceId] = useState<ResourceIdentifier>({});
    const nav = useNav();
    const initialValue = useMemo(() => (!to || !from ? undefined : { to: parseISO(to), from: parseISO(from) }), []);
    const [dateFilter, setDateFilter] = useState<{ from?: Date; to?: Date }>();
    const loading = useEventValue(model.loading);
    useEffect(() => {
        if (dateFilter?.from && dateFilter?.to) {
            nav.mergeParams({ from: fmtSvc.toJsonShortDate(dateFilter.from), to: fmtSvc.toJsonShortDate(addDays(dateFilter.to, -1)) });
        }
    }, [dateFilter?.from, dateFilter?.to]);
    const selectedTrace = useEventValue(model.traceSelectionChange);
    const small = useMediaQuery('(max-width: 1400px)');
    const panelWidth = small ? 350 : 450;

    const [activeRule, setActiveRule] = useState<TagAutomationRule>();
    const [homeModel, setHomeModel] = useState<MapContractHomeModel>();
    const [homeModelLoaded, setHomeModelLoaded] = useState<boolean>(false);
    const autoTagModel = useDi(MapAutoTagModel);

    useEffect(() => {
        if (id) {
            setHomeModel(container.resolve(MapContractHomeModel).init(id));
            setHomeModelLoaded(true);
        }
    }, [id]);

    useEffect(() => {
        if (homeModel && homeModel.contract) {
            autoTagModel.init(homeModel.contract);
        }
    }, [homeModel, homeModel?.contract]);

    const mapContractEditCtx = useDi(MapContractEditContext);
    const mapContractListCtx = useDi(MapContractListContext);
    const editing = useEventValue(mapContractEditCtx.editorOpen);
    useEffect(() => {
        if (editing) {
            setActiveRule(model.rule);
        } else {
            setActiveRule(undefined);
        }
    }, [editing]);

    const saveRule = useCallback(
        async (rule: TagAutomationRule) => {
            await autoTagModel.updateRule(rule);
            setActiveRule(undefined);
            mapContractEditCtx.editorOpen.emit(false);
            model.load(ruleId);

            mapContractListCtx.refreshList.emit(true);
        },
        [autoTagModel]
    );

    useEffect(() => {
        if (selectedTrace) {
            open;
        }
    }, [selectedTrace]);

    return (
        <PageContent>
            <PaneledPage>
                <PagePanel size={panelWidth}>
                    <Box>
                        <PanelHeader>
                            <Text size={20}>Rule Activity</Text>
                        </PanelHeader>
                        <Group px="xl" pb="md" position="center" noWrap>
                            <TagAutomationDateRangeFilter initialValue={initialValue} onChange={setDateFilter} />
                            <Tooltip label="Refresh">
                                <ActionIcon onClick={model.refresh}>
                                    <Refresh className={loading ? 'ti-spin' : ''} size={20} />
                                </ActionIcon>
                            </Tooltip>
                        </Group>
                    </Box>
                    <Divider color={theme.colors.gray[3] as CustomColors} />
                    <PanelBody noPadding>
                        {loading || !model.rule || !dateFilter ? null : (
                            <RuleDetails dateRange={dateFilter} rule={model.rule} homeModelLoaded={homeModelLoaded} />
                        )}
                    </PanelBody>
                </PagePanel>
                <Divider color={theme.colors.gray[3] as CustomColors} orientation="vertical" />
                <PagePanel size="fill" style={{ background: '#fff' }}>
                    <PanelBody>{loading || !model.rule || !dateFilter ? null : <ActivityGrid dateRange={dateFilter} rule={model.rule} />}</PanelBody>
                </PagePanel>
                <Drawer
                    onClose={model.clearSelectedTrace}
                    closeOnClickOutside
                    opened={!!selectedTrace}
                    position="right"
                    size={400}
                    withinPortal={false}
                    withCloseButton={false}
                >
                    <Stack sx={{ height: '100%', overflow: 'hidden' }} spacing={0}>
                        <Group position="apart">
                            <Title pl="md" order={3}>
                                Activity
                            </Title>
                            <Box style={{ padding: '16px' }}>
                                <CloseButton onClick={model.clearSelectedTrace} />
                            </Box>
                        </Group>
                        <Divider color={theme.colors.gray[3] as CustomColors} />
                        {!selectedTrace ? null : <RuleTraceViewer onOpenDetails={setResourceId} trace={selectedTrace} />}
                    </Stack>
                </Drawer>
                <ResourceDetailsOpener
                    platform={CloudPlatform ?? 'Aws'}
                    onClose={() => setResourceId({})}
                    resourceId={ResourceId}
                    resourceType={ResourceType ?? ''}
                />

                {!activeRule || !homeModel?.mapResourceQuerySvc ? null : (
                    <MapAutoTagRule
                        contractId={homeModel.contract!.Id!}
                        automationRuleId={activeRule.Id ?? null}
                        onDelete={autoTagModel.deleteRule}
                        onSave={saveRule}
                        onClose={() => setActiveRule(undefined)}
                        mapQueryService={homeModel.mapResourceQuerySvc}
                    />
                )}
            </PaneledPage>
        </PageContent>
    );
}

function RuleDetails({
    rule,
    dateRange,
    homeModelLoaded,
}: {
    rule: TagAutomationRule;
    dateRange: { from?: Date; to?: Date };
    homeModelLoaded: boolean;
}) {
    return (
        <ScrollArea sx={{ height: '100%' }}>
            <Box p="lg">
                <RuleTitleCard rule={rule} homeModelLoaded={homeModelLoaded} />
                <Space h="md" />
                <StatusRollup rule={rule} dateRange={dateRange} />
                <Space h="md" />
                <PeriodActivity rule={rule} dateRange={dateRange} />
            </Box>
        </ScrollArea>
    );
}

function RuleTitleCard({ rule, homeModelLoaded }: { rule: TagAutomationRule; homeModelLoaded: boolean }) {
    const theme = useMantineTheme();
    const [detailsOpened, { toggle: toggleDetails }] = useDisclosure(false);
    const fmtSvc = useDi(FormatService);
    const statusIcon = rule.Status === 'Active' ? 'ti ti-circle-filled' : rule.Status === 'Test' ? 'ti ti-clock-hour-10' : 'ti ti-circle-dashed';
    const testExpired = rule.Status == 'Test' && new Date(rule.TestEndDate!) < new Date();
    const testExpirationDate = fmtSvc.toShortDate(fmtSvc.toLocalDate(rule.TestEndDate));

    const statusColor = rule.Status === 'Active' ? 'success' : rule.Status === 'Test' ? 'warning' : 'gray';
    const nav = useNav();

    const mapContractEditCtx = useDi(MapContractEditContext);
    const editRule = () => {
        if (homeModelLoaded) {
            mapContractEditCtx.editorOpen.emit(true);
        } else {
            const ruleId = rule.Id ? rule.Id.toString() : '';
            nav.descend('automation-rule', { ruleId });
        }
    };

    const statusDescription =
        rule.Status === 'Test' ? (
            <>
                Rule is running in Test mode.
                <br />
                No tag changes will occur. <br />
                {!testExpired ? (
                    <>Test will run until {testExpirationDate}.</>
                ) : (
                    <Text weight="bolder" color={'error'}>
                        Test expired {testExpirationDate}.
                    </Text>
                )}
            </>
        ) : rule.Status === 'Active' ? (
            <>
                Rule is Live
                <br /> It may make tag changes and charge credits.
            </>
        ) : (
            <>
                Rule is in Draft mode
                <br /> No tag changes will occur.
            </>
        );
    return (
        <Card radius="md" p={0} withBorder sx={{ background: theme.colors.gray[2] }}>
            <Group p="md" align="center" position="apart" noWrap spacing="xs">
                <Group noWrap spacing={5}>
                    <TooltipWhite withinPortal label={statusDescription}>
                        <Box>
                            <ThemeIcon variant="light" color={statusColor} sx={{ background: 'none' }}>
                                <i className={statusIcon}></i>
                            </ThemeIcon>
                        </Box>
                    </TooltipWhite>
                    <Text weight="bolder">
                        {rule.RuleName}
                        {rule.Status === 'Test' ? (
                            <>
                                <br />
                                {testExpired ? (
                                    <Text color={'error'} size={12}>
                                        Expired {testExpirationDate}
                                    </Text>
                                ) : (
                                    <Text size={12}>Expires {testExpirationDate}</Text>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Text>
                </Group>
                <Box>
                    <Tooltip withinPortal label="Show Rule Definition">
                        <ActionIcon onClick={toggleDetails}>{detailsOpened ? <ChevronUp /> : <ChevronDown />}</ActionIcon>
                    </Tooltip>
                </Box>
            </Group>
            <Collapse in={detailsOpened}>
                <Divider color={theme.colors.gray[3] as CustomColors} />
                <Box p="md" sx={{ background: theme.white }}>
                    {!rule.RuleDescription ? null : (
                        <>
                            <Text>Rule Description:</Text>
                            <Text size="sm">{rule.RuleDescription}</Text>
                            <Space h="md" />
                        </>
                    )}
                    <Text>Rule Definition:</Text>
                    <RuleDescription rule={rule} />
                </Box>
                <Divider color={theme.colors.gray[3] as CustomColors} />
                <Group p="md" position="right">
                    <Button component="a" variant="outline" onClick={editRule}>
                        Edit Rule
                    </Button>
                </Group>
            </Collapse>
        </Card>
    );
}

function StatusRollup({ rule, dateRange }: { rule: TagAutomationRule; dateRange: { from?: Date; to?: Date } }) {
    const model = useDi(TagAutomationRuleActivityModel);
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<{ issues: number; changes: number; tests: number; all: number; testIssues: number }>();
    const status = useEventValue(model.status);
    const loadStats = async (dateRange: { from?: Date; to?: Date }) => {
        setLoading(true);
        try {
            const from = dateRange.from;
            const to = dateRange.to;
            if (!from || !to) return;
            const response = await queryBuilder<{
                Timestamp: Date;
                RuleId: number;
                Status: TagAutomationTraceByRuleStatus;
                Mode: TagAutomationTraceByRuleMode;
                JobStatus: AnonymousQueueJobJobStatus;
                RequestedChanges: number;
                CompletedChanges: number;
            }>()
                .where((b) => {
                    const filters: IFluentOperators<boolean>[] = [];
                    if (from) {
                        filters.push(b.model.Timestamp!.onOrAfter(from));
                    }
                    if (to) {
                        filters.push(b.model.Timestamp!.before(to));
                    }
                    return b.and(b.model.RuleId!.eq(rule.Id!), ...filters);
                })
                .select((b) => ({
                    status: {
                        Operation: 'values',
                        Operands: [{ Field: 'Status' }, { Value: '' }, { Value: ValuesGroupOtherText }],
                    } as unknown as TagAutomationTraceByRuleStatus,
                    jobStatus: {
                        Operation: 'values',
                        Operands: [{ Field: 'JobStatus' }, { Value: '' }, { Value: ValuesGroupOtherText }],
                    } as unknown as AnonymousQueueJobJobStatus,
                    mode: b.model.Mode!,
                    count: b.count(),
                }))
                .execute(postTagAutomationQueryTraceByRule);
            setData(
                response.Results?.reduce(
                    (result, item) => {
                        if (item.mode === 'Test' && item.status === 'Sent') {
                            result.tests += item.count;
                        } else if (item.mode === 'Test') {
                            result.testIssues += item.count;
                        } else if (
                            item.jobStatus === 'Failed' ||
                            item.jobStatus === 'Canceled' ||
                            item.status === 'CreditsExhausted' ||
                            item.status === 'Fail' ||
                            item.status === 'CycleDetected'
                        ) {
                            result.issues += item.count;
                        } else {
                            result.changes += item.count;
                        }
                        result.all += item.count;
                        return result;
                    },
                    { issues: 0, changes: 0, tests: 0, all: 0, testIssues: 0 }
                )
            );
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        loadStats(dateRange);
    }, [dateRange.from, dateRange.to, rule]);

    const options = [
        <StatusRollupOption active={!status} status="All" onClick={() => model.status.emit(undefined)} count={data?.all} />,
        (rule.Status === 'Test' || data?.tests != 0) && (
            <StatusRollupOption active={status === 'test'} status="Passed Tests" onClick={() => model.status.emit('test')} count={data?.tests} />
        ),
        (rule.Status === 'Test' || data?.tests != 0) && (
            <StatusRollupOption
                active={status === 'testIssues'}
                status="Failed Tests"
                onClick={() => model.status.emit('testIssues')}
                count={data?.testIssues}
            />
        ),
        <StatusRollupOption active={status === 'success'} status="Changes" onClick={() => model.status.emit('success')} count={data?.changes} />,
        (rule.Status !== 'Test' || data?.issues != 0) && (
            <StatusRollupOption active={status === 'fail'} status="Issues" onClick={() => model.status.emit('fail')} count={data?.issues} />
        ),
    ];

    return (
        <Card shadow="sm" radius="md" p={0} sx={{ background: theme.colors.gray[1] }} withBorder>
            {options
                .filter((o) => !!o)
                .map((o, i) => (
                    <Fragment key={i}>
                        {i > 0 && <Divider color={theme.colors.gray[3] as CustomColors} />}
                        {o}
                    </Fragment>
                ))}
        </Card>
    );
}

function StatusRollupOption({ status, count, onClick, active }: { status: string; count?: number; onClick: () => void; active: boolean }) {
    const fmtSvc = useDi(FormatService);
    return (
        <Box p="sm">
            <StatusPicker onClick={onClick} state={active ? 'active' : undefined}>
                <Text>
                    {count === undefined ? <Loader mb={-4} size="xs" /> : fmtSvc.formatInt(count)} - {status}
                </Text>
                <ChevronRight />
            </StatusPicker>
        </Box>
    );
}

const StatusPicker = styled.a<{ state?: 'active' }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 5px;
    background: ${(p) => (p.state === 'active' ? p.theme.colors.primary[6] : undefined)};
    color: ${(p) => (p.state === 'active' ? p.theme.colors.primary[1] : undefined)};
    &:hover {
        background: ${(p) => (p.state === 'active' ? p.theme.colors.primary[6] : p.theme.colors.primary[1])};
        color: ${(p) => (p.state === 'active' ? p.theme.colors.primary[1] : p.theme.colors.primary[6])};
    }
`;

function PeriodActivity({ rule, dateRange }: { rule: TagAutomationRule; dateRange: { from?: Date; to?: Date } }) {
    const [data, setData] = useState<Record<string, string | number>[]>([]);
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);

    const loadActivity = async (dateRange: { from?: Date; to?: Date }) => {
        setLoading(true);
        try {
            const from = dateRange.from;
            const to = dateRange.to;
            if (!from || !to) return;

            const response = await queryBuilder<{
                Timestamp: Date;
                RuleId: number;
                RequestedChanges: number;
                CompletedChanges: number;
            }>()
                .where((b) => {
                    const filters: IFluentOperators<boolean>[] = [];
                    if (from) {
                        filters.push(b.model.Timestamp!.onOrAfter(from));
                    }
                    if (to) {
                        filters.push(b.model.Timestamp!.before(to));
                    }
                    return b.and(b.model.RuleId!.eq(rule.Id!), ...filters);
                })
                .select((b) => ({
                    date: b.truncDate('day', b.model.Timestamp!, -fmtSvc.getTzOffsetHours(), from, addDays(to, -1)),
                    requested: b.sum(b.model.RequestedChanges),
                    completed: b.sum(b.model.CompletedChanges),
                }))
                .execute(postTagAutomationQueryTraceByRule);
            setData(
                response?.Results?.reduce((result, item) => {
                    result.push(
                        {
                            Date: fmtSvc.toShortDate(item.date),
                            Activity: 'Requested',
                            Resources: item.requested,
                        },
                        {
                            Date: fmtSvc.toShortDate(item.date),
                            Activity: 'Changed',
                            Resources: item.completed,
                        }
                    );
                    return result;
                }, [] as Record<string, number | string>[]) ?? []
            );
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        loadActivity(dateRange);
    }, [dateRange?.from, dateRange?.to, rule]);
    return (
        <Card shadow="sm" radius="md" withBorder sx={{ height: '400px' }}>
            <Text>Tag Changes</Text>
            {loading ? <LoadingOverlay visible /> : null}
            <LineChart
                settings={{ margin: { bottom: 100, left: 50, right: 20, top: 20 }, noWrapper: true }}
                data={data}
                groups={['Date', 'Activity']}
                values={['Resources']}
            />
        </Card>
    );
}

function TagAutomationRuleActivityBreadCrumb() {
    const model = useDi(TagAutomationRuleActivityModel);
    const rule = model.rule;
    const loading = useEventValue(model.loading);
    const text = (
        <Tooltip
            withArrow
            withinPortal
            color="gray.0"
            sx={{
                color: 'GrayText',
                boxShadow: '0px 4px 15px 0px #00000040',
            }}
            label={`Activity for
            ${rule?.RuleName || 'Unnamed'}
            `}
        >
            <Box sx={{ maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {`Activity for
            ${rule?.RuleName || 'Unnamed'}
            `}
            </Box>
        </Tooltip>
    );
    return <>{!loading ? text : 'Loading...'}</>;
}

endpoint('tag-automation-rule-activity', TagAutomationRuleActivity, TagAutomationRuleActivityBreadCrumb);
