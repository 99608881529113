import { IQueryExpr, TypeMapping } from '@apis/Resources/model';
import { Space, Text } from '@mantine/core';
import {
    AwsOUAccountGroupRuleOptionsGroupingType,
    ReplacementOptions,
    TagAutomationRule,
    TagAutomationRuleParametersType,
    TagRuleSyntax,
} from '@apis/TagManager/model';
import { QueryDescriptorService } from '@root/Components/Filter/Services';
import { useDi } from '@root/Services/DI';
import { useCallback, useMemo } from 'react';
import { RuleEditor, TagLookupService } from './Model';
import { Point } from 'tabler-icons-react';
import { observer } from 'mobx-react-lite';
import { ValidationIssues } from './FilterResources';

function describeAutoTag(options: TagRuleSyntax) {
    const result: string[] = [];
    if (!options.AutoTagOptions?.length) {
        result.push('No tags have been selected.');
    } else if (!options.AutoTagOptions[0].KeyToAdd && !options.AutoTagOptions[0].ValueToAdd) {
        result.push('No action will be taken, because no tags are filled out.');
    } else {
        const invalidOptions = options.AutoTagOptions.filter((t) => !t.KeyToAdd || !t.ValueToAdd);
        const validOptions = options.AutoTagOptions.filter((t) => t.KeyToAdd && t.ValueToAdd);
        if (validOptions.length) {
            if (validOptions.length > 1) {
                result.push(`The following ${validOptions.length} actions will be taken:`);
            } else {
                result.push(`The following action will be taken:`);
            }
            result.push(...validOptions.map((o) => `\tA tag with key "${o.KeyToAdd}" and value "${o.ValueToAdd}" will be added`));
        } else {
            result.push(`No action will be taken, because there are no valid tags selected. `);
        }
        if (invalidOptions.length > 1) {
            result.push(`${invalidOptions.length} selected tags are ignored, because they are missing a key or value.`);
        } else if (invalidOptions.length) {
            result.push(`1 selected tag is ignored, because it is missing a key or value.`);
        }
    }
    return result;
}

function describeInheritance(options: TagRuleSyntax, queryDescriptor: QueryDescriptorService) {
    return [];
}

function describeReplace(options: TagRuleSyntax) {
    const result: string[] = [];
    if (
        !options.ReplacementOptions?.length ||
        (!options.ReplacementOptions[0].KeyToCheck &&
            !options.ReplacementOptions[0].KeyToReplace &&
            !options.ReplacementOptions[0].ValueToCheck &&
            !options.ReplacementOptions[0].ValueToReplace)
    ) {
        result.push('No replacements have been configured. ');
    } else {
        const isInvalid = (r: ReplacementOptions) =>
            !r.KeyToCheck ||
            !r.KeyToReplace ||
            (r.KeyToCheck === r.KeyToReplace && r.ValueToCheck == r.ValueToReplace) ||
            (r.KeyToCheck === r.KeyToReplace && !r.ValueToReplace);

        const invalidRules = options.ReplacementOptions.filter(isInvalid);
        if (invalidRules.length === 1) {
            result.push(`1 rule is invalid. It will be ignored. `);
        } else if (invalidRules.length > 1) {
            result.push(`${invalidRules.length} rules are invalid. They will be ignored. `);
        }
        const validRules = options.ReplacementOptions.filter((r) => !isInvalid(r));
        if (!validRules.length) {
            result.push('There are no valid replacement rules.');
        } else {
            result.push('Tags will be copied or replaced according to these rules:');
            result.push(
                ...validRules.map((r) => {
                    if (!r.ValueToCheck && !r.ValueToReplace) {
                        return `\tTags with "${r.KeyToCheck}" key will be removed, and their value will be copied to tag with key "${r.KeyToReplace}"`;
                    } else if (!r.ValueToCheck) {
                        if (r.KeyToCheck !== r.KeyToReplace) {
                            return `\tTags with "${r.KeyToCheck}" key will be replaced with "${r.KeyToReplace}" key and "${r.ValueToReplace}" as the value`;
                        } else {
                            return `\tAny values on tags with "${r.KeyToCheck}" key will be changed to "${r.ValueToReplace}"`;
                        }
                    } else if (!r.ValueToReplace) {
                        return `\tA tag with "${r.KeyToReplace}" key and "${r.ValueToCheck}" value will be applied, and tags with "${r.KeyToCheck}" key will be removed`;
                    } else {
                        if (r.KeyToCheck === r.KeyToReplace) {
                            return `\tOn tags with "${r.KeyToReplace}" key and "${r.ValueToCheck}" value, the value will be updated to "${r.ValueToReplace}"`;
                        } else {
                            return `\tTags with "${r.KeyToCheck}" key and "${r.ValueToCheck}" value will be replaced with "${r.KeyToReplace}" key and "${r.ValueToReplace}" value`;
                        }
                    }
                })
            );
        }
    }
    return result;
}

function describeSyntaxCorrection(options: TagRuleSyntax) {
    const result: string[] = [];
    const stx = options.SyntaxOptions;
    if (
        !stx ||
        ![
            stx.CapitalizeFirstLetter,
            stx.RemoveSpacesAtEnd,
            stx.RemoveSpacesAtStart,
            stx.ReplaceEmptySpacesWithUnderScores,
            stx.CapitalizeAll,
            stx.LowercaseAll,
            stx.LowercaseFirstLetter,
        ].includes(true)
    ) {
        result.push('No syntax correction rules are selected. ');
    } else if (!stx.ApplyTagKeys && !stx.ApplyTagValues) {
        result.push('Corrections will not apply to tag keys or values, so there will be no impact. ');
    } else {
        const specificKeysCt = stx.TagKeysToApplyTo?.length ?? 0;
        const specificValuesCt = stx.TagValuesToApplyTo?.length ?? 0;

        const target = [stx.ApplyTagKeys && 'Keys', stx.ApplyTagValues && 'Values'].filter((t) => typeof t === 'string').join(' and ');

        if (specificKeysCt || specificValuesCt) {
            const keys = stx.TagKeysToApplyTo?.map((k) => `"${k}"`).join(', ');
            const keysPlural = specificKeysCt > 1 ? `these ${specificKeysCt} specific keys` : `this ${specificKeysCt} specific key`;

            const values = stx.TagValuesToApplyTo?.map((k) => `"${k}"`).join(', ');
            const valuesPlural = specificValuesCt > 1 ? `these ${specificValuesCt} specific values` : `this ${specificValuesCt} specific value`;

            let message = '';
            if (specificKeysCt > 0) {
                message = `${target} on tags with ${keysPlural} (${keys})`;
                if (specificValuesCt > 0) {
                    message += ` and ${valuesPlural} (${values})`;
                }
            } else {
                message = `${target} on tags with ${valuesPlural} (${values})`;
            }
            message += ` will be updated as follows:`;
            result.push(message);
        }

        if (stx.CapitalizeAll) result.push(`\tAll letters will be capitalized`);
        if (stx.LowercaseAll) result.push(`\tAll letters will be lowercased`);
        if (stx.CapitalizeFirstLetter) result.push(`\tThe first letter will be capitalized`);
        if (stx.LowercaseFirstLetter) result.push(`\tThe first letter will be lowercased`);
        if (stx.RemoveSpacesAtStart) result.push(`\tAny spaces at the beginning will be removed`);
        if (stx.RemoveSpacesAtEnd) result.push(`\tAny spaces at the end will be removed`);
        if (stx.ReplaceEmptySpacesWithUnderScores) result.push(`\tAll spaces will be replaced with underscores`);
    }
    return result;
}

function describeMapCredits(options: TagRuleSyntax, queryDescriptor: QueryDescriptorService) {
    return [];
}
function describeResourceGroup(options: TagRuleSyntax) {
    const result: string[] = [];
    const stx = options.ResourceGroupRuleOptions;
    if (!stx || !stx.SelectedTags || stx.SelectedTags!.length === 0) {
        result.push('No resource group are selected.');
    } else {
        if (stx.SelectedTags) {
            if (stx.SelectedTags.length > 0) {
                stx.SelectedTags.forEach((resourceGroup) => {
                    var rgName = resourceGroup.ResourceGroup;
                    var selectedTagsForRg = resourceGroup.SelectedTags;
                    let message = 'Every resource in the resource group "' + rgName + '" will be tagged with the following tags: ';
                    selectedTagsForRg?.forEach((tag) => {
                        message = message + tag + (selectedTagsForRg?.length! > 1 ? ', ' : ';');
                    });
                    result.push(message);
                });
            }
        }
    }
    return result;
}

function describeAwsOUAccountGroup(options: TagRuleSyntax) {
    const result: string[] = [];
    const stx = options.AwsOUAccountGroupRuleOptions;
    if (!stx || !stx.SelectedTags || stx.SelectedTags!.length === 0) {
        result.push('No entity tags are selected.');
    } else {
        if (stx.SelectedTags) {
            if (stx.SelectedTags.length > 0) {
                stx.SelectedTags.forEach((entityGroup) => {
                    var entityName = entityGroup.EntityId;
                    var selectedTagsForEntity = entityGroup.SelectedTags;
                    var type = stx.GroupingType == AwsOUAccountGroupRuleOptionsGroupingType.Account ? 'Account' : 'Organization';
                    let message = 'Every resource in the under the ' + type + ' "' + entityName + '" will be tagged with the following tags: ';
                    selectedTagsForEntity?.forEach((tag) => {
                        message = message + tag + (selectedTagsForEntity?.length! > 1 ? ', ' : ';');
                    });
                    result.push(message);
                });
            }
        }
    }
    return result;
}

function describeBulkTag(options: TagRuleSyntax) {
    const result: string[] = [];
    const stx = options.BulkTagOptions;
    if (!stx || !stx.ResourceField || !stx.Headers || stx.Headers!.length === 0) {
        result.push('Tag Import options not configured.');
    } else {
        if (stx.ResourceField) {
            if (stx.Headers.length > 0) {
                let message = 'Resources with matching ' + stx.FileField + ' will be tagged with the following tags: ';
                stx.Headers.forEach((header, i) => {
                    if (header !== stx.FileField) {
                        let message2 = '';
                        if (i === stx.Headers!.length - 1) {
                            message2 = header + '. ';
                        } else {
                            message2 = header + ', ';
                        }
                        message = message + message2;
                    }
                });
                result.push(message);
            }
        }
    }
    return result;
}

const changeDescriberLookup: Record<TagAutomationRuleParametersType, (options: TagRuleSyntax, queryDescriptor: QueryDescriptorService) => string[]> =
    {
        AutoTag: describeAutoTag,
        Inheritance: describeInheritance,
        MapCredits: describeMapCredits,
        Replacement: describeReplace,
        SyntaxCorrection: describeSyntaxCorrection,
        ResourceGroup: describeResourceGroup,
        AwsOUAccountGroup: describeAwsOUAccountGroup,
        BulkTag: describeBulkTag,
    };

function describeChanges(queryDescriptor: QueryDescriptorService, type?: TagAutomationRuleParametersType, changes?: TagRuleSyntax) {
    if (!changes || !type) {
        return ['No changes have been configured'];
    }
    const describer = changeDescriberLookup[type];
    return describer(changes, queryDescriptor);
}
function describeConstraints(filter: IQueryExpr | undefined, queryDescriptor: QueryDescriptorService) {
    const result: string[] = [];
    if (filter && 'Operands' in filter && filter.Operands.length) {
        const filters = filter.Operands.map((q: IQueryExpr) => {
            const tokens = queryDescriptor.getTokens(q);
            const tokenText = tokens.map((t) => {
                let textItem = t.type === 'field' ? `Resource's ${t.text}` : t.type === 'none' ? 'INVALID' : t.text;
                if (typeof textItem === 'object') {
                    textItem = 'asdf';
                }
                return textItem;
            });
            return tokenText.length ? '\t' + tokenText.join(' ') : null;
        }).filter((t: string | null) => t !== null);
        if (filters.length) {
            result.push('Only resources that meet the following criteria would be impacted:', ...filters);
        }
    }

    if (!result.length) {
        result.push('All resources would be impacted, because no filters are selected. ');
    }
    return result;
}

export function getRuleTypeUfName(type?: TagAutomationRuleParametersType) {
    return type === 'AutoTag'
        ? 'Auto-Tag'
        : type === 'Inheritance'
        ? 'Tag Inheritance'
        : type === 'MapCredits'
        ? 'MAP Auto-Tagging'
        : type === 'Replacement'
        ? 'Tag Replacement'
        : type === 'SyntaxCorrection'
        ? 'Syntax Correction'
        : type === 'ResourceGroup'
        ? 'Group Inheritance'
        : type === 'AwsOUAccountGroup'
        ? 'Group Inheritance'
        : type === 'BulkTag'
        ? 'Tag Import'
        : '';
}

function describeConstraintsInheritance(
    filter: IQueryExpr | undefined,
    selectedChildTypes: TypeMapping[] | [],
    queryDescriptor: QueryDescriptorService
) {
    const result: string[] = [];
    if (filter && 'Operands' in filter && filter.Operands.length) {
        const filters = filter.Operands.map((q: IQueryExpr) => {
            const tokens = queryDescriptor.getTokens(q);
            const tokenText = tokens.map((t) => {
                let textItem = t.type === 'field' ? `Resource's ${t.text}` : t.type === 'none' ? 'INVALID' : t.text;
                if (typeof textItem === 'object') {
                    textItem = 'asdf';
                }
                return textItem;
            });
            return tokenText.length ? '\t' + tokenText.join(' ') : null;
        }).filter((t: string | null) => t !== null);
        if (filters.length) {
            result.push('Only children resources of resources that meet the following criteria would be impacted:', ...filters);
        }
    }

    if (selectedChildTypes.length > 0) {
        const childTypesByParentType = groupBy(selectedChildTypes, (t: TypeMapping) => t.ParentResourceType);
        const childTypesMessages = Object.keys(childTypesByParentType).map((parentType) => {
            const childTypes = childTypesByParentType[parentType].map((t: any) => t.ChildResourceType).join(', ');
            return `\t"${childTypes}" resources attached to an "${parentType}" `;
        });
        result.push('Only resources that have the following hierarchy relationship criteria would be impacted:', ...childTypesMessages);
    }

    if (!result.length) {
        result.push('All resources would be impacted, because no filters are selected. ');
    }
    return result;
}

function describeCaveats(rule: TagAutomationRule) {
    const result: string[] = [];

    if (rule.Parameters?.Type === TagAutomationRuleParametersType.Inheritance && rule.Status !== 'Draft') {
        result.push('This rule will apply all missing tags to child resources of the resources that meet the filter criteria.');
    }

    if (
        (rule.Parameters?.Type === TagAutomationRuleParametersType.ResourceGroup &&
            rule.Parameters.Syntax?.ResourceGroupRuleOptions?.ApplyAllTags === true) ||
        (rule.Parameters?.Type === TagAutomationRuleParametersType.AwsOUAccountGroup &&
            rule.Parameters.Syntax?.AwsOUAccountGroupRuleOptions?.ApplyAllTags === true)
    ) {
        result.push('This rule will apply all tags(current and future) from the selected entities to related resources.');
    }

    if (rule.Parameters?.Syntax?.OverwriteValue === true) {
        result.push('This rule will overwrite any existing tags that exist on the targeted resources');
    }

    if (rule.Status === 'Draft') {
        result.push('This rule will have no effect, because it is in Draft mode. ');
    }

    return result;
}
export function describeRule(rule: TagAutomationRule, queryDescriptor: QueryDescriptorService) {
    let constraints = [];
    constraints =
        rule.Parameters?.Type === TagAutomationRuleParametersType.Inheritance
            ? describeConstraintsInheritance(
                  rule.Parameters?.Syntax?.HierarchyOptions?.ParentFilter,
                  rule.Parameters.Syntax?.HierarchyOptions?.ChildResourceTypes!,
                  queryDescriptor
              )
            : describeConstraints(rule.Parameters?.Filter, queryDescriptor);

    if (rule.Parameters?.Type === TagAutomationRuleParametersType.ResourceGroup) {
        const filter = rule.Parameters?.Syntax?.ResourceGroupRuleOptions?.Filter;
        if (filter != undefined && filter.Operands.length > 0) {
            constraints.push(...describeConstraints(rule.Parameters?.Syntax?.ResourceGroupRuleOptions?.Filter, queryDescriptor));
        }
    }

    if (rule.Parameters?.Type === TagAutomationRuleParametersType.AwsOUAccountGroup) {
        const filter = rule.Parameters?.Syntax?.AwsOUAccountGroupRuleOptions?.Filter;
        if (filter != undefined && filter.Operands.length > 0) {
            constraints.push(...describeConstraints(rule.Parameters?.Syntax?.AwsOUAccountGroupRuleOptions?.Filter, queryDescriptor));
        }
    }

    return {
        changes: describeChanges(queryDescriptor, rule.Parameters?.Type, rule.Parameters?.Syntax),
        constraints: constraints,
        caveats: describeCaveats(rule),
    };
}

export const RuleDescription = observer(function RuleDescription({ rule }: { rule: TagAutomationRule }) {
    const { schemaSvc } = useDi(TagLookupService);
    const queryDescriptor = useMemo(() => QueryDescriptorService.create(schemaSvc), [schemaSvc]);
    const description = describeRule(rule, queryDescriptor);
    return (
        <>
            <RuleDescriptionSection lines={description.changes} />
            <Space h="xs" />
            <RuleDescriptionSection lines={description.constraints} />
            <Space h="xs" />
            <RuleDescriptionSection lines={description.caveats} />
        </>
    );
});

function groupBy(xs: any, f: any) {
    return xs.reduce((r: any, v: any, i: any, a: any, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

export function useFilterValidation(ruleEditor: RuleEditor) {
    return useCallback(
        (issues: ValidationIssues) => {
            const filterValidation = [];
            if (issues['Missing Field']) {
                const count = issues['Missing Field'];
                if (count > 1) {
                    filterValidation.push(`${count} filters have no field selected`);
                } else {
                    filterValidation.push(`1 filter has no field selected`);
                }
            }
            if (issues['Missing Value']) {
                const count = issues['Missing Value'];
                if (count > 1) {
                    filterValidation.push(`${count} filters are missing a value`);
                } else {
                    filterValidation.push(`1 filter is missing its value`);
                }
            }
            ruleEditor.filterValidation = filterValidation;
        },
        [ruleEditor]
    );
}

function RuleDescriptionSection({ lines }: { lines: string[] }) {
    return (
        <>
            {lines.map((l, i) => {
                if (l.startsWith('\t')) {
                    return (
                        <Text color="dimmed" my="2px" size="sm" key={i} sx={{ gridTemplateColumns: '32px auto', display: 'grid' }}>
                            <Point size={16} style={{ margin: '4px 0 0 12px' }} fill="#000" stroke="#0000" />
                            {l.substring(1)}
                        </Text>
                    );
                } else {
                    return (
                        <Text size="sm" mb="xs" key={i}>
                            {l}
                        </Text>
                    );
                }
            })}
        </>
    );
}
