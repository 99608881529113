import { Anchor, Badge, Button, Card, Container, Drawer, Group, Loader, LoadingOverlay, Text, useMantineTheme } from '@mantine/core';
import { observer } from 'mobx-react';
import { RuleEditCard } from './Design';
import { PreviewGroup, PreviewItem, RuleEditor, TagLookupService } from './Model';
import { RuleDescription } from './RuleDescriber';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { TagAutomationRuleService } from '@root/Site/TagManager/TagAutomation/Components/TagAutomationRuleService';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnGroupConfig, ColumnConfig, AnonymousValue, ChildAccessor } from '@root/Components/DataGrid/Models';
import { useState, useMemo } from 'react';
import { BaseResource, ResourceChangePreviewModel } from '@apis/Resources/model';
import { FieldInfoColumnAdapter } from '@root/Components/DataGrid/FieldInfoColumnAdapter';
import { FieldInfo } from '@root/Services/QueryExpr';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { CustomColors } from '@root/Design/Themes';
import { Node } from '@root/Components/VirtualTree/Node';
import { TagAutomationRuleParametersType } from '@apis/TagManager/model';
import { useEventValue, EventEmitter } from '@root/Services/EventEmitter';
import { ResourceDetailsOpener } from '@root/Components/Resources/ResourceDetails';
import { SidePanel, SidePanelContainer } from '@root/Design/SidePanel';
import { useRouteBoundPortal } from '@root/Components/Router/RouteBoundPortal';

export const PreviewRuleCard = observer(function PreviewRuleCard({ ruleEditor }: { ruleEditor: RuleEditor }) {
    const tagAutomationRulesSvc = useDi(TagAutomationRuleService);
    const openPreviewDrawer = () => {
        ruleEditor.refreshPreviewSample();
        tagAutomationRulesSvc.openPreviewDrawer();
    };
    const formatSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const showImpact =
        !ruleEditor.rule?.Id || (ruleEditor.originalStatus === 'Active' && ruleEditor.parametersChanged()) || ruleEditor.originalStatus !== 'Active';
    const rulesAffectedDescription =
        ruleEditor.rule?.Parameters?.Type === TagAutomationRuleParametersType.Inheritance
            ? 'Currently attached resources will be tagged to match their related resources'
            : 'Existing resources will be affected when this rule is activated';

    return (
        <>
            <RuleEditCard title="Impact">
                <Group noWrap align="start">
                    {!showImpact ? null : (
                        <Card radius="md" withBorder sx={{ background: theme.colors.gray[2], minWidth: '200px', maxWidth: '200px' }}>
                            <Badge color="warning" variant="outline" fullWidth size="xl" sx={{ background: theme.white }}>
                                {ruleEditor.preview.isCountRefreshing ? (
                                    <Loader color={'orange' as CustomColors} pt={5} size="xs" />
                                ) : (
                                    formatSvc.formatInt(ruleEditor.preview.count)
                                )}
                            </Badge>
                            <Text size="xs" my="sm">
                                {rulesAffectedDescription}
                            </Text>
                            <Button fullWidth onClick={openPreviewDrawer}>
                                Preview
                            </Button>
                        </Card>
                    )}
                    <div>{ruleEditor.rule ? <RuleDescription rule={ruleEditor.rule} /> : null}</div>
                </Group>
            </RuleEditCard>
        </>
    );
});

export const PreviewResources = observer(function PreviewResources({ ruleEditor }: { ruleEditor: RuleEditor }) {
    const tagAutomationRulesSvc = useDi(TagAutomationRuleService);
    const tagLookupSvc = useDi(TagLookupService);
    const columnAdapter = useDi(FieldInfoColumnAdapter);
    const schema = tagLookupSvc.schemaSvc;
    const formatSvc = useDi(FormatService);
    const opened = tagAutomationRulesSvc.previewDrawerOpen;
    const [grid, setGrid] = useState<DataGridModel>();
    const data = ruleEditor.preview.results;
    const groupConfig: { [groupName: string]: ColumnGroupConfig } = {
        ['BEFORE']: { color: '#FF9900' },
        ['AFTER']: { color: '#007FFF' },
    };

    const selectedResource = useMemo(() => new EventEmitter<BaseResource | undefined>(undefined), []);
    const resource = useEventValue(selectedResource);

    const updateColumnAccessor = (columnField: FieldInfo, column: ColumnConfig<PreviewGroup>) => {
        let path = columnField.getPath();
        const splitValues = columnField.fieldName.split('.');
        path.splice(-1, 1);
        path.push.apply(path, splitValues);

        let accessorPath = path.map((accessor, i) => ({ accessor, isLeaf: i + 1 === path.length }));
        const actualAccessor = columnAdapter.createAccessor(accessorPath);

        column.accessor = (item: PreviewGroup) => {
            return actualAccessor(item);
        };
    };

    const childAccessor = {
        hasChildren: (item) => {
            return !!item.Children?.length;
        },
        getChildren: async (item) => {
            return item.Children;
        },
    } as ChildAccessor<PreviewGroup>;

    const columns = useMemo(() => {
        const additionalColumnFields = ruleEditor.preview.additionalColumns;

        const additionalColumns: ColumnConfig<PreviewGroup>[] = [...new Set<string>(additionalColumnFields)].map((field) => {
            const columnField = schema.getField(field)!;
            const column = columnAdapter.adapt(columnField!) as ColumnConfig<PreviewGroup>;

            updateColumnAccessor(columnField, column);
            return column;
        });

        const result = [
            {
                header: 'ID',
                accessor: (item) => item.Id,
                defaultWidth: 150,
                id: 'Name',
                align: 'left',
                type: 'string',
                cellRenderer: (resource) => (
                    <Anchor
                        onClick={() => {
                            selectedResource.emit(resource);
                        }}
                        data-atid={'ResourceNameLink:' + resource.Name}
                    >
                        {(resource as unknown as { Name: string }).Name}
                    </Anchor>
                ),
                defaultFixed: true,
            },
            {
                header: 'Resource Type',
                accessor: (item) => item.ResourceType,
                defaultWidth: 190,
                id: 'ResourceType',
            },
            {
                header: 'Tag Key : Tag Value',
                accessor: (item) => item.KeyValueBefore,
                cellRenderer: (item) => {
                    return item.KeyValueBefore != undefined ? (
                        <VisibleSpaces value={item.KeyValueBefore as string} />
                    ) : item.Children?.length ?? 0 > 0 ? null : (
                        'No change'
                    );
                },
                defaultWidth: 250,
                groupName: 'BEFORE',
                id: 'KeyValueBefore',
            },
            {
                header: 'Tag Key : Tag Value',
                accessor: (item) => item.KeyValueAfter,
                cellRenderer: (item) => {
                    return item.KeyValueAfter != undefined ? (
                        <VisibleSpaces value={item.KeyValueAfter as string} />
                    ) : item.Children?.length ?? 0 > 0 ? null : (
                        'No change'
                    );
                },
                defaultWidth: 250,
                groupName: 'AFTER',
                id: 'KeyValueAfter',
            },
            ...additionalColumns,
        ] as ColumnConfig<PreviewGroup>[];

        result.forEach((column) => {
            column.noRemove = true;
            column.filter = undefined;
        });

        return result;
    }, [grid, data]);

    const target = useRouteBoundPortal();
    return (
        <>
            <Drawer
                opened={opened}
                onClose={() => tagAutomationRulesSvc.closePreviewDrawer()}
                size="50%"
                target={target}
                position="right"
                zIndex={199}
                withCloseButton={false}
            >
                <SidePanelContainer title="Rule Impact Preview" onClose={() => tagAutomationRulesSvc.closePreviewDrawer()}>
                    {ruleEditor.preview.loadingGrid ? (
                        <LoadingOverlay visible />
                    ) : (
                        <DataGrid
                            renderStats={() => (data.length < 20 ? `${data.length} preview results` : <>20 (Max) preview results</>)}
                            selectionMode="none"
                            groupConfig={groupConfig}
                            allowGroupBy={false}
                            showHeaderGroups={true}
                            showCount={false}
                            showToolbar={true}
                            hideGlobalSearch={true}
                            hideFilter={true}
                            disableHighlight
                            childAccessor={childAccessor}
                            onRowClick={(r) => grid?.treeModel?.toggle(r)}
                            dataSource={data}
                            displayMode="grid"
                            columns={columns}
                            onModelLoaded={setGrid}
                        />
                    )}
                </SidePanelContainer>
            </Drawer>

            <ResourceDetailsOpener
                onClose={() => selectedResource.emit(undefined)}
                resourceId={resource?.Id ?? ''}
                platform={resource?.CloudPlatform ?? 'Aws'}
                resourceType={resource?.ResourceType ?? ''}
                resource={resource}
                refreshDataOnly={() => {}}
            />
        </>
    );
});
