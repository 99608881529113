import { FillerSwitch } from '@root/Design/Filler';
import { useDi } from '@root/Services/DI';
import { InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { ComponentType, useEffect, useState } from 'react';

export function withSchemaPreloader<T>(Component: ComponentType<T>) {
    return function SchemaPreloader(props: any) {
        const invoiceSchemaSvc = useDi(InvoiceSchemaService);
        const [ready, setReady] = useState(false);

        useEffect(() => {
            let unloaded = false;
            (async function load() {
                await invoiceSchemaSvc.getDailySchema();
                if (!unloaded) {
                    setReady(true);
                }
            })();
            return () => {
                unloaded = true;
            };
        }, []);

        return <FillerSwitch loading={!ready}>{() => <Component {...props} />}</FillerSwitch>;
    };
}
