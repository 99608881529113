import { inject, injectable, singleton } from 'tsyringe';
import { FormatService } from '../FormatService';
import { ReconciliationGridRow } from '@root/Site/Showback/Reconciliation/ReconciliationGrid';
import { addMonths } from 'date-fns';
import { cleanBoolExpr, exprBuilder, groupExprs, IFluentOperators, INumericFluentOperators, queryBuilder, ValuesGroupOtherText } from '../QueryExpr';
import { IDailyRollup, IInvoiceRollup, IMonthlyRollup, InvoiceSchemaService } from './InvoiceSchemaService';
import {
    AllocationDimension,
    AllocationDimensionDimensionType,
    DisbursementDefinition,
    FundSource,
    FundSourceDefinition,
    InvoiceAllocationRule,
    InvoiceAllocationRuleRuleType,
    InvoiceTaggingRule,
    NamedFilterSet,
} from '@apis/Invoices/model';
import { postMetadataIntegrationSearch, QueryExpr, QueryOperation, QueryResult } from '@apis/Resources';
import { InvoiceApiService } from './InvoiceApiService';
import {
    getInvoiceRuleGetAllocationDimensions,
    getInvoiceRuleGetInvoiceAllocationRules,
    getInvoiceRuleGetInvoiceTaggingRules,
    postInvoiceRuleSaveInvoiceAllocationRule,
    postInvoiceRuleSaveInvoiceTaggingRule,
} from '@apis/Invoices';
import { EventEmitter } from '../EventEmitter';
import { getIntegrationGetIntegrations } from '@apis/Customers';
import { IQueryExpr, MetadataIntegrationConfig } from '@apis/Resources/model';
import { IntegrationSchemaService } from '../Integrations/IntegrationSchemaService';

@singleton()
export class ShowbackEvents {
    public readonly invoiceRuleUpdated = new EventEmitter<InvoiceAllocationRule | InvoiceTaggingRule | undefined>(undefined);
}

export type AllocDimOption = { field: string; type: AllocationDimensionDimensionType; prefix: string | null };

@injectable()
export class ShowbackPersistence {
    public get invoiceApi() {
        return this._invoiceApi;
    }

    public constructor(
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(InvoiceApiService) private readonly _invoiceApi: InvoiceApiService,
        @inject(ShowbackEvents) private readonly showbackEvt: ShowbackEvents,
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService,
        @inject(IntegrationSchemaService) private readonly integrationSchemaSvc: IntegrationSchemaService
    ) {}

    public async getAllocatedData(dimension: AllocationDimension, selectedMonth: Date) {
        const { field } = await this.getCostField(selectedMonth);
        const dataQuery = this.getAllocatedDataQuery(dimension, selectedMonth, field);

        const results = await dataQuery.execute((q) => this.invoiceApi.queryMonthlyRollup(q, [selectedMonth]));

        return results;
    }

    public getDateRange(selectedMonth: Date) {
        var y = selectedMonth.getFullYear(),
            m = selectedMonth.getMonth();
        const startDate = new Date(y, m, 1);
        const endDate = new Date(y, m + 1, 0);
        return { from: startDate, to: endDate };
    }

    public async getAllocationDimension(id: number) {
        const results = await getInvoiceRuleGetAllocationDimensions();
        return results?.find((d) => d.Id === id);
    }

    public async getInvoiceRule(id: number, ruleType?: InvoiceAllocationRuleRuleType) {
        const [allocRule, tagRules] = await Promise.all([getInvoiceRuleGetInvoiceAllocationRules(), getInvoiceRuleGetInvoiceTaggingRules()]);
        const rules = [...allocRule, ...tagRules];
        return rules?.find((r) => r.Id === id && (!ruleType || r.RuleType === ruleType));
    }

    public async saveInvoiceRule(rule: InvoiceAllocationRule | InvoiceTaggingRule) {
        if (rule.RuleType === 'UntaggedCosts') {
            await postInvoiceRuleSaveInvoiceTaggingRule(rule as InvoiceTaggingRule);
        } else {
            await postInvoiceRuleSaveInvoiceAllocationRule(rule as InvoiceAllocationRule);
        }
        this.showbackEvt.invoiceRuleUpdated.emit(rule);
    }

    public async getInvoiceDateRange() {
        return await this.invoiceApi.getDateRange();
    }

    //#region Dimension Options
    public async getAllocDimOptions(): Promise<{ integration: AllocDimOption[]; tag: AllocDimOption[]; standard: AllocDimOption[] }> {
        const [integration, tag, standard] = await Promise.all([this.getIntegrationDimFields(), this.getTagDimFields(), this.getStandardDimFields()]);
        return { integration, tag, standard };
    }

    private async getStandardDimFields(): Promise<AllocDimOption[]> {
        return [
            { type: 'UsageAccount', field: 'lineItem/UsageAccountId', prefix: null },
            { type: 'PayerAccount', field: 'bill/PayerAccountId', prefix: null },
        ];
    }

    private async getIntegrationDimFields() {
        const integrationSchemas = await this.integrationSchemaSvc.getIntegrationSchema();
        const integrationFields = integrationSchemas?.flatMap((s) => (s.Fields ?? []).map((f) => ({ type: s, field: f }))) ?? [];
        return integrationFields.flatMap(
            ({ type, field }) => ({ field: field.Field, type: 'IntegrationDatapoint', prefix: type.Name } as AllocDimOption)
        );
    }

    private async getTagDimFields() {
        const schemaTypes = await this.invoiceSchemaSvc.getMonthlySchema();
        const tagFields = schemaTypes
            .map((t) => (t.Name?.startsWith('Tags') ? t.Fields ?? [] : []))
            .flat()
            .filter((f) => f.Field?.startsWith('resourceTags/user:'));
        return tagFields.map((f) => ({ field: f.Field!, type: 'CostAllocationTag', prefix: null } as AllocDimOption));
    }
    //#endregion

    public async getFundSourceAmount(source: FundSource, month: Date) {
        if (source.SourceType === 'LineItems') {
            const queryExpr = this.getCriteriaForFilterSet(source.Filters ?? {});
            let builder = queryBuilder<IInvoiceRollup>();
            if (queryExpr) {
                builder = builder.where((b) => b.fromExpr<boolean>(queryExpr as QueryExpr));
            }
            const result = await builder
                .select((r) => ({
                    amount: r.sum(r.model['lineItem/UnblendedCost']),
                }))
                .execute((q) => this.invoiceApi.query(q, { from: month, to: month }, false));
            const results = result.Results;
            let amount: number = 0;
            if (results && results.length > 0) {
                results.forEach((r) => {
                    amount += r.amount;
                });
            }
            return amount;
        } else {
            return source.ExternalDetail?.Amount ?? 0;
        }
    }

    public async getUnallocatedData(dimension: AllocationDimension, selectedMonth: Date) {
        const { xb } = this.getMetricExprBuilder();
        const [stats, invoiceStats] = await Promise.all([
            this.getAllocationStats(selectedMonth, dimension, { useAdjustedCost: true, field: xb.model.AdjustedCashCost! }, 'unallocated'),
            this.getAllocationStats(selectedMonth, dimension, { useAdjustedCost: true, field: xb.model['lineItem/UnblendedCost']! }, 'unallocated'),
        ]);
        const results = stats.Results ?? [];
        type ResultsItemType = (typeof results)[number];

        const dimensions: [keyof ResultsItemType, string][] = [
            ['unallocatedUntagged', 'Untagged Costs'],
            ['discountSp', 'Savings Plans'],
            ['discountRi', 'Reserved Instances'],
            ['chargeFees', 'Fees'],
            ['discountEdp', 'Discounts'],
            ['discountCredits', 'Credits'],
            ['chargeSupport', 'Support'],
            ['chargeMarketplace', 'Marketplace Charges'],
            ['unallocatedNoCategory', 'Other'],
        ];

        const invoiceAmtStats = (invoiceStats.Results ?? [])[0];

        const data: ReconciliationGridRow[] = [];
        results.forEach((result) => {
            dimensions.forEach(([dimension, name]) => {
                let invoiceAmount = invoiceAmtStats[dimension as keyof ResultsItemType];
                let showbackAmt = result[dimension as keyof ResultsItemType];
                data.push({
                    assetId: name,
                    invoiceAmount: invoiceAmount ?? 0,
                    showbackAmount: showbackAmt ?? 0,
                    savingsPlans: dimension === 'discountSp' ? result?.discountSp : 0,
                    reservedInstances: dimension === 'discountRi' ? result?.discountRi : 0,
                    edp: dimension === 'discountEdp' ? result?.discountEdp : 0,
                    credits: dimension === 'discountCredits' ? result?.discountCredits : 0,
                    support: dimension === 'chargeSupport' ? result?.chargeSupport : 0,
                    marketplace: dimension === 'chargeMarketplace' ? result?.chargeMarketplace : 0,
                    fees: dimension === 'chargeFees' ? result?.chargeFees : 0,
                    amortizationReservedInstances: dimension === 'discountRi' ? result?.upfrontRiFees : 0,
                    amortizationSavingsPlans: dimension === 'discountSp' ? result?.upfrontSpFees : 0,
                });
            });
        });

        return data;
    }

    //#region Allocation Dimension details
    public getDimensionInvoiceField(allocDim: AllocationDimension | undefined) {
        const [field] = this.getDimensionInvoiceFieldWithSchema(allocDim);

        return field;
    }

    public getSchemaQualifiedDimensionField(allocDim: AllocationDimension | undefined) {
        const [field, schemaTypeName] = this.getDimensionInvoiceFieldWithSchema(allocDim);
        return [schemaTypeName, field].filter((p) => !!p).join('.');
    }

    private getDimensionInvoiceFieldWithSchema(allocDim: AllocationDimension | undefined): [string, string] {
        switch (allocDim?.DimensionType) {
            case 'CostAllocationTag':
                return [allocDim?.DimensionName, 'resourceTags'];
            case 'IntegrationDatapoint':
                return [allocDim.IntegrationPrefix + '/' + allocDim.DimensionName, allocDim.IntegrationPrefix ?? ''];
            case 'PayerAccount':
                return ['bill/PayerAccountId', 'bill'];
            case 'UsageAccount':
                return ['lineItem/UsageAccountId', 'lineItem'];
            default:
                return ['', ''];
        }
    }

    public getDimensionTypeName(field: string, type: AllocationDimensionDimensionType) {
        switch (type) {
            case 'CostAllocationTag':
                return field.startsWith('resourceTags/user:') ? field.split('resourceTags/user:')[1] : field;
            case 'IntegrationDatapoint':
                return this.fmtSvc.userFriendlyCamelCase(field);
            case 'PayerAccount':
                return 'Payer Account';
            case 'UsageAccount':
                return 'Usage Account';
            default:
                return '';
        }
    }
    public getDimensionName(allocDim: AllocationDimension | undefined) {
        return !allocDim?.DimensionType ? '' : this.getDimensionTypeName(allocDim?.DimensionName ?? '', allocDim?.DimensionType);
    }

    public async getDimensionInvoiceValues(allocDim: AllocationDimension, range?: { from?: Date; to?: Date }) {
        const months = !range ? [] : this.getMonthList(range);
        const { Results } = await this.getDimensionUniqueValuesQuery(allocDim).execute((q) => this.invoiceApi.queryMonthlyRollup(q, months));

        return (Results?.map((r) => r.key) ?? []).filter((r) => !!r && r !== ValuesGroupOtherText);
    }

    public async getDimensionValueOptions(allocDim: AllocationDimension, range?: { from?: Date; to?: Date }) {
        let result: string[] = [];
        if (allocDim.DimensionType === 'IntegrationDatapoint') {
            const { integration, integrationConfig } = (await this.getIntegrationConfig(allocDim)) ?? {};
            if (integration && integrationConfig && integrationConfig.SourceIdField) {
                const dimensionName = integrationConfig.SourceIdField;

                const results = await this.getExistingDimensionValuesFromMetadata(dimensionName, integration.Id);
                if (results?.Results?.length) {
                    result = results.Results.map((r) => r.key);
                }
            }
        }
        if (!result.length) {
            result = await this.getDimensionInvoiceValues(allocDim, range);
        }
        const uniqueResults = new Set(result.filter((r) => !!r && r !== ValuesGroupOtherText));
        return [...uniqueResults].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
    }

    private async getExistingDimensionValuesFromMetadata(dimensionName: string, integrationId: number | undefined) {
        const query = queryBuilder<{ [key: string]: string }>()
            .select((b) => ({
                key: b.values(b.model[dimensionName]),
            }))
            .build();

        return (await postMetadataIntegrationSearch(query, { integrationId: integrationId })) as QueryResult<{ key: string }>;
    }

    private async getIntegrationConfig(allocDim: AllocationDimension) {
        if (allocDim.DimensionType !== 'IntegrationDatapoint') {
            const integrationConfigs = await this.getIntegrationConfigs();
            return integrationConfigs.find(({ integrationConfig }) => integrationConfig?.DestinationField === allocDim.IntegrationPrefix);
        }
        return undefined;
    }

    private async getIntegrationConfigs() {
        const integrations = await getIntegrationGetIntegrations();
        return integrations.map((integration) => ({
            integration,
            integrationConfig: integration.ConfigJson ? (JSON.parse(integration.ConfigJson ?? 'null') as MetadataIntegrationConfig | null) : null,
        }));
    }

    public getDimensionUniqueValuesQuery(allocDim: AllocationDimension) {
        var dimensionName = this.getDimensionInvoiceField(allocDim);
        return queryBuilder<{
            [key: string]: string;
        }>().select((b) => ({
            key: b.values(b.model[dimensionName]),
        }));
    }
    //#endregion

    public getMonthList(range: { from?: Date; to?: Date }) {
        if (!range.from && !range.to) {
            return [];
        }
        const from = range.from ?? addMonths(new Date(), -12);
        const to = range.to ?? new Date();
        const months = [];
        for (let month = from; month <= to; month = addMonths(month, 1)) {
            months.push(month);
        }
        return months;
    }

    //#region Common Queries
    public async getAllocationStats(
        month: Date,
        allocDim: AllocationDimension,
        costField?: { field: number & INumericFluentOperators; useAdjustedCost: boolean },
        allocState?: 'allocated' | 'unallocated'
    ) {
        const { unallocated, allocated, unallocatedUntagged, untagged, noCategory, ...allocSpecificCategories } = this.getMetricCriteria(allocDim);
        const allocSpecifiedCategs = !allocState
            ? allocSpecificCategories
            : this.mergeCritExpr(allocSpecificCategories, allocState === 'allocated' ? allocated : unallocated);
        const {
            allCharges,
            allDiscounts,
            chargeFees,
            chargeMarketplace,
            chargeSupport,
            discountCredits,
            discountEdp,
            discountRi,
            discountSp,
            discountPvtRate,
        } = allocSpecifiedCategs;

        const { upfrontRiFees, upfrontSpFees, reservedInstances } = this.getNonBillingPeriodCostExprs();

        const { xb, createExpr } = this.getMetricExprBuilder();
        const qb = this.getMetricQb();

        const dimField = this.getDimensionInvoiceField(allocDim);
        const tagged = xb.model[dimField].isNotNull();
        const { field, useAdjustedCost } = costField || (await this.getCostField(month));
        const unallocatedNoCategory = createExpr((eb) => eb.and(eb.fromExpr<boolean>(noCategory), eb.model[dimField].isNull()));

        let statsQuery = await qb;
        const statsResults = await statsQuery
            .select((b) => ({
                total: b.sum(field),
                upfrontRiFees,
                upfrontSpFees,
                tagCovered: b.aggIf(tagged, b.sum(field)),
                ...this.getMetricCostSumExpr(useAdjustedCost ? 'Adjusted' : 'Billed', {
                    unallocated,
                    allocated,
                    untagged,
                    allCharges,
                    allDiscounts,
                    chargeFees,
                    chargeMarketplace,
                    chargeSupport,
                    discountCredits,
                    unallocatedNoCategory,
                    discountEdp,
                    discountRi,
                    discountSp,
                    discountPvtRate,
                    unallocatedUntagged,
                }),
            }))
            .execute((q) => this.invoiceApi.queryByUsageMonth(q, month));

        return statsResults;
    }

    public async hasAdjustedCost(month: Date) {
        const qb = this.getMetricQb();
        const results = await qb
            .where((b) => b.model.AdjustedCashCost!.isNotNull())
            .take(1)
            .execute((q) => this.invoiceApi.queryByUsageMonth(q, month));

        return typeof results?.Count === 'number' && results.Count > 0;
    }

    public async getCostField(month: Date) {
        const { xb } = this.getMetricExprBuilder();
        const useAdjustedCost = await this.hasAdjustedCost(month);
        return { field: xb.model[useAdjustedCost ? 'AdjustedCashCost' : 'lineItem/UnblendedCost']!, useAdjustedCost };
    }

    public getCriteriaForFilterSet(filterGroup: NamedFilterSet) {
        const inclusions = filterGroup.InclusionRules?.map((r) => r.Filter);
        const inclusionsExpr = groupExprs('or', inclusions);

        const exclusions = filterGroup.ExclusionRules?.map((r) => r.Filter);
        const exclusionsExpr = groupExprs('or', exclusions);
        const exclusionExpr = exclusionsExpr ? { Operation: 'not', Operands: [exclusionsExpr] } : undefined;

        const result = groupExprs('and', [inclusionsExpr, exclusionExpr]);
        return !result ? undefined : cleanBoolExpr(JSON.parse(JSON.stringify(result)));
    }

    public getAllocatedDataQuery(dimension: AllocationDimension, selectedMonth: Date, costField: INumericFluentOperators & number) {
        const dimField = this.getDimensionInvoiceField(dimension);
        const { chargeMarketplace, chargeFees, chargeSupport, discountCredits, discountEdp, discountSp, discountRi } = this.exprToOp(
            this.getMetricCriteria(dimension)
        );
        const { xb } = this.getMetricExprBuilder();
        const sumCost = xb.sum(costField);
        const { upfrontRiFees, upfrontSpFees, internalFees } = this.getNonBillingPeriodCostExprs();
        return queryBuilder<IInvoiceRollup & { [key: string]: string }>().select((b) => ({
            assetId: b.model[dimField],
            invoiceAmount: b.sum(b.model['lineItem/UnblendedCost']!),
            showbackAmount: b.sum(b.model.AdjustedCashCost as number),
            savingsPlans: b.aggIf(discountSp, sumCost),
            reservedInstances: b.aggIf(discountRi, sumCost),
            edp: b.aggIf(discountEdp, sumCost),
            credits: b.aggIf(discountCredits, sumCost),
            support: b.aggIf(chargeSupport, sumCost),
            fees: b.aggIf(chargeFees, sumCost),
            datacharges: b.aggIf(
                b.or(b.model['product/productFamily']!.eq('Data Transfer'), b.model['product/productfamily']!.contains('DataTransfer')),
                b.sum(b.model['lineItem/UnblendedCost'] as number)
            ),
            marketplace: b.aggIf(chargeMarketplace, sumCost),
            internal: internalFees,
            amortizationSavingsPlans: upfrontSpFees,
            amortizationReservedInstances: upfrontRiFees,
            otherSavingsPlans: b.aggIf(b.model['lineItem/LineItemType']!.eq('SavingsPlanRecurringFee'), sumCost),
            otherReservedInstances: b.aggIf(b.model['lineItem/LineItemType']!.eq('RIFee'), sumCost),
        }));
    }

    public createTagRuleFilters(allocDim: AllocationDimension) {
        const exprs: QueryExpr[] = [];

        const field = this.getDimensionInvoiceField(allocDim);
        const rules = allocDim.TaggingRules ?? [];
        for (const rule of rules) {
            if (rule.TagKey === field) {
                const filters = this.createTagRuleFilter(rule);
                if (filters.length > 1) {
                    exprs.push({ Operation: 'and', Operands: [filters] });
                } else if (filters.length > 0) {
                    exprs.push(filters[0]);
                }
            }
        }

        return exprs.length > 1 ? { Operation: 'or', Operands: exprs } : exprs.length ? exprs[0] : undefined;
    }

    public createTagRuleFilter(rule: InvoiceTaggingRule) {
        const exprs: QueryExpr[] = [];
        const filterSet = rule.TargetFilters ? this.getCriteriaForFilterSet(rule.TargetFilters) : undefined;
        if (filterSet) {
            exprs.push(filterSet as QueryExpr);
        }
        if (!rule.AllowOverwrite) {
            exprs.push({ Operation: 'isNull', Operands: [{ Field: rule.TagKey }] });
        }
        return exprs;
    }

    public getMetricQb() {
        return queryBuilder<IDailyRollup & { [key: string]: string }>();
    }

    public getMetricExprBuilder() {
        const { builder: xb, ...rest } = exprBuilder<IDailyRollup & { [key: string]: string }>();
        return { xb, ...rest };
    }

    public getMetricCostSumExpr<TCostType extends 'Adjusted' | 'Billed', TResult extends Record<string, QueryExpr>>(
        costField: TCostType,
        conditions: TResult,
        additionalCrit?: QueryExpr
    ) {
        const { builder: xb } = exprBuilder<IDailyRollup & { [key: string]: string }>();
        const costFieldExpr = costField === 'Adjusted' ? xb.model.AdjustedCashCost! : xb.model['lineItem/UnblendedCost']!;
        const combinedCrit = this.mergeCritExpr(conditions, additionalCrit);

        return Object.keys(combinedCrit).reduce(
            (acc, key) => ({
                ...acc,
                [key]: xb.aggIf(xb.fromExpr<boolean>(combinedCrit[key as keyof typeof combinedCrit]), xb.sum(costFieldExpr)),
            }),
            {} as Record<keyof typeof combinedCrit, INumericFluentOperators>
        );
    }

    public exprToOp<TResult extends Record<keyof TResult, QueryExpr>>(conditions: TResult) {
        const { builder: xb } = exprBuilder<IDailyRollup & { [key: string]: string }>();

        return Object.keys(conditions).reduce(
            (acc, key) => ({
                ...acc,
                [key]: xb.fromExpr<boolean>(conditions[key as keyof TResult]),
            }),
            {} as Record<keyof TResult, IFluentOperators<boolean> & boolean>
        );
    }

    public mergeCritExpr<TResult extends Record<keyof TResult, QueryExpr>>(conditions: TResult, additionalCrit?: QueryExpr) {
        const { builder: xb } = exprBuilder<IDailyRollup & { [key: string]: string }>();
        const combineCrit = additionalCrit
            ? (crit: QueryExpr) => xb.and(xb.fromExpr<boolean>(additionalCrit), xb.fromExpr<boolean>(crit))
            : (crit: QueryExpr) => xb.fromExpr<boolean>(crit);

        return Object.keys(conditions).reduce(
            (acc, key) => ({
                ...acc,
                [key]: xb.resolve(combineCrit(conditions[key as keyof TResult])),
            }),
            {} as Record<keyof TResult, QueryExpr>
        );
    }

    public getNonBillingPeriodCostExprs() {
        const { builder: xb } = exprBuilder<IDailyRollup>();
        return {
            internalFees: xb.sum(xb.model.ExternalCostAdjustment!),
            reservedInstances: xb.sum(xb.model['reservation/OnDemandCost']),
            upfrontSpFees: xb.sum(xb.model['savingsPlan/AmortizedUpfrontCommitmentForBillingPeriod']),
            upfrontRiFees: xb.sum(xb.model['reservation/UnusedAmortizedUpfrontFeeForBillingPeriod']),
        };
    }

    public getMetricCriteria(allocDim: AllocationDimension) {
        const dimField = this.getDimensionInvoiceField(allocDim);
        const { createExpr: xb, createFluentExpr } = exprBuilder<IDailyRollup & { [key: string]: string }>();

        const tagged = xb((b) => b.model[dimField].isNotNull());
        const untagged = xb((b) => b.model[dimField].isNull());

        const unallocCrit = createFluentExpr((b) => b.fromExpr<boolean>(untagged));

        const allocCrit = createFluentExpr((b) => b.fromExpr<boolean>(tagged));

        const unallocatedUntagged = xb((b) => b.and(b.model[dimField].isNull(), b.model['lineItem/LineItemType']!.eq('Usage')));

        const discountSp = xb((b) =>
            b.model['lineItem/LineItemType']!.eq([
                'SavingsPlanNegation',
                'SavingsPlanCoveredUsage',
                'SavingsPlanRecurringFee',
                'SavingsPlanUpfrontFee',
            ])
        );

        const discountRi = xb((b) =>
            b.or(
                b.model['lineItem/LineItemType']!.eq(['DiscountedUsage', 'RIFee']),
                b.and(b.model['lineItem/LineItemType']!.eq('Fee'), b.model['reservation/ReservationARN']!.isNotNull())
            )
        );

        const discountPvtRate = xb((b) => b.model['lineItem/LineItemType']!.eq(['PrivateRateDiscount']));

        const discountEdp = xb((b) => b.model['lineItem/LineItemType']!.eq(['EdpDiscount']));

        const discountCredits = xb((b) => b.model['lineItem/LineItemType']!.eq(['Credit']));

        const allDiscounts = xb((b) =>
            b.or(
                b.fromExpr<boolean>(discountSp),
                b.fromExpr<boolean>(discountRi),
                b.fromExpr<boolean>(discountEdp),
                b.fromExpr<boolean>(discountCredits),
                b.fromExpr<boolean>(discountPvtRate)
            )
        );

        const chargeSupport = xb((b) => b.and(b.model['bill/BillingEntity']!.eq('AWS'), b.model['product/ProductName']!.contains('Support')));

        const chargeMarketplace = xb((b) => b.model['bill/BillingEntity']!.eq('AWS Marketplace'));
        const chargeNotMarketplace = xb((b) => b.model['bill/BillingEntity']!.ne('AWS Marketplace'));

        const chargeFees = xb((b) =>
            b.or(
                b.model['lineItem/LineItemType']!.eq(['Tax']),
                b.and(
                    b.model['lineItem/LineItemType']!.eq(['Fee']),
                    b.model['reservation/ReservationARN']!.isNull(),
                    b.not(b.model['product/ProductName']!.contains('Support'))
                )
            )
        );

        const allCharges = xb((b) =>
            b.or(b.fromExpr<boolean>(chargeSupport), b.fromExpr<boolean>(chargeMarketplace), b.fromExpr<boolean>(chargeFees))
        );

        const noCategory = xb((b) =>
            b.not(
                b.or(
                    ...[
                        chargeSupport,
                        chargeMarketplace,
                        chargeFees,
                        discountSp,
                        discountRi,
                        discountEdp,
                        discountPvtRate,
                        discountCredits,
                        unallocatedUntagged,
                    ].map((c) => b.fromExpr<boolean>(c))
                )
            )
        );

        return {
            /**
             * the alloc dim field is null or empty, ([allocDim] is null or [allocDim] = '')
             */
            untagged,
            /**
             * the AdjustedCashCost is null or non-zero, and the line item is untagged
             */
            unallocated: unallocCrit.resolve(),
            /**
             * the AdjustedCashCost is not null, and the line item is tagged
             */
            allocated: allocCrit.resolve(),
            unallocatedUntagged,
            allDiscounts,
            allCharges,
            noCategory,
            discountSp,
            discountRi,
            discountEdp,
            discountPvtRate,
            discountCredits,
            chargeSupport,
            chargeMarketplace,
            chargeNotMarketplace,
            chargeFees,
        };
    }
    //#endregion

    public getOtherCharges(dimension: string, data: never[]) {
        //Get other charges from the data
        const result: string[] = [];

        return result;
    }

    //#region Preview Queries
    public async getSourcePreviewData(rule: InvoiceAllocationRule, selectedMonth: Date) {
        const fundSource = rule.FundSource;
        if (fundSource?.Sources?.length === 1) {
            const source = fundSource.Sources[0];
            if (!source.Filters?.ExclusionRules && !source.Filters?.InclusionRules) {
                return [];
            }
        }

        const fundSourceFilters = fundSource?.Sources?.map((source) => {
            if (source.SourceType === 'LineItems') {
                return this.getCriteriaForFilterSet(source.Filters ?? {});
            } else {
                return undefined;
            }
        }) as IQueryExpr[];
        const fundSourceFilter = groupExprs('or', fundSourceFilters);
        if (!fundSourceFilter) {
            return [];
        }
        const results = await queryBuilder<IDailyRollup & { [key: string]: string }>()
            .where((b) => b.fromExpr<boolean>(fundSourceFilter as QueryExpr))
            .sortDesc((b) => b.model['lineItem/UnblendedCost'])
            .take(100)
            .execute((q) => this.invoiceApi.query<IDailyRollup>(q, this.getDateRange(selectedMonth)));

        await this.GetNamesFromMonthlyData(results, selectedMonth);

        return results?.Results ?? [];
    }

    public async getDisbursmentPreviewData(rule: InvoiceAllocationRule, selectedMonth: Date) {
        const disbursement = rule.Disbursement;
        if (!disbursement) {
            return [];
        }
        const dataQuery = this.getDisbursmentQuery(disbursement, rule);

        dataQuery.Take = 100;
        const results = await this.invoiceApi.query<IDailyRollup & { [key: string]: string }>(dataQuery, this.getDateRange(selectedMonth));

        await this.GetNamesFromMonthlyData(results, selectedMonth);

        return results?.Results ?? [];
    }

    private getDisbursmentQuery(disbursment: DisbursementDefinition, rule: InvoiceAllocationRule) {
        let dataQueryFilters = [];

        if (disbursment.Scope) {
            const scopeFilters = this.getCriteriaForFilterSet(disbursment.Scope ?? {});

            const scopeFilter = groupExprs('or', scopeFilters as IQueryExpr[]);
            dataQueryFilters.push(scopeFilter);
        }

        if (disbursment.Targets && disbursment.Targets.length > 0) {
            const targetFilters = rule.Disbursement?.Targets?.map((target) => {
                if (target.TargetType === 'Existing') {
                    return this.getCriteriaForFilterSet(target.TargetExistingFilter ?? {});
                } else {
                    return undefined;
                }
            });
            const targetFilter = groupExprs('or', targetFilters as IQueryExpr[]);
            dataQueryFilters.push(targetFilter);
        }
        const where = groupExprs('and', dataQueryFilters);
        let qb = queryBuilder<IDailyRollup>();
        if (where) {
            qb = qb.where((b) => b.fromExpr<boolean>(where as QueryExpr));
        }
        return qb.sortDesc((b) => b.model['lineItem/UnblendedCost']).build();
    }

    public async getSourceAndTargetTotalPreviewData(rule: InvoiceAllocationRule, selectedMonth: Date) {
        const fundSource = rule.FundSource;
        const disbursment = rule.Disbursement;
        const [rawSourceResults, rawTargetResults] = await Promise.all([
            fundSource ? this.getSourceTotalsByDay(fundSource, selectedMonth) : undefined,
            disbursment ? this.getTargetTotalsByDay(disbursment, rule, selectedMonth) : undefined,
        ]);
        const sourceResults = rawSourceResults?.Results ?? [];
        const targetResults = rawTargetResults?.Results ?? [];
        const sourceTotalsByDay = sourceResults.reduce(
            (res, item) => res.set(this.fmtSvc.toUtcJsonShortDate(item.date) as unknown as string, item),
            new Map<string, (typeof sourceResults)[number]>()
        );
        const targetTotalsByDay = targetResults.reduce(
            (res, item) => res.set(this.fmtSvc.toUtcJsonShortDate(item.date) as unknown as string, item),
            new Map<string, (typeof targetResults)[number]>()
        );

        const { sourceInvoiceTotal, undisbursable, sourceCt } = [...sourceTotalsByDay.entries()].reduce(
            (res, [date, item]) => {
                if (!targetTotalsByDay.has(date)) {
                    res.undisbursable += item.total;
                }
                res.sourceInvoiceTotal += item.total;
                res.sourceCt += item.count;
                return res;
            },
            { sourceInvoiceTotal: 0, undisbursable: 0, sourceCt: 0 }
        );
        const sourceShowbackTotal = undisbursable;

        const { targetInvoiceTotal, targetCt } = [...targetTotalsByDay.values()].reduce(
            (res, item) => {
                res.targetInvoiceTotal += item.total;
                res.targetCt += item.count;
                return res;
            },
            { targetInvoiceTotal: 0, targetCt: 0 }
        );
        const targetShowbackTotal = targetInvoiceTotal + sourceInvoiceTotal - undisbursable;

        return {
            sourceCt,
            sourceInvoiceTotal,
            sourceShowbackTotal,
            targetCt,
            targetInvoiceTotal,
            targetShowbackTotal,
            sourceTotalsByDay,
            targetTotalsByDay,
        };
    }

    private async getSourceTotalsByDay(fundSource: FundSourceDefinition, selectedMonth: Date) {
        if (fundSource && fundSource.Sources && fundSource.Sources.length > 0) {
            const fundSourceFilters = fundSource.Sources?.map((source) => {
                if (source.SourceType === 'LineItems') {
                    return this.getCriteriaForFilterSet(source.Filters ?? {});
                } else {
                    // calculate from custom fee
                    return undefined;
                }
            }) as IQueryExpr[];
            const fundSourceFilter = groupExprs('or', fundSourceFilters);
            if (fundSourceFilter) {
                const sourceTotalsByDay = await queryBuilder<IDailyRollup>()
                    .where((b) => b.fromExpr<boolean>(fundSourceFilter as QueryExpr))
                    .select((b) => {
                        return {
                            date: b.model.UsageStartDate,
                            total: b.sum(b.model['lineItem/UnblendedCost']),
                            count: b.count(),
                        };
                    })
                    .execute((q) => this.invoiceApi.query<IDailyRollup>(q, this.getDateRange(selectedMonth)));
                return sourceTotalsByDay;
            }
        }
    }

    private async getTargetTotalsByDay(disbursment: DisbursementDefinition, rule: InvoiceAllocationRule, selectedMonth: Date) {
        if (disbursment) {
            const disbursmentWhere = this.getDisbursmentQuery(disbursment, rule).Where;

            if (disbursmentWhere) {
                const targetTotalsByDay = await queryBuilder<IDailyRollup>()
                    .where((b) => b.fromExpr<boolean>(disbursmentWhere as QueryExpr))
                    .select((b) => {
                        return {
                            date: b.model.UsageStartDate,
                            total: b.sum(b.model['lineItem/UnblendedCost']),
                            count: b.count(),
                        };
                    })
                    .execute((q) => this.invoiceApi.query<IDailyRollup>(q, this.getDateRange(selectedMonth)));
                return targetTotalsByDay;
            } else {
                const targetTotalsByDay = await queryBuilder<IDailyRollup>()
                    .select((b) => {
                        return {
                            date: b.model.UsageStartDate,
                            total: b.sum(b.model['lineItem/UnblendedCost']),
                            count: b.count(),
                        };
                    })
                    .execute((q) => this.invoiceApi.query<IDailyRollup>(q, this.getDateRange(selectedMonth)));
                return targetTotalsByDay;
            }
        }
    }

    private async GetNamesFromMonthlyData(results: QueryResult<IInvoiceRollup & { Name?: string }>, selectedMonth: Date) {
        if (results.Results) {
            const varianceKeyLookup = [...results.Results.reduce((r, item) => r.add(item.VarianceKey), new Set<string>())];
            const nameLookupQuery = await queryBuilder<IMonthlyRollup>()
                .take(100)
                .where((b) => b.model.VarianceKey.eq(varianceKeyLookup))
                .select((b) => ({ Name: b.model['lineItem/LineItemDescription'], VarianceKey: b.model.VarianceKey, Ct: b.count() }))
                .execute((q) => this.invoiceApi.queryMonthlyRollup(q, [selectedMonth]));

            results.Results.forEach((r) => {
                const name = nameLookupQuery.Results?.find((n) => n.VarianceKey === r.VarianceKey)?.Name;
                r.Name = name;
            });
        }
    }

    public async getUntaggedPreviewData(rule: InvoiceTaggingRule, selectedMonth: Date, allocDim: AllocationDimension) {
        const query = this.getUntaggedPreviewQuery(rule, allocDim);
        const rawResult = await this.invoiceApi.query<IInvoiceRollup & { [key: string]: string }>(query, this.getDateRange(selectedMonth));

        return { results: rawResult?.Results ?? [], count: rawResult?.Count ?? 0 };
    }

    public getUntaggedPreviewQuery(rule: InvoiceTaggingRule, allocDim: AllocationDimension) {
        const filters = rule.TargetFilters;
        const dataQuery = queryBuilder<IInvoiceRollup & { [key: string]: string }>()
            .take(100)
            .sortDesc((b) => b.model['lineItem/UnblendedCost'])
            .build();
        const criteria: QueryOperation = {
            Operation: 'and',
            Operands: [{ Operation: 'ne', Operands: [{ Field: rule.TagKey ?? '' }, { Value: rule.TagValue ?? '' }] }],
        };
        const criteriaFilter = this.getCriteriaForFilterSet(filters ?? {});
        if (criteriaFilter) {
            criteria.Operands.push(criteriaFilter);
        }
        if (!rule.AllowOverwrite) {
            criteria.Operands.push({ Operation: 'isNull', Operands: [{ Field: this.getDimensionInvoiceField(allocDim) }] });
        }
        dataQuery.Where = criteria;

        return dataQuery;
    }
    //#endregion
}
