import pako from 'pako';

export class DataCompressionService {
    public compressJson<T>(data: T): string {
        const serialized = JSON.stringify(data);
        const compressed = pako.gzip(serialized);
        const encoded = this.uint8ArrayToBase64(compressed);
        return encoded;
    }

    public decompressJson<T>(data: string): T {
        const decompressed = new Uint8Array(this.base64ToUint8Array(data));
        const deserialized = pako.inflate(decompressed, { to: 'string' });
        return JSON.parse(deserialized);
    }

    private uint8ArrayToBase64(uint8Array: Uint8Array): string {
        let binary = '';
        const len = uint8Array.byteLength;

        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(uint8Array[i]);
        }

        return window.btoa(binary);
    }

    private base64ToUint8Array(base64: string): Uint8Array {
        const binary = window.atob(base64);
        const len = binary.length;
        const uint8Array = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            uint8Array[i] = binary.charCodeAt(i);
        }

        return uint8Array;
    }
}
